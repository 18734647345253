var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"add-branch-dialog",attrs:{"title":_vm.type,"visible":_vm.branchesDialogVisible,"width":"900px","center":""},on:{"close":_vm.cancel}},[_c('el-row',[_c('el-col',[_c('ValidationObserver',{ref:"branchForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('el-form',{attrs:{"id":"branch-form","model":_vm.branchForm},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.addBranchHandler($event)}}},[_c('el-row',{staticClass:"dialog-account-info"},[_c('el-form-item',[_c('div',{staticClass:"row-title title"},[_vm._v("帳號資訊")]),_c('div',{staticClass:"row-item"},[_c('el-col',{staticClass:"right-side-border",attrs:{"span":12}},[_c('div',{staticClass:"field"},[_c('span',{staticClass:"star-key"},[_vm._v("*")]),_c('span',{staticClass:"title",class:{ 'is-active': _vm.highlightStatusList[1] },on:{"click":function($event){_vm.$refs.pharmacy_name.focus();_vm.highlightHandler(1);}}},[_vm._v("藥局名稱")]),_c('ValidationProvider',{staticClass:"validate-element",attrs:{"rules":"notAllowedEmpty|maximum50InputLength"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"name"},[_c('el-input',{ref:"pharmacy_name",attrs:{"type":"text","resize":"none"},on:{"focus":function($event){return _vm.highlightHandler(1)}},model:{value:(_vm.branchForm.pharmacy_name),callback:function ($$v) {_vm.$set(_vm.branchForm, "pharmacy_name", $$v)},expression:"branchForm.pharmacy_name"}})],1),_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"field address"},[_c('span',{staticClass:"star-key"},[_vm._v("*")]),_c('span',{staticClass:"title",class:{ 'is-active': _vm.highlightStatusList[2] },on:{"click":function($event){_vm.$refs.pharmacy_address.focus();_vm.highlightHandler(2);}}},[_vm._v("藥局地址")]),_c('ValidationProvider',{staticClass:"validate-element",attrs:{"rules":"notAllowedEmpty|maximum50InputLength"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"address"},[_c('el-input',{ref:"pharmacy_address",attrs:{"type":"textarea","resize":"none"},on:{"focus":function($event){return _vm.highlightHandler(2)}},model:{value:(_vm.branchForm.pharmacy_address),callback:function ($$v) {_vm.$set(_vm.branchForm, "pharmacy_address", $$v)},expression:"branchForm.pharmacy_address"}})],1),_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('el-col',{staticClass:"left-side",attrs:{"span":12}},[_c('div',{staticClass:"field"},[_c('span',{staticClass:"star-key"},[_vm._v("*")]),_c('span',{staticClass:"title",class:{ 'is-active': _vm.highlightStatusList[3] },on:{"click":function($event){_vm.$refs.account.focus();_vm.highlightHandler(3);}}},[_vm._v("登入帳號")]),_c('ValidationProvider',{staticClass:"validate-element",attrs:{"rules":"notAllowedEmpty|maximum30InputLength"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"account"},[_c('el-input',{ref:"account",attrs:{"type":"text","resize":"none"},on:{"focus":function($event){return _vm.highlightHandler(3)}},model:{value:(_vm.branchForm.account),callback:function ($$v) {_vm.$set(_vm.branchForm, "account", $$v)},expression:"branchForm.account"}})],1),_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"field"},[_c('span',{staticClass:"star-key"},[_vm._v("*")]),_c('span',{staticClass:"title",class:{ 'is-active': _vm.highlightStatusList[4] },on:{"click":function($event){_vm.$refs.password.focus();_vm.highlightHandler(4);}}},[_vm._v("登入密碼")]),_c('ValidationProvider',{staticClass:"validate-element",attrs:{"rules":"notAllowedEmpty|maximum20InputLength"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"password"},[_c('el-input',{ref:"password",attrs:{"type":"password","resize":"none"},on:{"focus":function($event){return _vm.highlightHandler(4)}},model:{value:(_vm.branchForm.password),callback:function ($$v) {_vm.$set(_vm.branchForm, "password", $$v)},expression:"branchForm.password"}})],1),_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])],1)])],1),_c('el-row',{staticClass:"dialog-line-login"},[_c('el-form-item',[_c('div',{staticClass:"row-title title"},[_vm._v("LINE Login")]),_c('div',{staticClass:"row-item"},[_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"field"},[_c('span',{staticClass:"star-key"},[_vm._v("*")]),_c('span',{staticClass:"title",class:{ 'is-active': _vm.highlightStatusList[5] },on:{"click":function($event){_vm.$refs.login_channel_id.focus();_vm.highlightHandler(5);}}},[_vm._v("Channel ID")]),_c('ValidationProvider',{attrs:{"rules":"notAllowedEmpty|maximum20InputLength"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"line-channel-id"},[_c('el-input',{ref:"login_channel_id",attrs:{"type":"text","resize":"none"},on:{"focus":function($event){return _vm.highlightHandler(5)}},model:{value:(_vm.branchForm.login_channel_id),callback:function ($$v) {_vm.$set(_vm.branchForm, "login_channel_id", $$v)},expression:"branchForm.login_channel_id"}})],1),_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"field"},[_c('span',{staticClass:"star-key"},[_vm._v("*")]),_c('span',{staticClass:"title",class:{ 'is-active': _vm.highlightStatusList[6] },on:{"click":function($event){_vm.$refs.liff_url.focus();_vm.highlightHandler(6);}}},[_vm._v("LIFF URL")]),_c('ValidationProvider',{attrs:{"rules":"notAllowedEmpty|maximum50InputLength"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"line-liff-url"},[_c('el-input',{ref:"liff_url",attrs:{"type":"text","resize":"none"},on:{"focus":function($event){return _vm.highlightHandler(6)}},model:{value:(_vm.branchForm.liff_url),callback:function ($$v) {_vm.$set(_vm.branchForm, "liff_url", $$v)},expression:"branchForm.liff_url"}})],1),_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])],1)])],1),_c('el-row',{staticClass:"dialog-line-messaging-api"},[_c('el-form-item',[_c('div',{staticClass:"row-title title"},[_vm._v("Messaging API")]),_c('div',{staticClass:"row-item"},[_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"field"},[_c('span',{staticClass:"star-key"},[_vm._v("*")]),_c('span',{staticClass:"title",class:{ 'is-active': _vm.highlightStatusList[7] },on:{"click":function($event){_vm.$refs.channel_secret.focus();_vm.highlightHandler(7);}}},[_vm._v("Channel secret")]),_c('ValidationProvider',{attrs:{"rules":"notAllowedEmpty|maximum50InputLength"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"line-channel-secret"},[_c('el-input',{ref:"channel_secret",attrs:{"type":"text","resize":"none"},on:{"focus":function($event){return _vm.highlightHandler(7)}},model:{value:(_vm.branchForm.channel_secret),callback:function ($$v) {_vm.$set(_vm.branchForm, "channel_secret", $$v)},expression:"branchForm.channel_secret"}})],1),_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"field"},[_c('span',{staticClass:"star-key"},[_vm._v("*")]),_c('span',{staticClass:"title",class:{ 'is-active': _vm.highlightStatusList[8] },on:{"click":function($event){_vm.$refs.channel_access_token.focus();_vm.highlightHandler(8);}}},[_vm._v("Channel access token")]),_c('ValidationProvider',{attrs:{"rules":"notAllowedEmpty|maximum200InputLength"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"line-channel-access-token"},[_c('el-input',{ref:"channel_access_token",attrs:{"type":"textarea","resize":"none"},on:{"focus":function($event){return _vm.highlightHandler(8)}},model:{value:(_vm.branchForm.channel_access_token),callback:function ($$v) {_vm.$set(_vm.branchForm, "channel_access_token", $$v)},expression:"branchForm.channel_access_token"}})],1),_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])],1)])],1)],1)]}}])})],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{staticClass:"custom-col-24"},[_c('el-col',{attrs:{"span":12}},[_c('el-button',{staticClass:"btn-manage el-button-primary-plain",on:{"click":_vm.cancel}},[_vm._v("取消")])],1),_c('el-col',{attrs:{"span":12}},[_c('el-button',{staticClass:"btn-manage el-button-primary",attrs:{"form":'branch-form',"native-type":"submit"}},[_vm._v("確定送出")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }