import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// plugins
import "@/plugins/element-ui";
import "@/plugins/Vue2googlemap";
Vue.config.productionTip = false;

//utils
import "@/assets/utils/veeValidate/index.js";

// reset css style
import "@/assets/scss/reset.scss";

// element ui global style
import "@/assets/scss/vendors/global.scss";

// title
document.title = "慢箋得來速";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
