import { render, staticRenderFns } from "./HospitalsManagement.vue?vue&type=template&id=451184f2&scoped=true&lang=pug&"
import script from "./HospitalsManagement.vue?vue&type=script&lang=js&"
export * from "./HospitalsManagement.vue?vue&type=script&lang=js&"
import style0 from "./HospitalsManagement.vue?vue&type=style&index=0&id=451184f2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "451184f2",
  null
  
)

export default component.exports