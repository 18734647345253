import api from "@/api";

const state = {
  accountsList: []
};

const getters = {
  accountsList: state => state.accountsList
};
const mutations = {
  ACCOUNTS_LIST(state, accounts) {
    state.accountsList = accounts;
  }
};

const actions = {
  async getAccountsList({ commit }) {
    const { data: accountList } = await api.get("/users");
    commit("ACCOUNTS_LIST", accountList);
  },
  async createAccountHandler({ commit, dispatch }, accountForm) {
    try {
      const { data: accountsList, status } = await api.post(
        "users",
        accountForm
      );

      commit("ACCOUNTS_LIST", accountsList);
      status === 200 &&
        dispatch("successHandler", "帳號建立成功!!", { root: true });
    } catch (e) {
      const { account } = e.response.data.errors;
      account[0] === "The account has already been taken." &&
        dispatch("errorHandler", "這個使用者名稱已有人使用，請試試其他名稱。", {
          root: true
        });
    }
  },
  async updateUserPasswordHandler({ commit, dispatch }, accountForm) {
    const { id, password } = accountForm;
    const { data: accountsList, status } = await api.put(`users/${id}`, {
      password
    });
    commit("ACCOUNTS_LIST", accountsList);
    status === 200 &&
      dispatch("successHandler", "密碼修改成功!!", { root: true });
  },
  async deleteAccountHandler({ commit, dispatch }, id) {
    try {
      const { data: accountsList, status } = await api.delete(`users/${id}`);

      commit("ACCOUNTS_LIST", accountsList);
      status === 200 &&
        dispatch("successHandler", "帳號刪除成功!!", { root: true });
    } catch (e) {
      console.log(e);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
