<template lang="pug">
  el-dialog(
    title="帳號資訊"
    :visible="accountInfoDialogVisible"
    width="900px"
    center
    @close="$emit('update:accountInfoDialogVisible', false); $refs.accountInfoForm.reset()"
  )
    el-row
      el-col
        ValidationObserver(ref="accountInfoForm" v-slot="{ handleSubmit }")
          el-form(
            id="account-info-form"
            :model="accountInfoForm"
            @submit.native.prevent="updateAccountInfoHandler"
          )
            el-form-item(
              :class="{ 'is-active': highlightDialogFormItemsStatusList[0] }"
              @click.native="$refs.pharmacy_name.focus(); highlightDialogFormItemsHandler(0)"
            )
              div(slot="label" class="pharmacy")
                span.star-key *
                span 藥局名稱
              ValidationProvider(rules="notAllowedEmpty|maximum50InputLength" v-slot="{ errors }")
                el-input(
                  class="el-input-medium"
                  type="text"
                  ref="pharmacy_name"
                  v-model="accountInfoForm.pharmacy_name"
                  resize="none"
                  @focus="highlightDialogFormItemsHandler(0)"
                )
                p.error-msg {{ errors[0] }}
            el-form-item(
              :class="{ 'is-active': highlightDialogFormItemsStatusList[2] }"
              @click.native="$refs.pharmacy_address.focus(); highlightDialogFormItemsHandler(2)"
            )
              div(slot="label")
                span.star-key *
                span 藥局地址
              ValidationProvider(rules="notAllowedEmpty|maximum50InputLength" v-slot="{ errors }")
                el-input(
                  class="el-input-medium"
                  type="text"
                  ref="pharmacy_address"
                  v-model="accountInfoForm.pharmacy_address"
                  resize="none"
                  @focus="highlightDialogFormItemsHandler(2)"
                )
                p.error-msg {{ errors[0] }}
            el-form-item()
              div(slot="label")
                span 登入帳號
              ValidationProvider(rules="" v-slot="{ errors }")
                el-input(
                  class="el-input-medium"
                  type="text"
                  ref="account"
                  disabled
                  v-model="accountInfoForm.account"
                  resize="none"
                )
            el-form-item(
              :class="{ 'is-active': highlightDialogFormItemsStatusList[3] }"
              @click.native="$refs.password.focus(); highlightDialogFormItemsHandler(3)"
            )
              div(slot="label")
                span 更改密碼
              ValidationProvider(:rules="accountInfoForm.password ? 'maximum20InputLength' : ''" v-slot="{ errors }")
                el-input(
                  class="el-input-medium"
                  type="password"
                  ref="password"
                  v-model="accountInfoForm.password"
                  resize="none"
                  @focus="highlightDialogFormItemsHandler(3)"
                )
                p.error-msg {{ errors[0] }}
    .dialog-footer(slot="footer")
      el-row(:gutter="20")
        el-col.custom-col-24
          el-col(:span="12")
            el-button(
              class="btn-manage el-button-primary-plain"
              @click="cancel"
            ) 取消
          el-col(:span="12")
            el-button(
              class="btn-manage el-button-primary"
              :form="'account-info-form'"
              native-type="submit"
            ) 確定
</template>

<script>
export default {
  props: {
    accountInfoForm: {
      type: Object
    },
    accountInfoDialogVisible: {
      type: Boolean
    }
  },
  data() {
    return {
      highlightDialogFormItemsStatusList: [false, false, false, false, false]
    };
  },
  methods: {
    async updateAccountInfoHandler() {
      const isValid = await this.$refs.accountInfoForm.validate();
      this.$emit("updateAccountInfoHandler");
      console.log("updateAccountInfoHandler", isValid);
    },
    highlightDialogFormItemsHandler(index) {
      this.highlightDialogFormItemsStatusList.fill(false);
      this.highlightDialogFormItemsStatusList[index] = true;
      this.highlightDialogFormItemsStatusList = [
        ...this.highlightDialogFormItemsStatusList
      ];
    },
    cancel() {
      this.$emit("update:accountInfoDialogVisible", false);
      this.$refs.accountInfoForm.reset();
      this.highlightDialogFormItemsStatusList.fill(false);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #f7f7f7;
  color: #4a4a4a;
  border: none;
}
</style>
