<template lang="pug">
  el-dialog(
    class="deactivate-dialog"
    title="停用"
    :visible="deactivateDialogVisible"
    width="450px"
    center
    @close="$emit('update:deactivateDialogVisible', false)"
  )
    el-row
      el-col(:span="24")
        span.dialog-message {{ msg }}
    .dialog-footer(slot="footer")
      el-row(:gutter="20")
        el-col.custom-col-24
          el-col(:span="12")
            el-button(
              class="btn-manage el-button-danger-plain el-button--large"
              @click="$emit('update:deactivateDialogVisible', false)"
            ) 取消
          el-col(:span="12")
            el-button(
              class="btn-manage el-button-danger el-button--large"
              @click="$emit('deactivateHandler')"
            ) 確定
</template>

<script>
export default {
  props: {
    deactivateDialogVisible: {
      type: Boolean
    },
    msg: {
      type: String
    }
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  components: {},
  mounted() {}
};
</script>

<style lang="scss" scoped></style>
