<template lang="pug">
  div.manage-container 
    .title-bar
      h3.page-title 配送地區列表
    .districts-table-content
      el-table.district-table(:data="deliveryList" style="width: 100%;")
        el-table-column(width="100")
          template(slot="header")
            p.first-col-title 縣市
          template(slot-scope="props")
            p.city {{ props.row.city }}
        el-table-column()
          template(slot="header")
            p 行政區
          template(slot-scope="props")
            el-checkbox.table-checkbox(
              v-for="({ districtName, isOpen }, index) in props.row.administrativeDistricts" 
              :key="index"
              :value="isOpen"
              disabled
            ) {{ districtName }}
        el-table-column(label="" width="103")
          template(slot-scope="props")
            .edit-btns
              el-button.check(@click="openUpdateDistrictsStatusHandler(props.row)")
                img(:src="require('@/assets/images/check.png')")
    el-dialog(
      v-if="districtsDialogVisible"
      title="修改地區"
      :visible.sync="districtsDialogVisible"
      width="450px"
      center
      @close="resetDistrictsForm"
    )
      el-row
        el-col
            el-form(
              id="edit-districts-form"
              :model="districtsForm"
              @submit.native.prevent="onSubmit"
            )
              el-form-item
                div(slot="label")
                  span {{ selectedCity.city }} 
                el-checkbox-group(v-model="districtsForm.checkList")
                  el-checkbox.edit-checkbox(
                    v-for="(district, index) in selectedCity.administrativeDistricts"
                    :key="index"
                    :label="district.districtName"
                    :checked="district.isOpen"
                  )
      .dialog-footer(slot="footer")
        el-row(:gutter="20")
          el-col.custom-col-24
            el-col(:span="12")
              el-button(
                class="btn-manage el-button-primary-plain el-button--large"
                @click="resetDistrictsForm"
              ) 取消
            el-col(:span="12")
              el-button(
                class="btn-manage el-button-primary el-button--large"
                native-type="submit"
                :form="'edit-districts-form'"
              ) 確定

</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";

// components

// assets

// config

export default {
  props: {},
  data() {
    return {
      districtsDialogVisible: false,
      districtsForm: {
        checkList: []
      },
      selectedCity: {
        id: 0,
        city: "",
        administrativeDistricts: []
      }
    };
  },
  methods: {
    ...mapActions({}),
    resetDistrictsForm() {
      this.districtsDialogVisible = false;
      this.districtsForm = {
        checkList: []
      };
      this.selectedCity = {
        id: 0,
        city: "",
        administrativeDistricts: []
      };
    },
    async onSubmit() {
      // 功能名稱依據頁面自訂修改
      const mapping = this.mappingdeliveryDistrictsStatusHandler();
      await this.$store.dispatch("districts/updateDeliveryDistrictsHandler", {
        ...this.selectedCity,
        administrativeDistricts: mapping
      });

      this.successMsg &&
        (window.alert(`${this.successMsg}`),
        (this.districtsDialogVisible = false),
        this.resetDistrictsForm(),
        this.$store.dispatch("successHandler", ""));
    },
    mappingdeliveryDistrictsStatusHandler() {
      const { administrativeDistricts } = this.selectedCity;
      const { checkList } = this.districtsForm;
      return administrativeDistricts.map(district => {
        const isSelected = checkList.some(
          name => name === district.districtName
        );
        return {
          id: district.id,
          name: district.districtName,
          is_open: isSelected
        };
      });
    },
    openUpdateDistrictsStatusHandler(city) {
      this.districtsDialogVisible = true;
      this.selectedCity = city;
    }
  },
  computed: {
    ...mapGetters({
      deliveryList: "districts/deliveryList"
    }),
    successMsg() {
      return this.$store.state.successMsg;
    }
  },
  components: {},
  async mounted() {
    await this.$store.dispatch("districts/getDistrictsList");
  }
};
</script>

<style lang="scss" scoped>
.district-table {
  ::v-deep .el-table__row {
    .cell {
      padding: 2px 10px;
      line-height: 18px;
    }
  }
}

.table-checkbox {
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: not-allowed;
  .el-checkbox__input {
    cursor: not-allowed;
  }
  // disabled but not checked
  ::v-deep .el-checkbox__input.is-disabled {
    .el-checkbox__inner {
      border: 2px solid $third;
      background: $fifth;
    }
    & + span.el-checkbox__label {
      color: $third;
    }
  }
  // disabled and checked
  ::v-deep .el-checkbox__input.is-disabled.is-checked {
    .el-checkbox__inner {
      border: $third;
      background: $third;
      &::after {
        border-color: #fff;
        left: 5px;
        height: 8px;
      }
    }
  }
}

.edit-checkbox {
  ::v-deep .el-checkbox__input {
    .el-checkbox__inner {
      border: 2px solid $third;
      background: $fifth;
    }
    & + span.el-checkbox__label {
      color: $third;
    }
  }
  // disabled and checked
  ::v-deep .el-checkbox__input.is-checked {
    .el-checkbox__inner {
      border: $primary;
      background: $primary;
      &::after {
        border-color: #fff;
        left: 5px;
        height: 8px;
      }
    }
  }
}

::v-deep .el-form-item__content {
  line-height: 25px;
}

::v-deep .el-form-item__label {
  width: 100%;
  margin-bottom: 16px;
  text-align: left;
  border-left: 5px solid #4a4a4a;
  padding-left: 10px;
  line-height: 1.5;
}

::v-deep .el-dialog--center {
  .el-dialog__body {
    padding: 25px 25px 20px 25px;
  }
}

.city {
  position: absolute;
  top: 12px;
  left: 25px;
}
</style>
