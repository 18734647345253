<template lang="pug">
  el-dialog(
    class="activate-dialog"
    title="啟用"
    :visible="activateDialogVisible"
    width="450px"
    center
    @close="$emit('update:activateDialogVisible', false)"
  )
    el-row
      el-col(:span="24")
        span.dialog-message {{ msg }}
    .dialog-footer(slot="footer")
      el-row(:gutter="20")
        el-col.custom-col-24
          el-col(:span="12")
            el-button(
              class="btn-manage el-button-primary-plain el-button--large"
              @click="$emit('update:activateDialogVisible', false)"
            ) 取消
          el-col(:span="12")
            el-button(
              class="btn-manage el-button-primary el-button--large"
              @click="$emit('activateHandler')"
            ) 確定
</template>

<script>
export default {
  props: {
    activateDialogVisible: {
      type: Boolean
    },
    msg: {
      type: String
    }
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  components: {},
  mounted() {}
};
</script>

<style lang="scss" scoped></style>
