import Vue from "vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
// Register it globally
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

// Add rules

extend("empty", {
  // 需要確認規則
  validate: value => {
    if (value === "") {
      return "該欄位不得為空";
    } else return true;
  },
  message: "",
  computesRequired: true
});

extend("phone", {
  // 需要確認規則
  validate: value => {
    const regPhone = /(\d{2,3}-?|\(\d{2,3}\))\d{3,4}-?\d{4}|09\d{2}-?(\d{6}|-\d{3}-\d{3})/;
    const isInvalid = regPhone.test(value);
    if (!isInvalid) {
      return "電話格式有誤，範例: 02-12341234, 0911123123";
    } else return true;
  },
  message: "",
  computesRequired: true
});

extend("orderRules", {
  // 不得為空
  params: ["type"],
  validate: (value, { type }) => {
    if (type === "審核訂單" && value) return true;
    return "該欄位不得為空，請確實填寫!!";
  },
  computesRequired: true
});

extend("invalidInput", {
  // 過濾特殊字元的輸入
  validate: value => {
    const regEn = /[`~!@#$%^&*()_+<>?:"{}\\,./=;'[\]]/;
    const regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/;
    const isinValidENInput = regEn.test(value);
    const isinValidCNInput = regCn.test(value);
    if (isinValidENInput || isinValidCNInput) {
      return "不得輸入~!@#$%^&*()_+<>?:\"\\-,./=;'[]之類的字符";
    } else {
      return true;
    }
  },
  message: "",
  computesRequired: true
});

extend("ChineseInput", {
  // 過濾中文的輸入
  validate: value => {
    const regCn = /[\u4e00-\u9fd5]/g;
    const isinValidCNInput = regCn.test(value);
    if (isinValidCNInput) {
      return "不得輸入中文";
    } else {
      return true;
    }
  },
  message: "",
  computesRequired: true
});

extend("notAllowedEmpty", {
  // 不得為空
  validate: value => {
    if (!value) {
      return "該欄位不得為空，請確實填寫!!";
    } else {
      return true;
    }
  },
  computesRequired: true
});

extend("maximum20InputLength", {
  validate: value => {
    if (value.length > 20) {
      return "不得大於 20 個字";
    } else return true;
  },
  message: "",
  computesRequired: true
});

extend("maximum30InputLength", {
  validate: value => {
    if (value.length > 20) {
      return "不得大於 30 個字";
    } else return true;
  },
  message: "",
  computesRequired: true
});

extend("maximum50InputLength", {
  validate: value => {
    if (value.length > 50) {
      return "不得大於 50 個字";
    } else return true;
  },
  message: "",
  computesRequired: true
});

extend("maximum200InputLength", {
  validate: value => {
    if (value.length > 200) {
      return "不得大於 200 個字";
    } else return true;
  },
  message: "",
  computesRequired: true
});
