<template lang="pug">
  .permission-container 
    .permission-table
      .title-bar
        h3.page-title 帳號列表
        el-button.add(
          class="btn-manage el-button--large el-button-secondary"
          @click="accountDialogVisible = true"
        ) 新增
      .permission-table-content
        el-table(:data="accountsList" style="width: 100%;")
          el-table-column(width="370")
            template(slot="header")
              p.first-col-title 帳號
            template(slot-scope="props")
              p.account {{ props.row.account }}
          el-table-column(label="")
            template(slot-scope="props")
              .edit-btns
                el-button.check(@click="openEditPasswordDialog(props.row)")
                  img(:src="require('@/assets/images/check.png')")
                el-button.delete(@click="openDeleteAccountDialog(props.row)")
                  img(:src="require('@/assets/images/delete.png')")
    el-dialog(
      class="account-dialog"
      v-if="accountDialogVisible"
      :title="!isEditing ? '新增帳號' : '修改帳號資訊'"
      :visible.sync="accountDialogVisible"
      width="450px"
      center
      @close="resetLoginForm"
    )
      el-row
        el-col(:span="24")
          ValidationObserver(ref="accountForm" v-slot="{ handleSubmit }")
            el-form(
              id="login-form"
              :model="accountForm"
              @submit.native.prevent="handleSubmit(createAccountHandler)"
            )
              el-form-item(
                @click.native="$refs.account.focus(); highlightHandler(0)"
                :class="{ 'is-active': highlightStatusList[0] && !isEditing }"
              )
                div(slot="label")
                  span(v-if="!isEditing" class="star-key") *
                  span 帳號
                ValidationProvider(rules="invalidInput|ChineseInput" v-slot="{ errors }")
                  el-input(
                    class="el-input-medium"
                    :disabled="isEditing ? true : null"
                    type="text"
                    ref="account"
                    v-model="accountForm.account"
                    @focus="highlightHandler(0)"
                    resize="none"
                  )
                  p.error-msg {{ errors[0] }}
              el-form-item(
                @click.native="$refs.password.focus(); highlightHandler(1)"
                :class="{ 'is-active': highlightStatusList[1] }"
              )
                div(slot="label")
                  span(class="star-key") *
                  span 密碼
                ValidationProvider(rules="invalidInput|ChineseInput" v-slot="{ errors }")
                  el-input(
                    class="el-input-medium"
                    type="password"
                    ref="password"
                    v-model="accountForm.password"
                    @focus="highlightHandler(1)"
                    resize="none"
                  )
                  p.error-msg {{ errors[0] }}
      .dialog-footer(slot="footer")
        el-row(:gutter="20")
          el-col.custom-col-24(v-if="isEditing" :span="24")
            el-col(:span="12")
              el-button(
                class="btn-manage el-button-primary-plain el-button--large" 
                @click="resetLoginForm"
              ) 取消
            el-col(:span="12")
              el-button(
                class="btn-manage el-button-primary el-button--large"
                @click="updateUserPasswordHandler"
              ) 確定
          el-col.custom-col-24(v-else :span="24")
            //- 新增帳號
            el-col(:span="12")
              el-button(
                class="btn-manage el-button-primary-plain el-button--large" 
                @click="resetLoginForm"
              ) 取消
            el-col(:span="12")
              el-button(
                class="btn-manage el-button-primary el-button--large"
                :form="'login-form'"
                native-type="submit"
              ) 確定
    el-dialog(
      class="delete-dialog"
      v-if="deleteDialogVisible"
      title="刪除"
      :visible.sync="deleteDialogVisible"
      width="450px"
      center
      @close="closeDeleteAccountDialog"
    )
      el-row
        el-col(:span="24")
          span.dialog-message 是否確定要刪除此帳號?
      .dialog-footer(slot="footer")
        el-row(:gutter="20")
          el-col.custom-col-24
            el-col(:span="12")
              el-button(
                class="btn-manage el-button-danger-plain el-button--large"
                @click="closeDeleteAccountDialog"
              ) 取消
            el-col(:span="12")
              el-button(
                class="btn-manage el-button-danger el-button--large"
                @click="deleteAccountHandler"
              ) 確定


</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";

export default {
  props: {},
  data() {
    return {
      accountForm: {
        account: "",
        password: ""
      },
      deleteUserId: null,
      accountDialogVisible: false,
      deleteDialogVisible: false,
      isEditing: false,
      highlightStatusList: [false, false]
    };
  },
  methods: {
    ...mapActions({}),
    async createAccountHandler() {
      await this.$store.dispatch(
        "permission/createAccountHandler",
        this.accountForm
      );

      this.errorMsg &&
        (alert(`${this.errorMsg}`), this.$store.dispatch("errorHandler", ""));

      this.successMsg &&
        (alert(`${this.successMsg}`),
        this.$store.dispatch("successHandler", ""),
        this.resetLoginForm());
    },
    resetLoginForm() {
      this.accountForm = {
        account: "",
        password: ""
      };
      this.isEditing = false;
      this.accountDialogVisible = false;
      this.highlightStatusList.fill(false);
      this.$refs.accountForm.reset();
    },
    openEditPasswordDialog(accountForm) {
      this.isEditing = true;
      this.accountDialogVisible = true;
      this.accountForm = {
        ...accountForm,
        password: ""
      };
    },
    async updateUserPasswordHandler() {
      await this.$store.dispatch(
        "permission/updateUserPasswordHandler",
        this.accountForm
      );

      this.successMsg &&
        (alert(`${this.successMsg}`),
        this.resetLoginForm(),
        this.$store.dispatch("successHandler", ""));
    },
    openDeleteAccountDialog({ id }) {
      this.deleteDialogVisible = true;
      this.deleteUserId = id;
    },
    closeDeleteAccountDialog() {
      this.deleteUserId = null;
      this.deleteDialogVisible = false;
    },
    async deleteAccountHandler() {
      await this.$store.dispatch(
        "permission/deleteAccountHandler",
        this.deleteUserId
      );

      this.successMsg &&
        (alert(`${this.successMsg}`),
        (this.deleteUserId = null),
        (this.deleteDialogVisible = false));
    },
    highlightHandler(index) {
      this.highlightStatusList.fill(false);
      this.highlightStatusList[index] = true;
      this.highlightStatusList = [...this.highlightStatusList];
    }
  },
  computed: {
    ...mapGetters({
      accountsList: "permission/accountsList"
    }),
    successMsg() {
      return this.$store.state.successMsg;
    },
    errorMsg() {
      return this.$store.state.errorMsg;
    }
  },
  components: {},
  async mounted() {
    await this.$store.dispatch("permission/getAccountsList");
  }
};
</script>

<style lang="scss" scoped>
.permission-container {
  margin-top: 50px;
}
.permission-table {
  width: 500px;
  margin: 0 auto;
  .permission-table-content {
    .account {
      color: $eleventh;
      font-size: 13px;
      padding-left: 15px;
    }
  }
  .check {
    margin-right: 10px;
  }
}

#login-form {
  .el-form-item {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.account-dialog {
  ::v-deep .el-dialog--center {
    .el-dialog__body {
      padding: 23px 25px 35px 25px;
    }
  }
}

.delete-dialog {
  ::v-deep .el-dialog__body {
    padding: 25px 25px 35px 25px;
  }
}

::v-deep .el-form-item__label {
  margin-bottom: 1px;
}
</style>
