<template lang="pug">
  el-dialog(
    class="delete-dialog"
    title="刪除"
    :visible="deleteDialogVisible"
    width="450px"
    center
    @close="$emit('update:deleteDialogVisible', false); $emit('update:deleteOrderNumber', null);"
  )
    el-row
      el-col(:span="24")
        span.dialog-message 是否確定要刪除此{{ msg }}?
    .dialog-footer(slot="footer")
      el-row(:gutter="20")
        el-col.custom-col-24
          el-col(:span="12")
            el-button(
              class="btn-manage el-button-danger-plain el-button--large"
              @click="$emit('update:deleteDialogVisible', false); $emit('update:deleteOrderNumber', null);"
            ) 取消
          el-col(:span="12")
            el-button(
              class="btn-manage el-button-danger el-button--large"
              @click="$emit('deleteHandler')"
            ) 確定
</template>

<script>
export default {
  props: {
    deleteDialogVisible: {
      type: Boolean
    },
    msg: {
      type: String
    },
    deleteId: {
      type: Number
    }
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  components: {},
  mounted() {}
};
</script>

<style lang="scss" scoped></style>
