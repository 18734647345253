<template lang="pug">
  el-dialog(
    title="預約失敗"
    :visible="reserveFailDialogVisible"
    width="450px"
    center
    append-to-body
    @close="$emit('resetReserveFailForm')"
  )
    el-row
      el-col
        ValidationObserver(ref="reserveFailForm" v-slot="{ handleSubmit }")
          el-form(
            id="reserve-fail-form"
            :model="reserveFailForm"
            @submit.native.prevent="handleSubmit(onSubmit)"
          )
            el-form-item(
              @click.native="$refs.failStatus.toggleMenu(); highlightHandler(0)"
            )
              div(slot="label" :class="{ 'is-active': highlightStatusList[0] }")
                span.star-key(v-if="!doubleChecked") *
                span 預約失敗的原因
              ValidationProvider(rules="invalidInput" v-slot="{ errors }")
                el-select(
                  ref="failStatus"
                  :value="failStatus"
                  @change="$emit('update:failStatus', $event);"
                  placeholder="請選擇"
                  size="medium"
                  :disabled="doubleChecked"
                  @focus="highlightHandler(0)"
                )
                  el-option(
                    v-for="item in failedStatusOptions"
                    :key="item.id"
                    :label="item.label"
                    :value="item.value"
                  )
                p.error-msg {{ errors[0] }}
            el-form-item(
              @click.native="$refs.reason.focus(); highlightHandler(1)"
              v-if="failStatus === 706" 
            )
              div(slot="label" :class="{ 'is-active': highlightStatusList[1] }")
                span.star-key(v-if="!doubleChecked") *
                span 回覆內容 
              ValidationProvider(:rules="`orderRules:審核訂單|invalidInput`" v-slot="{ errors }") 
                el-input(
                  :class="{ 'is-doubled-checked': doubleChecked }"
                  :disabled="doubleChecked"
                  class="el-input-medium"
                  type="textarea"
                  ref="reason"
                  :value="reason"
                  @input="$emit('update:reason', $event)"
                  resize="none"
                  @focus="highlightHandler(1)"
                )
                p.error-msg {{ errors[0] }}
    .dialog-footer(slot="footer")
      el-row
        el-col
          el-col(:span="11")
            el-button(
              v-if="!doubleChecked"
              class="btn-manage el-button-primary-plain"
              @click="$emit('resetReserveFailForm')"
            ) 取消
            el-button(
              v-if="doubleChecked"
              class="btn-manage el-button-primary-plain"
              @click="$emit('update:doubleChecked', false)"
            ) 返回
          el-col(:span="11" :offset="2")
            el-button(
              class="btn-manage el-button-primary"
              :form="'reserve-fail-form'"
              native-type="submit"
            ) 確定
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";

// components

// views

// assets

// config
import { failedStatusOptions } from "@/components/frontend-manage/OrdersManagement/options.js";

export default {
  props: {
    reserveFailDialogVisible: {
      type: Boolean
    },
    doubleChecked: {
      type: Boolean
    },
    reserveFailForm: {
      type: Object
    },
    reason: {
      type: String
    },
    failStatus: {
      type: Number
    }
  },
  data() {
    return {
      failedStatusOptions,
      highlightStatusList: [false, false]
    };
  },
  methods: {
    ...mapActions({}),
    onSubmit() {
      if (this.doubleChecked) {
        this.$emit("onFailDialogSubmit");
        this.$refs.reserveFailForm.reset();
        this.highlightStatusList.fill(false);
      } else {
        this.$emit("update:doubleChecked", true);
      }
    },
    highlightHandler(index) {
      this.highlightStatusList.fill(false);
      this.highlightStatusList[index] = true;
      this.highlightStatusList = [...this.highlightStatusList];
    }
  },
  watch: {
    failStatus(value) {
      this.failStatus !== "其他" ? this.$emit("update:reason", "") : value;
    }
  },
  computed: {
    ...mapGetters({})
  },
  components: {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
#reserve-fail-form {
  ::v-deep .el-select {
    .el-input__suffix {
      top: 26px;
    }
  }

  ::v-deep .el-form-item {
    &:first-child {
      margin-bottom: 16px;
    }
  }

  ::v-deep .el-textarea__inner {
    min-height: 150px !important;
  }

  .is-active {
    color: $primary;
  }

  .is-doubled-checked {
    ::v-deep .el-textarea__inner {
      background: $sixth;
    }
  }
}
</style>
