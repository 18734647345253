export const frontend_manage = [
  {
    path: "/manage",
    name: "Home",
    component: () => import("@/views/frontend-manage/Home.vue"),
    children: [
      {
        path: "permission",
        name: "Permission",
        meta: { auth: true, type: "manage" },
        component: () =>
          import("@/views/frontend-manage/PermissionManagement.vue")
      },
      {
        path: "district",
        name: "District",
        meta: { auth: true, type: "manage" },
        component: () =>
          import("@/views/frontend-manage/DistrictManagement.vue")
      },
      {
        path: "pharmacies",
        name: "Pharmacies",
        meta: { auth: true, type: "manage" },
        component: () =>
          import("@/views/frontend-manage/PharmaciesManagement.vue")
      },
      {
        path: "pharmacies/new",
        name: "AddPharmacies",
        meta: { auth: true, type: "manage" },
        component: () => import("@/views/frontend-manage/AddPharmacies.vue")
      },
      {
        path: "contact",
        name: "Contact",
        meta: { auth: true, type: "manage" },
        component: () => import("@/views/frontend-manage/ContactManagement.vue")
      },
      {
        path: "orders",
        name: "Orders",
        meta: { auth: true, type: "manage" },
        component: () => import("@/views/frontend-manage/OrdersManagement.vue")
      },
      {
        path: "hospitals",
        name: "Hospitals",
        meta: { auth: true, type: "manage" },
        component: () =>
          import("@/views/frontend-manage/HospitalsManagement.vue")
      },
      {
        path: "branches",
        name: "Branches",
        meta: { auth: true, type: "manage" },
        component: () =>
          import("@/views/frontend-manage/BranchesManagement.vue")
      },
      {
        path: "pharmacy-branches-orders",
        name: "PharmacyBranchesOrders",
        meta: { auth: true, type: "manage" },
        // 分支藥局-訂單管理
        component: () =>
          import("@/views/frontend-manage/PharmacyBranchesOrdersManagement.vue")
      },
      {
        path: "branches-orders",
        name: "BranchesOrders",
        meta: { auth: true, type: "manage" },
        // 藥局訂單
        component: () =>
          import("@/views/frontend-manage/BranchesOrdersManagement.vue")
      }
    ]
  },
  {
    path: "/manage/login",
    name: "Login",
    meta: { type: "manage" },
    component: () => import("@/views/frontend-manage/Login.vue")
  },
  {
    path: "/manage/*",
    name: "Any",
    meta: { type: "manage" },
    redirect: { name: "Permission" }
  }
];
