<template lang="pug">
  .history-pharmacys
    TitleBar(:title="'選擇預約領藥醫院'")
    .container(v-if="!showConfirmView")
      HospitalsList(
        :list="hospitalsList"
        :selected.sync="selected"
        @click.native="selectHospitalHandler"
      )
    //- .container(v-if="showConfirmView")
    //-   ConfirmPharmacies(
    //-     :selected.sync="selected"
    //-     :markers="mapMarkers"
    //-     :type="'hospitals'"
    //-   )
    el-button(
      v-if="!showConfirmView"
      class="btn el-button--large choose-other"
      @click="$router.push({ name: 'ChoosePharmacies' })"
    ) 更換至藥局領藥
    //- el-button(
    //-   v-if="showConfirmView"
    //-   class="btn el-button-primary-plain el-button--large go-back"
    //-   @click.native="showConfirmView = false"
    //- ) 上一步
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";

// components
import TitleBar from "@/components/frontend/Base/TitleBar.vue";
import HospitalsList from "@/components/frontend/HospitalsList.vue";
import ConfirmPharmacies from "@/components/frontend/ConfirmPharmacies.vue";
// views

// assets

export default {
  props: {},
  data() {
    return {
      selected: {},
      showConfirmView: false
    };
  },
  methods: {
    ...mapActions({}),
    selectHospitalHandler() {
      if (!this.hospitalsList.length) return;
      const hospitalQueryRecords = {
        selected: this.selected
      };
      this.$store.dispatch(
        "frontend_choose_hospitals/storeHospitalQueryRecords",
        hospitalQueryRecords
      );
      this.sendInfoHandler();
      this.$router.push({ name: "PrescriptionPhoto" });
    },
    sendInfoHandler() {
      const sendInfo = {
        method: 3, // 1: 配送到府 ; 2: 藥局配送 ; 3: 醫院
        pick_up_location: {
          id: this.selected.id
        }
      };

      this.$store.dispatch("sendInfoHandler", sendInfo);
    }
  },
  computed: {
    ...mapGetters({
      hospitalsList: "frontend_choose_hospitals/hospitalsList",
      hospitalQueryRecords: "frontend_choose_hospitals/hospitalQueryRecords"
    })
    // mapMarkers() {
    //   // 歷史藥局每次都只會拿到一個座標點
    //   return [
    //     {
    //       lat: this.selected.lat,
    //       lng: this.selected.lng
    //     }
    //   ];
    // }
  },
  components: {
    TitleBar,
    HospitalsList,
    ConfirmPharmacies
  },
  async mounted() {
    const { showConfirmView, selected } = this.hospitalQueryRecords;
    if (showConfirmView) {
      this.showConfirmView = showConfirmView;
      this.selected = selected;
    } else {
      await this.$store.dispatch(
        "frontend_choose_hospitals/getHospitalsListList"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0;
}

.choose-other {
  position: fixed;
  bottom: 16px;
  left: 25px;
  width: calc(100% - 50px);
  z-index: 2;
  border-radius: 5px;
  text-align: center;
  padding: 11px 0;
  color: $fifth;
  background: $twentyThree;
}

.go-back {
  width: calc(100% - 50px);
  position: fixed;
  left: 25px;
  bottom: 32px;
  z-index: 2;
}
</style>
