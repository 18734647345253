var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register"},[_c('TitleBar',{attrs:{"title":'填寫聯絡人資料'}}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"form-container"},[_c('div',{staticClass:"register-form"},[_c('ValidationObserver',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('el-form',{attrs:{"id":"register-form","model":_vm.registerForm},nativeOn:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('p',{staticClass:"tips"},[_vm._v("*請務必填寫您的真實資料")]),_c('el-form-item',{class:{ 'is-active': _vm.highlightStatusList[0] },nativeOn:{"click":function($event){_vm.$refs.lastName.focus(); _vm.highlightHandler(0)}}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_c('span',{staticClass:"star-key"},[_vm._v("*")]),_c('span',[_vm._v("姓氏")])]),_c('ValidationProvider',{attrs:{"rules":"invalidInput|empty"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{ref:"lastName",staticClass:"el-input-medium",attrs:{"type":"text","resize":"none"},on:{"focus":function($event){return _vm.highlightHandler(0)}},model:{value:(_vm.registerForm.lastName),callback:function ($$v) {_vm.$set(_vm.registerForm, "lastName", $$v)},expression:"registerForm.lastName"}}),_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{class:{ 'is-active': _vm.highlightStatusList[1] },nativeOn:{"click":function($event){_vm.$refs.firstName.focus(); _vm.highlightHandler(1)}}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_c('span',{staticClass:"star-key"},[_vm._v("*")]),_c('span',[_vm._v("名字")])]),_c('ValidationProvider',{attrs:{"rules":"invalidInput|empty"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{ref:"firstName",staticClass:"el-input-medium",attrs:{"type":"text","resize":"none"},on:{"focus":function($event){return _vm.highlightHandler(1)}},model:{value:(_vm.registerForm.firstName),callback:function ($$v) {_vm.$set(_vm.registerForm, "firstName", $$v)},expression:"registerForm.firstName"}}),_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{staticClass:"gender",class:{ 'is-active': _vm.highlightStatusList[2] },nativeOn:{"click":function($event){return _vm.highlightHandler(2)}}},[_c('div',{staticClass:"gender-label",attrs:{"slot":"label"},slot:"label"},[_c('span',{staticClass:"star-key"},[_vm._v("*")]),_c('span',[_vm._v("性別")])]),_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-radio',{attrs:{"label":"先生"},model:{value:(_vm.registerForm.gender),callback:function ($$v) {_vm.$set(_vm.registerForm, "gender", $$v)},expression:"registerForm.gender"}},[_vm._v("先生")]),_c('el-radio',{attrs:{"label":"小姐"},model:{value:(_vm.registerForm.gender),callback:function ($$v) {_vm.$set(_vm.registerForm, "gender", $$v)},expression:"registerForm.gender"}},[_vm._v("小姐")])]}}],null,true)})],1),_c('el-form-item',{class:{ 'is-active': _vm.highlightStatusList[3] },nativeOn:{"click":function($event){_vm.$refs.phone.focus(); _vm.highlightHandler(3)}}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_c('span',{staticClass:"star-key"},[_vm._v("*")]),_c('span',[_vm._v("聯絡電話")])]),_c('ValidationProvider',{attrs:{"rules":"phone|invalidInput|empty"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{ref:"phone",staticClass:"el-input-medium",attrs:{"type":"text","placeholder":"ex:02-12341234, 0911123123","resize":"none"},on:{"focus":function($event){return _vm.highlightHandler(3)}},model:{value:(_vm.registerForm.phone),callback:function ($$v) {_vm.$set(_vm.registerForm, "phone", $$v)},expression:"registerForm.phone"}}),_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"agree"},[_c('el-checkbox',{model:{value:(_vm.registerForm.checked),callback:function ($$v) {_vm.$set(_vm.registerForm, "checked", $$v)},expression:"registerForm.checked"}},[_vm._v("同意以下隱私權及使用條款")])],1)],1)]}}])})],1),_c('div',{staticClass:"authorization"},[_vm._v("慢箋病患本人及其已授權之代理人(聯絡人)同意本服務所登錄使用之聯絡人資訊、病患資料、慢箋照片(圖片)及個資內容資訊，用於慢箋管理服務、處方調劑收受核對與交付、及相關健康管理使用；本服務將對上述所登錄之資訊進行保密及善盡適法合規之義務。")])]),_c('el-button',{staticClass:"btn el-button-primary el-button--large register-btn",attrs:{"form":'register-form',"native-type":"submit"}},[_vm._v("確定送出")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }