<template lang="pug">
  div(class="street") 
      .info-container
        .info(v-if="selectedCity") {{ selectedCity }}
        .info(v-if="selectedAdministrativeDistricts") {{ selectedAdministrativeDistricts }}
        .info(v-if="selectedStreet") {{ selectedStreet }}
      .citites-container(v-if="!selectedCity")
        p 請選擇縣市
        div
          .citites(
            v-for="(city, index) in citiesList" 
            :key="index"
            @click="$emit('update:selectedCity', city);  chooseCitySendMode()"
          ) {{ city }}
      .administrativeDistricts-container(
        v-if="selectedCity && !selectedAdministrativeDistricts"
        )
        p 請選擇行政區
        div
          .administrativeDistricts(
            v-for="(administrativeDistrict, index) in districtsList" 
            :key="index"
            @click="$emit('update:selectedAdministrativeDistricts', administrativeDistrict); chooseDistrictSendMode()"
          ) {{ administrativeDistrict }}
      .streets-container
        template(v-if="type === 'pharmacy' && selectedAdministrativeDistricts && !selectedStreet")
          p 請選擇街道
          div
            .streets(
              v-for="(street, index) in streetList" 
              :key="index"
              @click="$emit('update:selectedStreet', street); $emit('getPharmaciesList', {searchType:'街道搜尋', status:'street'})"
            ) {{ street }}
        template(v-if="type === 'delivery' && selectedAdministrativeDistricts && !selectedStreet")
          p 請輸入送藥地址
          input(
            type="text"
            class="el-input__inner el-input--medium"
            :value="selectedStreet"
            @change="updateSeletedStreetHandler"
          )
      div(class='feature-btns')
        el-button(
          v-if="selectedCity || selectedAdministrativeDistricts || selectedStreet" 
          @click="goBackHandler"
          class="btn el-button-primary-plain el-button--large go-back"
        ) 上一步
        el-button(
          v-if="selectedCity && selectedAdministrativeDistricts && selectedStreet && type === 'delivery'" 
          @click="sendHandler"
          class="btn el-button-primary el-button--large go-back"
        ) 確定送出
        el-button(
          v-if="type === 'delivery'" 
          @click="$router.push({ name: 'ChoosePharmacies' })"
          class="btn el-button-third el-button--large"
        ) 更換至藥局領藥
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";

// components

// views

// assets

export default {
  props: {
    selectedCity: {
      type: String
    },
    selectedAdministrativeDistricts: {
      type: String
    },
    selectedStreet: {
      type: String
    },
    citiesList: {
      type: Array
    },
    districtsList: {
      type: Array
    },
    streetList: {
      type: Array
    },
    selected: {
      type: Object
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      isDelay: false
    };
  },
  methods: {
    updateSeletedStreetHandler($event) {
      setTimeout(() => {
        if (!this.isDelay) {
          this.$emit("update:selectedStreet", $event.target.value);
        }
        this.isDelay = false;
      }, 50);
    },
    ...mapActions({}),
    chooseCitySendMode() {
      if (this.type === "delivery") {
        this.$emit("getPharmaciesList", {
          searchType: "配送到府",
          status: "city"
        });
      } else {
        this.$emit("getPharmaciesList", {
          searchType: "街道搜尋",
          status: "city"
        });
      }
    },
    chooseDistrictSendMode() {
      if (this.type === "delivery") {
        this.$emit("getPharmaciesList", {
          searchType: "配送到府",
          status: "district"
        });
      } else {
        this.$emit("getPharmaciesList", {
          searchType: "街道搜尋",
          status: "district"
        });
      }
    },
    goBackHandler() {
      this.isDelay = true;
      if (this.selectedCity && !this.selectedAdministrativeDistricts) {
        this.$emit("update:selectedCity", "");
      }
      if (this.selectedAdministrativeDistricts && !this.selectedStreet) {
        this.$emit("update:selectedAdministrativeDistricts", "");
      }
      if (this.selectedStreet) {
        this.isDelay = false;
        this.$emit("update:selectedStreet", "");
      }
    },
    sendHandler() {
      this.$emit("sendInfoHandler");
    }
  },
  computed: {
    ...mapGetters({})
  },
  components: {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
.street {
  .info-container {
    margin: 16px 0;
  }
  .info {
    text-align: center;
    background: $fifth;
    padding: 12px 0;
    color: $primary;
    font-weight: 600;
    font-size: 14px;
  }
  .administrativeDistricts-container {
    > div {
      padding-bottom: 80px;
    }
  }
  .citites-container,
  .administrativeDistricts-container,
  .streets-container {
    p {
      color: $primary;
      margin-bottom: 10px;
      font-weight: 600;
    }
    > div {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .citites,
  .administrativeDistricts,
  .streets {
    padding: 9px 0;
    text-align: center;
    background: $fifth;
    border-radius: 5px;
    margin-bottom: 10px;
    color: $third;
    font-weight: 600;
  }
  .citites,
  .administrativeDistricts {
    width: 31.33%;
    &:nth-child(3n + 2) {
      margin: 0 3% 10px;
    }
  }
  .streets {
    width: 48%;
    &:nth-child(2n) {
      margin-left: 4%;
    }
  }
  .street-pharmacy-list {
    p {
      color: $primary;
      margin-bottom: 10px;
    }
  }
}

.feature-btns {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 32px;
  z-index: 2;
  background: $eighth;

  @media (max-height: 667px) {
    bottom: 10px;
  }

  button {
    margin-left: 25px;
    margin-bottom: 10px;
    width: calc(100% - 50px);
    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-button + .el-button {
    margin-left: 25px;
  }
}

.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border: 1px solid $primary;
}
</style>
