import api from "@/api";

const mappingHistoryInfo = state => {
  const { data, current_page } = state.historyInfo;
  const list = data.map(item => {
    return {
      id: item.id,
      status: item.status,
      orderDate: item.created_at,
      receiveMedicineName: item.receiver,
      startReceiveMedicineDate: item.started_at,
      endReceiveMedicineDate: item.ended_at,
      failedReason: item.failed_message
    };
  });
  return {
    currentPage: current_page,
    list
  };
};

const state = {
  historyInfo: {
    data: [],
    currentPage: 1
  }
};
const getters = {
  historyInfo: state => mappingHistoryInfo(state)
};

const mutations = {
  HISTORY_INFO(state, historyInfo) {
    state.historyInfo = historyInfo;
  }
};

const actions = {
  async getHistoryRecordsList({ commit }) {
    try {
      const { data: historyInfo } = await api.get("/member/orders");
      commit("HISTORY_INFO", historyInfo);
    } catch (e) {
      console.log(e);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
