<template lang="pug">
  .nav-bar
    .logo-container(@click="$router.push({ name: 'Orders' }, () => {})")
      div.logo-img
        img(:src="require('@/assets/images/main-logo.png')")
      h1 慢箋得來速後台
    .menu
      nav.menu-list
        span.business-type(v-if="isAdminAndCenter") B2B
          i.el-icon-arrow-right
        router-link(
          :to="{ name: 'Branches' }"
          v-if="isAdminAndCenter"
        ) 藥局管理
        router-link(v-if="isAdminAndCenter" :to="{ name: 'BranchesOrders', query: { page: 1 } }") 慢箋訂單
        span.business-type.b2c(
          :style="{'border-left': isAdminAndCenter ? '1px solid #b4b4b5' : 'none'}"
        ) B2C
          i.el-icon-arrow-right
        router-link(
          :to="{ name: 'Permission' }" 
          v-if="isAdminAndCenter"
        ) 管理員設置
        router-link(
          :to="{ name: 'Hospitals' }"
          v-if="isAdminAndCenter"
        ) 醫院管理
        router-link(
          :to="{ name: 'District' }"
          v-if="isAdminAndCenter"
        ) 地區管理
        router-link(
          :to="{ name: 'Pharmacies', query: { page: 1 } }"
          v-if="isAdminAndCenter"
        ) 藥局管理
        router-link(:to="{ name: 'Contact', query: { page: 1 } }") 聯絡人管理
        router-link(v-if="isCenter" :to="{ name: 'Orders', query: { page: 1 } }") 訂單管理
        router-link(v-if="isBranch" :to="{ name: 'PharmacyBranchesOrders', query: { page: 1 } }") 訂單管理
      .user(v-if="isCenter" ) Hi, {{ admin.account || loginInfo.account}}
      .user(v-if="isBranch" ) Hi, {{ admin.account || loginInfo.account}}
      el-button.logout(@click="logoutHandler")
        img(:src="require('@/assets/images/logout.png')")
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";
import Cookie from "js-cookie";

export default {
  props: {},
  data() {
    return {};
  },
  methods: {
    ...mapActions({}),
    logoutHandler() {
      Cookie.remove("token");
      this.$router.push({ name: "Login" });
      localStorage.removeItem("loginInfo");
    }
  },
  computed: {
    ...mapGetters(["admin"]),
    page() {
      return this.$route.query.page;
    },
    loginInfo() {
      return JSON.parse(localStorage.getItem("loginInfo"));
    },
    isAdminAndCenter() {
      // 中央藥局 role = 1, 為管理員時 is_admin = true
      return (
        (this.admin.isAdmin && this.admin.role === 1) ||
        (this.loginInfo.is_admin && this.loginInfo.role === 1)
      );
    },
    isCenter() {
      return this.admin.role === 1 || this.loginInfo.role === 1;
    },
    isBranch() {
      // 分支藥局 role = 2, 為管理員時 is_admin = true
      return this.admin.role === 2 || this.loginInfo.role === 2;
    }
  },
  components: {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
.nav-bar {
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  padding: 10px 25px;
  background: $fifth;
  min-width: 992px;
}

.logo-container {
  cursor: pointer;
  .logo-img,
  h1 {
    display: inline-block;
    vertical-align: middle;
  }
  .logo-img {
    margin-right: 5px;
    width: 30px;
    height: 30px;
    img {
      width: 100%;
    }
  }
}

.menu {
  display: flex;
  .menu-list,
  .user {
    align-self: center;
    font-size: 14px;
  }
  .user {
    padding-left: 16px;
    border-left: 1px solid $seventh;
  }
  .menu-list {
    margin-right: 23px;
    a {
      padding: 0 12.5px;
      color: $third;
      font-weight: 500;
      padding-bottom: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .logout {
    margin-left: 16px;
    background: $primary;
    border: 1px solid $primary;
  }
}

.router-link-exact-active,
.router-link-active {
  position: relative;
  color: $primary !important;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
    display: block;
    background: $primary;
    border-radius: 2.5px;
  }
}

.business-type {
  color: $primary;
  font-weight: bold;

  i {
    margin-left: 5px;
    font-weight: bold;
  }
}

.b2c {
  padding-left: 10px;
}
</style>
