import api from "@/api";

const state = {};
const getters = {};

const mutations = {};

const actions = {
  // eslint-disable-next-line no-unused-vars
  async sendOrdersHandler({ commit, dispatch }, form) {
    try {
      await api.post("/member/orders", form, {
        "Content-type": "multipart/form-data"
      });
      dispatch("successHandler", "慢箋訂單送出成功!!", { root: true });
    } catch (e) {
      console.log(e);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
