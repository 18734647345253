<template lang="pug">
  div.manage-container
    SearchBar(
      v-if="!specificContactPersonFilterchecked"
      :searchForm="searchForm"
      :isExcludeFields="['receiveMedicineType']"
      @resetSearchForm="resetSearchForm"
      @onSearchFormSubmit="onSearchFormSubmit"
    )
    .back-to-orders(v-if="specificContactPersonFilterchecked")
      p 
        span 目前列表已針對特定聯絡人做篩選
        span.back(@click="goBackPreviousOrdersList") 返回原訂單列表
    .orders-table-content
      .title-bar
        h3.page-title 訂單列表
        el-button.add(
          class="btn-manage el-button--large el-button-secondary"
          @click="exportReports"
        ) 匯出
      el-table.orders-table(
        :data="orderInfo.list" 
        style="width: 100%;"
        empty-text="目前尚無訂單資料"
      )
          el-table-column(min-width="140")
            template(slot="header")
              p.first-col-title 訂單編號
            template(slot-scope="props")
              p.orders-name {{ props.row.orderNumber }}
          el-table-column(min-width="120")
            template(slot="header")
              p 聯絡人
            template(slot-scope="props")
              p.contact-person 
                span(
                  @click="getContactPersonRecordsHandler(props.row)"
                ) {{ props.row.contactPerson }}
                span(v-if="props.row.badRecords !== 0") ({{ props.row.badRecords }})
                img.male(v-if="props.row.gender === '先生'" :src="require('@/assets/images/male.png')")
                img.female(v-if="props.row.gender === '小姐'" :src="require('@/assets/images/female.png')")
          el-table-column(min-width="100")
            template(slot="header")
              p 聯絡電話
            template(slot-scope="props")
              p {{ props.row.contactPhone }}
          el-table-column(min-width="80")
            template(slot="header")
              p 病人姓名
            template(slot-scope="props")
              p.dialog-receive-medicine-name {{ props.row.receiveMedicineName }}
          el-table-column(min-width="150")
            template(slot="header")
              p 領藥機構
            template(slot-scope="props")
              p(
                v-if="props.row.receiveMedicineType === '2' || props.row.receiveMedicineType === '3'"
              ) {{ props.row.receiveMedicineLocation }}
              p(v-else) 查看詳情
          el-table-column(min-width="140")
            template(slot="header")
              p 慢箋狀態
            template(slot-scope="props")
              p(
                :class="statusStyle(props.row.orderStatus)"
                class="status"
              ) {{ statusText(props.row.orderStatus) }}
          el-table-column(min-width="165")
            template(slot="header")
              p 訂單時間
            template(slot-scope="props")
              p {{ props.row.orderDate }}
          el-table-column(min-width="105")
            template(slot="header")
              p 開始領藥日期
            template(slot-scope="props")
              p {{ props.row.startReceiveMedicineDate }}
          el-table-column(min-width="105")
            template(slot="header")
              p 最後領藥日期
            template(slot-scope="props")
              p {{ props.row.endReceiveMedicineDate }}
          el-table-column(min-width="165")
            template(slot="header")
              p 確認領藥日期
            template(slot-scope="props")
              p {{ props.row.checkReceiveMedicineDate }}
          el-table-column(min-width="60")
            template(slot="header")
              p 備註
            template(slot-scope="props")
              p.memo(v-if="props.row.memo") 
                img(:src="require('@/assets/images/note.png')")
          el-table-column(label="" min-width="150")
            template(slot-scope="props")
              .edit-btns
                el-button.more-details(
                  @click="openUpdateOrderFormHandler(props.row, '訂單詳情')"
                )
                  img(:src="require('@/assets/images/more.png')")
                el-button.picture(@click="openPictureDrugstoreDialog(props.row)")
                  img(:src="require('@/assets/images/picture.png')")
    el-pagination(
      background
      layout="prev, pager, next"
      :total="ordersLength"
      :page-size="20"
      @current-change="handleCurrentChange"
      :current-page="orderInfo.currentPage"
    )

    OrderDialog(
      :type="type"
      :orderDialogVisible.sync="orderDialogVisible"
      :orderForm="orderForm"
      :searchForm="searchForm"
    )

    Picture(
      :pictureDialogVisible.sync="pictureDialogVisible"
      :currentSelectedPicture.sync="currentSelectedPicture"
    )
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";

// components
import DatePicker from "@/components/frontend-manage/Base/DatePicker";
import SearchBar from "@/components/frontend-manage/OrdersManagement/SearchBar.vue";
import Picture from "@/components/frontend-manage/OrdersManagement/Picture.vue";
import OrderDialog from "@/components/frontend-manage/BrancesOrdersManagement/OrderDialog.vue";
import DeleteDialog from "@/components/frontend-manage/Base/DeleteDialog";
// assets

export default {
  props: {},
  data() {
    return {
      searchForm: {
        startDate: dayjs().format("YYYY/MM/DD"),
        endDate: "",
        contactPerson: "",
        contactPhone: "",
        receiveMedicineName: "",
        // receiveMedicineType: "",
        receiveMedicineLocation: "",
        orderStatus: "",
        contactPersonId: null
      },
      previousSearchRecords: {},
      orderForm: {
        // 配合後端的格式
        id: "",
        orderNumber: "",
        contactPerson: "",
        gender: "",
        badRecords: 0,
        contactPhone: "",
        receiveMedicineName: "",
        receiveMedicineType: "",
        receiveMedicineLocation: "",
        orderStatus: "",
        orderDate: "",
        // 注意訊息與狀態的對應
        startReceiveMedicineDate: "",
        endReceiveMedicineDate: "",
        checkReceiveMedicineDate: "",
        picture: "",
        memo: "",
        reserveSuccess: "",
        startReceiveMedicine: "",
        duringReceiveMedicine: "",
        lastReceiveMedicine: "",
        outDateReceiveMedicine: ""
      },
      currentSelectedPicture: "",
      currentPage: 1,
      deleteOrderNumber: null,
      type: null,
      orderDialogVisible: false,
      pictureDialogVisible: false,
      specificContactPersonFilterchecked: false
    };
  },
  methods: {
    ...mapActions({}),
    async getOrderList() {
      await this.$store.dispatch("branches_orders/getOrderList", {
        started_at: this.searchForm.startDate
          ? this.encodeISO8061DateTime(this.searchForm.startDate, "start")
          : this.searchForm.startDate,
        ended_at: this.searchForm.endDate
          ? this.encodeISO8061DateTime(this.searchForm.endDate, "end")
          : this.searchForm.endDate,
        member_name: this.searchForm.contactPerson,
        member_phone: this.searchForm.contactPhone,
        receiver: this.searchForm.receiveMedicineName,
        method: this.searchForm.receiveMedicineType,
        location: this.searchForm.receiveMedicineLocation,
        status: this.searchForm.orderStatus,
        member_id: this.searchForm.contactPersonId,
        page: this.currentPage,
        // 預設都只能拿到自己管理下的訂單，但中央藥局如果想看別人的訂單，請帶上 not_owned: true
        not_owned: true
      });
      this.errorMsg &&
        (alert(`${this.errorMsg}`), this.$store.dispatch("errorHandler", ""));
    },
    encodeISO8061DateTime(date, type) {
      if (type === "start") {
        const time = new Date(date).getTime() + 3600 * 1000 * 8;
        const result = dayjs(time).toISOString();
        return result.split(".")[0] + "+08:00";
      } else {
        // offset 8:00:00 and 23:59:59
        const time =
          new Date(date).getTime() + 3600 * 1000 * 8 + 86400 * 1000 * 1 - 1;
        const result = dayjs(time).toISOString();
        return result.split(".")[0] + "+08:00";
      }
    },
    onSearchFormSubmit() {
      this.currentPage = 1;
      this.$router
        .push({
          path: "/manage/branches-orders",
          query: { page: 1 }
        })
        .catch(err => err);
      this.getOrderList();
    },
    resetSearchForm() {
      this.searchForm = {
        startDate: dayjs().format("YYYY/MM/DD"),
        endDate: "",
        contactPerson: "",
        contactPhone: "",
        receiveMedicineName: "",
        receiveMedicineType: "",
        receiveMedicineLocation: "",
        orderStatus: "",
        contactPersonId: null
      };
    },
    getContactPersonRecordsHandler({ contactPersonId, contactPerson }) {
      this.searchForm.page = this.$route.query.page;
      this.previousSearchRecords = JSON.parse(JSON.stringify(this.searchForm));
      delete this.searchForm.page;
      this.searchForm.contactPerson = contactPerson;
      this.searchForm.startDate = "";
      this.specificContactPersonFilterchecked = true;
      this.searchForm.contactPersonId = contactPersonId;
      this.currentPage = 1;
      this.$router
        .push({
          path: "/manage/branches-orders",
          query: { page: 1 }
        })
        .catch(err => err);
      this.getOrderList();

      window.scrollTo(0, 0);
    },
    async openUpdateOrderFormHandler({ orderNumber }, type) {
      await this.$store.dispatch(
        "branches_orders/getOrderDetails",
        orderNumber
      );
      this.orderForm = {
        ...this.orderDetails
      };
      this.type = type;
      this.orderDialogVisible = true;
    },
    async exportReports() {
      await this.$store.dispatch("branches_orders/exportReports", {
        started_at: this.searchForm.startDate
          ? this.encodeISO8061DateTime(this.searchForm.startDate, "start")
          : this.searchForm.startDate,
        ended_at: this.searchForm.endDate
          ? this.encodeISO8061DateTime(this.searchForm.endDate, "end")
          : this.searchForm.endDate,
        member_name: this.searchForm.contactPerson,
        member_phone: this.searchForm.contactPhone,
        receiver: this.searchForm.receiveMedicineName,
        method: this.searchForm.receiveMedicineType,
        location: this.searchForm.receiveMedicineLocation,
        status: this.searchForm.orderStatus,
        member_id: this.searchForm.contactPersonId,
        export: true,
        not_owned: true
      });
    },
    openPictureDrugstoreDialog({ picture }) {
      this.currentSelectedPicture = picture;
      this.pictureDialogVisible = true;
    },
    async handleCurrentChange(page) {
      this.currentPage = page;
      this.$router
        .push({
          path: "/manage/branches-orders",
          query: { page }
        })
        .catch(e => {
          e;
        });
      await this.getOrderList();
    },
    goBackPreviousOrdersList() {
      this.specificContactPersonFilterchecked = false;
      this.searchForm = this.previousSearchRecords;
      this.handleCurrentChange(+this.previousSearchRecords.page);
      this.getOrderList();
    },
    statusStyle(status) {
      // 詳細狀態參見 option.js
      const obj = {
        1: "waiting-to-review",
        2: "prepared",
        701: "failed",
        702: "failed",
        703: "failed",
        704: "failed",
        705: "failed",
        706: "failed",
        3: "allowable",
        4: "cancelable",
        5: "already-received",
        6: "not-check",
        7: "drug-withdrawal",
        8: "waiting-to-review", // hospital
        9: "failed", // hospital
        10: "allowable", // hospital
        11: "already-received", // hospital
        12: "cancelable" // hospital
      };
      return obj[status];
    },
    statusText(status) {
      const obj = {
        1: "等待審核",
        2: "備藥中",
        701: "失敗-黑名單",
        702: "失敗-照片",
        703: "失敗-備藥時間",
        704: "失敗-第二次已領",
        705: "失敗-無獲利",
        706: "失敗-其他",
        3: "可領藥",
        4: "取消",
        5: "已領藥",
        6: "未確認",
        7: "退藥",
        8: "等待審核", // hospital
        9: "登錄失敗", // hospital
        10: "登錄成功", // hospital
        11: "已通知", // hospital
        12: "取消" // hospital
      };
      return obj[status];
    },
    typeText(type) {
      const obj = {
        1: "配送到府",
        2: "藥局",
        3: "醫院"
      };
      return obj[type];
    }
  },
  computed: {
    ...mapGetters({
      orderInfo: "branches_orders/orderInfo",
      ordersLength: "branches_orders/ordersLength",
      orderDetails: "branches_orders/orderDetails"
    }),
    successMsg() {
      return this.$store.state.successMsg;
    },
    errorMsg() {
      return this.$store.state.errorMsg;
    }
  },
  components: {
    DatePicker,
    SearchBar,
    OrderDialog,
    DeleteDialog,
    Picture
  },
  async mounted() {
    this.$router
      .push({
        path: "/manage/branches-orders",
        query: { page: 1 }
      })
      .catch(err => {
        err;
      });
    await this.getOrderList();
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-table {
  .el-table__body p {
    font-size: 13px !important;
  }
}

.orders-table-content {
  .more-details,
  .check,
  .picture {
    margin-right: 10px;
  }
}

.back-to-orders {
  margin: -15px 0 10px 0;
  font-size: 20px;
  span {
    &:first-child {
      font-weight: 600;
    }
  }
  .back {
    color: $primary;
    text-decoration: underline;
    margin-left: 25px;
    cursor: pointer;
  }
}

.orders-name {
  padding-left: 15px;
}
.contact-person,
.dialog-receive-medicine-name {
  span {
    vertical-align: middle;
    margin-right: 2px;
  }
}

.contact-person {
  color: $primary;
  text-decoration: underline;
  cursor: pointer;
  .male {
    width: 12px;
    height: 12px;
  }
  .female {
    width: 9px;
    height: 12px;
  }
}

.memo {
  img {
    width: 13px;
    height: 15px;
  }
}

.status {
  border-radius: 12px;
  text-align: center;
  color: $fifth;
  display: inline-block;
  padding: 0 10px;
}
</style>
