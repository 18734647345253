import api from "@/api";

const state = {
  branchesInfo: {
    data: []
  },
  branchAccountInformation: {},
  branchAPIInformation: {}
};

const getters = {
  branchesList: state => ({
    list: state.branchesInfo.data
  }),
  branchAccountInformation: state => ({
    ...state.branchAccountInformation.data
  }),
  branchAPIInformation: state => ({
    ...state.branchAPIInformation.data
  })
};

const mutations = {
  BRANCHES_INFO(state, branchesInfo) {
    state.branchesInfo = branchesInfo;
  },
  BRANCH_ACCOUNT_INFO(state, branchAccountInformation) {
    state.branchAccountInformation = branchAccountInformation;
  },
  BRANCH_API_INFO(state, branchAPIInformation) {
    state.branchAPIInformation = branchAPIInformation;
  }
};

const actions = {
  async getBranchesList({ commit }) {
    try {
      const { data: lineBots } = await api.get("line-bots");
      commit("BRANCHES_INFO", lineBots);
    } catch (e) {
      console.log(e);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async activateAccount({ commit }, id) {
    try {
      await api.put(`line-bots/${id}/status`);
    } catch (e) {
      console.log(e);
    }
  },
  async getBranchAccountInformation({ commit }, id) {
    try {
      const { data: branchAccountInformation } = await api.get(
        `line-bots/${id}/manager`
      );
      commit("BRANCH_ACCOUNT_INFO", branchAccountInformation);
    } catch (e) {
      console.log(e);
    }
  },
  async getBranchAPIInformation({ commit }, id) {
    try {
      const { data: branchAPIInformation } = await api.get(
        `line-bots/${id}/channel`
      );
      commit("BRANCH_API_INFO", branchAPIInformation);
    } catch (e) {
      console.log(e);
    }
  },
  async updateBranchAPIInformation({ dispatch }, { id, apiInfoForm }) {
    try {
      await api.put(`line-bots/${id}/channel`, {
        ...apiInfoForm
      });
      dispatch("successHandler", "藥局 API 資訊修改成功!!", { root: true });
    } catch (e) {
      if (e.response.status === 422 && e.response.data.errors["liff_url"]) {
        dispatch("errorHandler", "LIFF URL 已經存在!!", {
          root: true
        });
      }
      if (
        e.response.status === 422 &&
        e.response.data.errors["login_channel_id"]
      ) {
        dispatch("errorHandler", "Channel ID 已經存在!!", {
          root: true
        });
      }
    }
  },
  async updateBranchAccountInformation({ dispatch }, { id, accountInfoForm }) {
    try {
      await api.put(`line-bots/${id}/manager`, {
        ...accountInfoForm
      });
      dispatch("successHandler", "藥局帳號資訊修改成功!!", { root: true });
    } catch (e) {
      console.log(e);
    }
  },
  async addBranchHandler({ dispatch }, branchForm) {
    try {
      await api.post("line-bots", {
        ...branchForm
      });
      dispatch("successHandler", "藥局資訊建立成功!!", { root: true });
    } catch (e) {
      if (e.response.status === 400) {
        dispatch("errorHandler", "帳號重複!!", {
          root: true
        });
      }
      if (e.response.status === 422) {
        dispatch("errorHandler", "LIFF URL 已經存在!!", {
          root: true
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
