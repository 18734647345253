<template lang="pug">
  div(v-if="isInClient")
    Header
    //- button(@click="$router.push({ name: 'HistoryPharmacies' })") 選擇歷史藥局
    //- button(@click="$router.push({ name: 'HistoryRecords' })") 領藥查詢
    //- button(@click="$router.push({ name: 'Register' })") 填寫聯絡人資料
    //- button(@click="$router.push({ name: 'ChoosePharmacies' })") 選擇領藥藥局
    //- button(@click="$router.push({ name: 'DeliveryService' })") 選擇配送到府
    //- button(@click="remove") 刪除 line 註冊
    //- div(style="margin-top: 100px; width: 100px;") token: {{ token }}
    router-view.frontend-container 
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";
// import Cookies from "js-cookie";
import liff from "@line/liff";
// import axios from "axios";
// components
import Header from "@/components/frontend/Base/Header.vue";

// assets

export default {
  props: {},
  data() {
    return {
      // token: Cookies.get("token")
    };
  },
  methods: {
    ...mapActions({})
    // async remove() {
    //   console.log("remove");
    //   await axios.delete("https://51g1yup.com/api/boom");
    //   liff.closeWindow();
    // }
  },
  computed: {
    ...mapGetters({}),
    isInClient() {
      return liff.isInClient();
    }
  },
  components: {
    Header
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.frontend-container {
  min-width: 320px;
  min-height: 640px;
  height: calc(100vh - 80px);
  background: $eighth;
  overflow-y: scroll;
  padding-bottom: 80px;
  @media (max-height: 667px) {
    min-height: 640px;
    height: calc(100vh - 116px);
  }
}
.frontend-container::-webkit-scrollbar {
  display: none;
}

// button {
//   position: absolute;
//   z-index: 9999;
//   top: 0;
//   &:nth-child(1) {
//     left: 0;
//   }
//   &:nth-child(2) {
//     left: 50px;
//   }
//   &:nth-child(3) {
//     left: 120px;
//   }
//   &:nth-child(4) {
//     left: 180px;
//   }
//   &:nth-child(5) {
//     left: 220px;
//   }
//   &:nth-child(6) {
//     left: 280px;
//   }
// }
</style>
