<template lang="pug">
  div
    el-dialog(
      id="print"
      class="picture-dialog"
      title="處方箋"
      :visible="pictureDialogVisible"
      width="500px"
      center
      @close="$emit('update:pictureDialogVisible', false);$emit('update:currentSelectedPicture', '');"
    )
      el-row
        el-col(:span="24")
          .picture-container(
          )
            img(:src="currentSelectedPicture")
      .dialog-footer(slot="footer")
        el-row(:gutter="20")
          el-col.custom-col-24
            el-col
              el-button(
                class="btn-manage el-button-primary el-button--large"
                @click="printPhoto"
              ) 列印處方箋
    .print-photo(v-if="pictureDialogVisible")
      img(:src="currentSelectedPicture")

</template>

<script>
export default {
  props: {
    pictureDialogVisible: {
      type: Boolean
    },
    currentSelectedPicture: {
      type: String
    }
  },
  data() {
    return {};
  },
  methods: {
    printPhoto() {
      window.print();
    }
  },
  computed: {},
  components: {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
.picture-dialog {
  .picture-container {
    width: 100%;
    height: 550px;
    background: $sixth;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
