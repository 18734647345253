<template lang="pug">
  div.login-container
    div.login-form
      ValidationObserver(
        ref="loginForm"
        v-slot="{ handleSubmit }"
      )
        el-form(
          id="login-form"
          :model="loginForm"
          @submit.native.prevent="handleSubmit(onSubmit)"
        )
          .login-form-header
            div.logo-container
              img(
                :src="require('@/assets/images/logo.png')"
                alt="logo"
              )
              h2 慢箋得來速後台
          .login-form-content
            el-form-item.login-input(
              v-for="( item, index ) in 2" :key="index"
              :class="{ 'is-active': highlightStatusList[ index ] }"
            )
              ValidationProvider(
                rules="invalidInput|ChineseInput"
                v-slot="{ errors }"
              )
                div.user-info(@click="highlightHandler(index)")
                  img(
                    v-if="index === 0"
                    :src="require('@/assets/images/account.png')"
                    alt="account"
                  )
                  img(
                    v-else
                    :src="require('@/assets/images/password.png')"
                    alt="password"
                  )
                el-input.el-input--large(
                  v-if="index === 0"
                  @focus="highlightHandler(index)"
                  @blur="removeHighlightHandler"
                  v-model="loginForm.account"
                )
                p.error-msg(v-if="index === 0") {{ errors[0] }}
                el-input.el-input--large(
                  type="password"
                  v-if="index === 1"
                  @focus="highlightHandler(index)"
                  @blur="removeHighlightHandler"
                  v-model="loginForm.password"
                )
                p.error-msg(v-if="index === 1") {{ errors[0] }}
          .login-form-footer
            el-button(
              class="el-button--large btn el-button-primary"
              native-type="submit"
              :form="'login-form'"
            ) 登入
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";

export default {
  props: {},
  data() {
    return {
      loginForm: {
        account: "",
        password: ""
      },
      highlightStatusList: [false, false]
    };
  },
  methods: {
    ...mapActions({}),
    async onSubmit() {
      await this.$store.dispatch("loginHandler", this.loginForm);
      const error = this.$store.state.errorMsg;
      const success = this.$store.state.successMsg;
      error &&
        (window.alert(`${error}`), this.$store.dispatch("errorHandler", ""));

      success &&
        (window.alert(`${success}`),
        this.$store.dispatch("successHandler", ""));

      if (this.loginInfo.role === 1) {
        this.$router.push({ name: "Orders" });
      } else {
        this.$router.push({ name: "PharmacyBranchesOrders" });
      }
    },
    highlightHandler(index) {
      this.highlightStatusList.fill(false);
      this.highlightStatusList[index] = true;
      this.highlightStatusList = [...this.highlightStatusList];
    },
    removeHighlightHandler() {
      this.highlightStatusList.fill(false);
      this.highlightStatusList = [...this.highlightStatusList];
    }
  },
  computed: {
    ...mapGetters({}),
    loginInfo() {
      return JSON.parse(localStorage.getItem("loginInfo"));
    }
  },
  components: {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
.login-container {
  position: relative;
  height: 100vh;
  background: linear-gradient(155deg, $primary 56%, $secondary 44%) center
    center / 100% 100%;
}

.login-form {
  width: 400px;
  padding: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $fifth;
  border-radius: 10px;
  &-header {
    text-align: center;
    img {
      margin-bottom: 25px;
    }
    h2 {
      margin-bottom: 50px;
      color: $third;
    }
  }
  &-content {
    .login-input {
      position: relative;
      border-radius: 5px;
      border: 1px solid transparent;
      &:first-child {
        margin-bottom: 25px;
      }
      &:last-child {
        margin-bottom: 50px;
      }
      .user-info {
        width: 50px;
        height: 44px;
        display: inline-block;
        vertical-align: middle;
        border-radius: 5px 0 0 5px;
        background: $fourth;
        text-align: center;
        img {
          line-height: 44px;
        }
      }
      ::v-deep .el-input {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 50px);
        .el-input__inner {
          border-radius: 0 5px 5px 0;
          transition: 0s;
          background: $sixth;
        }
      }
    }
  }
}

.is-active {
  border: 1px solid $fourth !important;
  ::v-deep .el-input {
    .el-input__inner {
      &:focus,
      &:active {
        border: 1px solid transparent !important;
      }
    }
  }
}
</style>
