import api from "@/api";

const mappingPharmaciesList = state => {
  return state.pharmaciesList.map(pharmacy => {
    const fullAddress =
      pharmacy.city + pharmacy.district + pharmacy.street + pharmacy.address;
    return {
      id: pharmacy.id,
      lng: +pharmacy.longitude, // 經度
      lat: +pharmacy.latitude, // 緯度
      pharmacy: pharmacy.name,
      address: pharmacy.full_address || fullAddress
    };
  });
};

const state = {
  pharmaciesList: [],
  citiesList: [],
  districtsList: [],
  streetList: [],
  deliveryQueryRecords: {},
  choosePharmaciesQueryRecord: {}
};
const getters = {
  pharmaciesList: state => mappingPharmaciesList(state),
  citiesList: state => state.citiesList,
  districtsList: state => state.districtsList,
  streetList: state => state.streetList,
  deliveryQueryRecords: state => state.deliveryQueryRecords,
  choosePharmaciesQueryRecord: state => state.choosePharmaciesQueryRecord
};

const mutations = {
  PHARMACIES_LIST(state, pharmaciesList) {
    state.pharmaciesList = pharmaciesList;
  },
  CITIES_LIST(state, citiesList) {
    state.citiesList = citiesList;
  },
  DISTRICTS_LIST(state, districtsList) {
    state.districtsList = districtsList;
  },
  STREET_LIST(state, streetList) {
    state.streetList = streetList;
  },
  QUERY_RECORD(state, deliveryQueryRecords) {
    state.deliveryQueryRecords = deliveryQueryRecords;
  },
  CHOOSE_PHARMACIESQUERY_RECORD(state, choosePharmaciesQueryRecord) {
    state.choosePharmaciesQueryRecord = choosePharmaciesQueryRecord;
  }
};

const actions = {
  async getMapSearchPharmaciesList({ commit }, searchParams) {
    try {
      const { data: pharmaciesList } = await api.get("/member/pharmacies", {
        params: searchParams
      });
      commit("PHARMACIES_LIST", pharmaciesList);
    } catch (e) {
      console.log(e);
    }
  },
  async getStreetSearchList({ commit }, { searchParams, type }) {
    try {
      const { data } = await api.get("/member/pharmacies", {
        params: searchParams
      });
      if (type === "city") {
        commit("CITIES_LIST", data);
      }
      if (type === "district") {
        commit("DISTRICTS_LIST", data);
      }
      if (type === "street") {
        commit("STREET_LIST", data);
      }
      if (type === "pharmacies") {
        commit("PHARMACIES_LIST", data.data);
      }
    } catch (e) {
      console.log(e);
    }
  },
  async getNameSearchList({ commit }, searchParams) {
    // 清除街道搜尋的資料
    commit("CITIES_LIST", []);
    commit("DISTRICTS_LIST", []);
    commit("STREET_LIST", []);

    try {
      if (!searchParams.name) {
        commit("PHARMACIES_LIST", []);
      } else {
        const { data } = await api.get("/member/pharmacies", {
          params: searchParams
        });
        commit("PHARMACIES_LIST", data.data);
      }
    } catch (e) {
      console.log(e);
    }
  },
  async getDeliveryPharmaciesList({ commit }, { searchParams, status }) {
    try {
      const { data } = await api.get("/member/delivery-area", {
        params: searchParams
      });
      if (status === "init") {
        commit("CITIES_LIST", data);
      }
      if (status === "city") {
        commit("DISTRICTS_LIST", data);
      }
      if (status === "district") {
        commit("PHARMACIES_LIST", data.data);
      }
    } catch (e) {
      console.log(e);
    }
  },
  async storeDeliveryQueryRecords({ commit }, deliveryQueryRecords) {
    commit("QUERY_RECORD", deliveryQueryRecords);
  },
  storeChoosePharmaciesQueryRecord({ commit }, choosePharmaciesQueryRecord) {
    commit("CHOOSE_PHARMACIESQUERY_RECORD", choosePharmaciesQueryRecord);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
