<template lang="pug">
  el-dialog(
    class="add-branch-dialog"
    :title="type"
    :visible="branchesDialogVisible"
    width="900px"
    center
    @close="cancel"
  )
    el-row
      el-col
        ValidationObserver(ref="branchForm" v-slot="{ handleSubmit }")
          el-form(
            id="branch-form"
            :model="branchForm"
            @submit.native.prevent="addBranchHandler"
          )
            el-row(class="dialog-account-info")
              el-form-item(
              )
                div(class="row-title title") 帳號資訊
                div(class="row-item")
                  el-col(:span="12" class="right-side-border")
                    div.field
                      span.star-key *
                      span.title(
                        :class="{ 'is-active': highlightStatusList[1] }"
                        @click="$refs.pharmacy_name.focus();highlightHandler(1);"
                      ) 藥局名稱
                      ValidationProvider.validate-element(rules="notAllowedEmpty|maximum50InputLength" v-slot="{ errors }")
                        .name
                          el-input(
                            type="text"
                            ref="pharmacy_name"
                            v-model="branchForm.pharmacy_name"
                            resize="none"
                            @focus="highlightHandler(1)"
                          )
                        p.error-msg {{ errors[0] }}
                    div.field.address
                      span.star-key *
                      span.title(
                        :class="{ 'is-active': highlightStatusList[2] }"
                        @click="$refs.pharmacy_address.focus();highlightHandler(2);"
                      ) 藥局地址
                      ValidationProvider.validate-element(rules="notAllowedEmpty|maximum50InputLength" v-slot="{ errors }")
                        .address
                          el-input(
                            type="textarea"
                            ref="pharmacy_address"
                            v-model="branchForm.pharmacy_address"
                            resize="none"
                            @focus="highlightHandler(2)"
                          )
                        p.error-msg {{ errors[0] }}
                  el-col(:span="12" class="left-side")
                    div.field
                      span.star-key *
                      span.title(
                        :class="{ 'is-active': highlightStatusList[3] }"
                        @click="$refs.account.focus();highlightHandler(3);"
                      ) 登入帳號
                      ValidationProvider.validate-element(rules="notAllowedEmpty|maximum30InputLength" v-slot="{ errors }")
                        .account
                          el-input(
                            type="text"
                            ref="account"
                            v-model="branchForm.account"
                            resize="none"
                            @focus="highlightHandler(3)"
                          )
                        p.error-msg {{ errors[0] }}
                    div.field
                      span.star-key *
                      span.title(
                        :class="{ 'is-active': highlightStatusList[4] }"
                        @click="$refs.password.focus();highlightHandler(4);"
                      ) 登入密碼
                      ValidationProvider.validate-element(rules="notAllowedEmpty|maximum20InputLength" v-slot="{ errors }")
                        .password
                          el-input(
                            type="password"
                            ref="password"
                            v-model="branchForm.password"
                            resize="none"
                            @focus="highlightHandler(4)"
                          )
                        p.error-msg {{ errors[0] }}
            el-row(class="dialog-line-login")
              el-form-item(
              )
                div(class="row-title title") LINE Login
                div(class="row-item")
                  el-col(:span="24")
                    div.field
                      span.star-key *
                      span.title(
                        :class="{ 'is-active': highlightStatusList[5] }"
                        @click="$refs.login_channel_id.focus();highlightHandler(5);"
                      ) Channel ID
                      ValidationProvider(rules="notAllowedEmpty|maximum20InputLength" v-slot="{ errors }")
                        .line-channel-id
                          el-input(
                            type="text"
                            ref="login_channel_id"
                            v-model="branchForm.login_channel_id"
                            resize="none"
                            @focus="highlightHandler(5)"
                          )
                        p.error-msg {{ errors[0] }}
                    div.field
                      span.star-key *
                      span.title(
                        :class="{ 'is-active': highlightStatusList[6] }"
                        @click="$refs.liff_url.focus();highlightHandler(6);"
                      ) LIFF URL
                      ValidationProvider(rules="notAllowedEmpty|maximum50InputLength" v-slot="{ errors }")
                        .line-liff-url
                          el-input(
                            type="text"
                            ref="liff_url"
                            v-model="branchForm.liff_url"
                            resize="none"
                            @focus="highlightHandler(6)"
                          )
                        p.error-msg {{ errors[0] }}
            el-row(class="dialog-line-messaging-api")
              el-form-item(
              )
                div(class="row-title title") Messaging API
                div(class="row-item")
                  el-col(:span="24")
                    div.field
                      span.star-key *
                      span.title(
                        :class="{ 'is-active': highlightStatusList[7] }"
                        @click="$refs.channel_secret.focus();highlightHandler(7);"
                      ) Channel secret
                      ValidationProvider(rules="notAllowedEmpty|maximum50InputLength" v-slot="{ errors }")
                        .line-channel-secret
                          el-input(
                            type="text"
                            ref="channel_secret"
                            v-model="branchForm.channel_secret"
                            resize="none"
                            @focus="highlightHandler(7)"
                          )
                        p.error-msg {{ errors[0] }}
                    div.field
                      span.star-key *
                      span.title(
                        :class="{ 'is-active': highlightStatusList[8] }"
                        @click="$refs.channel_access_token.focus();highlightHandler(8);"
                      ) Channel access token
                      ValidationProvider(rules="notAllowedEmpty|maximum200InputLength" v-slot="{ errors }")
                        .line-channel-access-token
                          el-input(
                            type="textarea"
                            ref="channel_access_token"
                            v-model="branchForm.channel_access_token"
                            resize="none"
                            @focus="highlightHandler(8)"
                          )
                        p.error-msg {{ errors[0] }}
    .dialog-footer(slot="footer")
      el-row(:gutter="20")
        el-col.custom-col-24()
          el-col(:span="12")
            el-button(
              class="btn-manage el-button-primary-plain"
              @click="cancel"
            ) 取消
          el-col(:span="12")
            el-button(
              class="btn-manage el-button-primary"
              :form="'branch-form'"
              native-type="submit"
            ) 確定送出

</template>

<script>
// plugin

// components

// config

export default {
  props: {
    branchForm: {
      type: Object
    },
    branchesDialogVisible: {
      type: Boolean
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      reserveFailDialogVisible: false,
      confirmDialogVisible: false,
      doubleChecked: false,
      highlightStatusList: [false, false, false, false, false]
    };
  },
  methods: {
    async addBranchHandler() {
      const isValid = await this.$refs.branchForm.validate();

      if (isValid) {
        this.$emit("addBranchHandler");
      }
    },
    highlightHandler(index) {
      this.highlightStatusList.fill(false);
      this.highlightStatusList[index] = true;
      this.highlightStatusList = [...this.highlightStatusList];
    },
    cancel() {
      this.$emit("update:branchesDialogVisible", false);
      this.$emit("resetBranchForm");
      this.$refs.branchForm.reset();
      this.highlightStatusList.fill(false);
    }
  },
  computed: {
    successMsg() {
      return this.$store.state.successMsg;
    },
    errorMsg() {
      return this.$store.state.errorMsg;
    }
  },
  components: {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
#branch-form {
  ::v-deep .el-form-item__content {
    line-height: 1.5;
  }

  .title {
    font-weight: 600;
    margin-right: 16px;
  }

  .right-side-border {
    border-right: 1px solid #b4b4b5;
  }

  .row-title {
    width: 100%;
    margin-bottom: 5px;
    text-align: left;
    border-left: 5px solid #4a4a4a;
    padding-left: 10px;
    line-height: 1.5;
  }

  .row-item {
    border-radius: 8px;
    border: 1px solid #b4b4b5;
    padding: 16px 25px;
    &::after {
      content: "";
      display: block;
      clear: both;
    }
  }

  .dialog-line-messaging-api {
    .el-form-item:last-child {
      margin-bottom: 0;
    }
    .field {
      margin-bottom: 20px;
    }
  }

  .dialog-line-login {
    .field {
      margin-bottom: 20px;
    }
  }

  .dialog-account-info {
    .field {
      margin-bottom: 20px;
    }
    .validate-element {
      display: inline-block;
      width: 75%;
    }
    .field.address {
      .star-key,
      .title {
        vertical-align: top;
      }
    }

    .left-side {
      padding-left: 20px;
      .validate-element {
        width: 79%;
      }
    }
  }
}

.add-branch-dialog {
  ::v-deep .el-dialog {
    margin-top: 15vh !important;
  }
}

.is-active {
  color: $primary;
}
</style>
