<template lang="pug">
  div.manage-container
    .branches-table-content
      .title-bar
        h3.page-title 藥局列表
        el-button.add(
          class="btn-manage el-button--large el-button-secondary"
          @click="addBranch"
        ) 建立
      el-table.branches-table(
        :data="branchesList.list"
        style="width: 100%;"
        empty-text=""
      )
        el-table-column(width="256")
          template(slot="header")
            p.first-col-title 藥局名稱
          template(slot-scope="props")
            p.pharmacy-name {{ props.row.pharmacy_name }}
        el-table-column(
          :width="index === 0 ? '300' : '200'"
          v-for="({ name, key }, index) in tableParams"
          :key="index"
        )
          template(slot="header")
            p {{ name }}
          template(slot-scope="props"  )
            p(v-if="key !== 'status'" ) {{ props.row[key] }}
            p.status-badge(
              v-else
              :style="{'background-color': props.row[key] === true ? '#84bdff': '#ff8b93'}"
              ) {{ mappingBadgeStatus(props.row[key]) }}
        el-table-column(label="")
          template(slot-scope="props")
            .edit-btns
              el-button.account(@click="openAccountInfoDialog(props.row)") 帳號
              el-button.api(@click="openAPIInfoDialog(props.row)") API
              el-button.activate(v-if="props.row['status'] === false" @click="activateAccount(props.row)") 啟用
              el-button.deactivate(v-if="props.row['status'] === true" @click="deactivateAccount(props.row)") 停用
    ActivateDialog(
      :activateDialogVisible.sync="activateDialogVisible"
      @activateHandler="activateAccountHandler"
      :msg="'是否確定要啟用此帳號？'"
    )
    DeActivateDialog(
      :deactivateDialogVisible.sync="deactivateDialogVisible"
      @deactivateHandler="deactivateAccountHandler"
      :msg="'是否確定要停用此帳號？'"
    )
    AddBranchDialog(
      type="建立分支"
      :branchesDialogVisible.sync="branchesDialogVisible"
      :branchForm="branchForm"
      @addBranchHandler="addBranchHandler"
      @resetBranchForm="resetBranchForm"
    )
    AccountInfoDialog(
      :accountInfoDialogVisible.sync="accountInfoDialogVisible"
      :accountInfoForm="accountInfoForm"
      @updateAccountInfoHandler="updateAccountInfoHandler"
    )
    APIInfoDialog(
      :apiInfoDialogVisible.sync="apiInfoDialogVisible"
      :apiInfoForm="apiInfoForm"
      @updateAPIInfoHandler="updateAPIInfoHandler"
    )
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";

// components
import ActivateDialog from "@/components/frontend-manage/Base/ActivateDialog";
import DeActivateDialog from "@/components/frontend-manage/Base/DeActivateDialog";
import AddBranchDialog from "@/components/frontend-manage/BranchesManagement/AddBranchDialog.vue";
import AccountInfoDialog from "@/components/frontend-manage/BranchesManagement/AccountInfoDialog.vue";
import APIInfoDialog from "@/components/frontend-manage/BranchesManagement/APIInfoDialog.vue";
// assets

export default {
  props: {},
  data() {
    return {
      tableParams: [
        {
          name: "藥局地址",
          key: "pharmacy_address"
        },
        {
          name: "登入帳號",
          key: "account"
        },
        {
          name: "開通狀態",
          key: "status"
        }
      ],
      activateDialogVisible: false,
      deactivateDialogVisible: false,
      rowId: null,
      branchesDialogVisible: false,
      branchForm: {
        pharmacy_name: "",
        pharmacy_address: "",
        account: "",
        password: "",
        login_channel_id: "",
        liff_url: "",
        channel_secret: "",
        channel_access_token: ""
      },
      accountInfoDialogVisible: false,
      accountInfoForm: {
        pharmacy_name: "",
        pharmacy_address: "",
        account: "",
        password: ""
      },
      apiInfoDialogVisible: false,
      apiInfoForm: {
        login_channel_id: "",
        liff_url: "",
        channel_secret: "",
        channel_access_token: "",
        api_token: ""
      }
    };
  },
  methods: {
    ...mapActions({}),
    async getBranchesList() {
      await this.$store.dispatch("branches/getBranchesList");
    },
    addBranch() {
      this.branchesDialogVisible = true;
      console.log("add branch");
    },
    mappingBadgeStatus(status) {
      return status === true ? "啟用中" : "停用中";
    },
    activateAccount(row) {
      console.log(row);
      this.activateDialogVisible = true;
      this.rowId = row.id;
    },
    async activateAccountHandler() {
      await this.$store.dispatch("branches/activateAccount", this.rowId);
      console.log("activateAccountHandler", this.rowId);
      this.activateDialogVisible = false;
      await this.$store.dispatch("branches/getBranchesList");
    },
    deactivateAccount(row) {
      console.log(row);
      this.deactivateDialogVisible = true;
      this.rowId = row.id;
    },
    async deactivateAccountHandler() {
      await this.$store.dispatch("branches/activateAccount", this.rowId);
      console.log("deactivateAccountHandler", this.rowId);
      this.deactivateDialogVisible = false;
      await this.$store.dispatch("branches/getBranchesList");
    },
    async addBranchHandler() {
      await this.$store.dispatch("branches/addBranchHandler", this.branchForm);

      this.successMsg &&
        (alert(`${this.successMsg}`),
        this.$store.dispatch("successHandler", ""));

      if (this.errorMsg) {
        alert(`${this.errorMsg}`), this.$store.dispatch("errorHandler", "");
        return;
      }

      await this.$store.dispatch("branches/getBranchesList");

      this.branchesDialogVisible = false;
    },
    resetBranchForm() {
      this.branchForm = {
        pharmacy_name: "",
        pharmacy_address: "",
        account: "",
        password: "",
        login_channel_id: "",
        liff_url: "",
        channel_secret: "",
        channel_access_token: ""
      };
    },
    async openAccountInfoDialog(row) {
      await this.$store.dispatch(
        "branches/getBranchAccountInformation",
        row.id
      );
      this.accountInfoForm = { ...this.branchAccountInformation };
      this.accountInfoDialogVisible = true;
    },
    async updateAccountInfoHandler() {
      const accountInfoForm = {
        pharmacy_name: this.accountInfoForm.pharmacy_name,
        pharmacy_address: this.accountInfoForm.pharmacy_address
      };

      if (this.accountInfoForm.password) {
        accountInfoForm.password = this.accountInfoForm.password;
      }

      await this.$store.dispatch("branches/updateBranchAccountInformation", {
        id: this.accountInfoForm.id,
        accountInfoForm: accountInfoForm
      });

      this.successMsg &&
        (alert(`${this.successMsg}`),
        this.$store.dispatch("successHandler", ""));

      await this.$store.dispatch("branches/getBranchesList");

      this.accountInfoDialogVisible = false;
    },
    async updateAPIInfoHandler() {
      await this.$store.dispatch("branches/updateBranchAPIInformation", {
        id: this.apiInfoForm.id,
        apiInfoForm: {
          login_channel_id: this.apiInfoForm.login_channel_id,
          liff_url: this.apiInfoForm.liff_url,
          channel_secret: this.apiInfoForm.channel_secret,
          channel_access_token: this.apiInfoForm.channel_access_token
        }
      });

      this.successMsg &&
        (alert(`${this.successMsg}`),
        this.$store.dispatch("successHandler", ""));

      if (this.errorMsg) {
        alert(`${this.errorMsg}`), this.$store.dispatch("errorHandler", "");
        return;
      }

      await this.$store.dispatch("branches/getBranchesList");

      this.apiInfoDialogVisible = false;
    },
    async openAPIInfoDialog(row) {
      await this.$store.dispatch("branches/getBranchAPIInformation", row.id);
      this.apiInfoForm = { ...this.branchAPIInformation };
      this.apiInfoDialogVisible = true;
    }
  },
  computed: {
    ...mapGetters({
      branchesList: "branches/branchesList",
      branchAccountInformation: "branches/branchAccountInformation",
      branchAPIInformation: "branches/branchAPIInformation"
    }),
    successMsg() {
      return this.$store.state.successMsg;
    },
    errorMsg() {
      return this.$store.state.errorMsg;
    }
  },
  components: {
    ActivateDialog,
    DeActivateDialog,
    AddBranchDialog,
    AccountInfoDialog,
    APIInfoDialog
  },
  async mounted() {
    await this.getBranchesList();
  }
};
</script>

<style lang="scss" scoped>
#pharmacy-form {
  ::v-deep .el-select {
    .el-input__suffix {
      top: 28px;
    }
  }
}

.pharmacy-name {
  padding-left: 15px;
}

.branches-table-content {
  .check {
    margin-right: 10px;
  }
}

.edit-btns {
  .el-button {
    width: 50px;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    padding: 0 11px;
    border-radius: 5px;
    color: #fff;
    margin-left: 10px;
    border: none;
  }

  .account {
    background-color: #f7b52c;
  }
  .api {
    background-color: #80aa32;
  }
  .activate {
    background-color: #4a90e2;
  }
  .deactivate {
    background-color: #ea6d6a;
  }
}

.status-badge {
  padding: 0 10px;
  border-radius: 12px;
  width: 59px;
  height: 24px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
}
</style>
