<template lang="pug">
  div.manage-container 
    .add-drugstores
      .title-bar
        h3.page-title 新增藥局
        el-upload(
          :action="fetchUploadURL"
          :headers="{ 'Authorization': 'Bearer ' + token }"
          :show-file-list="false"
          :on-error="uploadErrorHandler"
          :on-success="uploadSuccessHandler"
          accept=".csv"
        )
          el-button.add(
            class="btn-manage el-button--large el-button-secondary import"
          ) 批量匯入
      .content
        ValidationObserver(ref="totalPharmaciesList" v-slot="{ handleSubmit }")
          el-form(
            id="add-pharmacy-form"
            @submit.native.prevent="handleSubmit(onSubmit)"
          ) 
            el-row(
              :gutter="25"
              v-for="(pharmacy, index) in totalPharmaciesList.list" 
              :key="index"
            )
              el-col(:span="6")
                el-form-item(
                  ref="elFormItem1"
                  @click.native="$refs.pharmacyNameList[index].focus(); highlightHandler(index, 'elFormItem1');"
                )
                  div(slot="label")
                    span.star-key *
                    span 藥局名稱
                  ValidationProvider(rules="" v-slot="{ errors }")
                    el-input(
                      class="el-input-medium"
                      type="text"
                      ref="pharmacyNameList"
                      v-model.native.lazy="pharmacy.pharmacyName"
                      resize="none"
                    )
              el-col(:span="3") 
                el-form-item(
                  ref="elFormItem2"
                  @click.native="$refs.pharmacyCityList[index].toggleMenu(); highlightHandler(index, 'elFormItem2')"
                )
                  div(slot="label")
                    span.star-key *
                    span 縣市
                  ValidationProvider(rules="" v-slot="{ errors }")
                    el-select(
                      ref="pharmacyCityList"
                      placeholder="請選擇"
                      no-data-text="請先選擇縣市"
                      size="medium"
                      :value="pharmacy.city"
                      @change="selectCityHandler($event, pharmacy, index);"
                    )
                      el-option(
                        v-for="item in totalPharmaciesList.citiesAndDistricts" 
                        :key="item.id"
                        :label="item.city"
                        :value="item.city"
                      )
              el-col(:span="3") 
                el-form-item(
                  ref="elFormItem3"
                  @click.native="$refs.administrativeDistrictsList[index].toggleMenu(); highlightHandler(index, 'elFormItem3')"
                )
                  div(slot="label")
                    span.star-key *
                    span 行政區
                  ValidationProvider(rules="" v-slot="{ errors }")
                    el-select(
                      ref="administrativeDistrictsList"
                      placeholder="請選擇"
                      no-data-text="請先選擇縣市"
                      size="medium"
                      :value="pharmacy.administrativeDistrict"
                      @change="selectDistrictHandler($event, pharmacy, index);"
                    )
                      el-option(
                        v-for="item in filterAdministrativeDistricts(pharmacy.city)" 
                        :key="item.districtName"
                        :label="item.districtName"
                        :value="item.districtName"
                      )
              el-col(:span="5") 
                el-form-item(
                  ref="elFormItem4"
                  @click.native="$refs.pharmacyStreetList[index].focus(); highlightHandler(index, 'elFormItem4')"
                )
                  div(slot="label")
                    span.star-key *
                    span 街道
                  ValidationProvider(rules="" v-slot="{ errors }")
                    el-input(
                      class="el-input-medium"
                      type="text"
                      ref="pharmacyStreetList"
                      v-model.native.lazy="pharmacy.street"
                      resize="none"
                    )
              el-col(:span="5") 
                el-form-item(
                  ref="elFormItem5"
                  @click.native="$refs.pharmacyAddressList[index].focus(); highlightHandler(index, 'elFormItem5')"
                )
                  div(slot="label")
                    span.star-key *
                    span 地址
                  ValidationProvider(rules="" v-slot="{ errors }")
                    el-input(
                      class="el-input-medium"
                      type="text"
                      ref="pharmacyAddressList"
                      v-model.native.lazy="pharmacy.address"
                      resize="none"
                    )
              el-col(:span="2") 
                el-form-item.last-col
                  .empty
                  el-button.delete(@click="openDeleteDrugstoreDialog(index)")
                    img(:src="require('@/assets/images/delete.png')")
        .add-pharmacy-btn(@click="addPharmaciesHandler") 新增一筆
      el-row.footer(:gutter="20")
        el-col(:span="12")
          el-button(
            class="btn-manage el-button-primary-plain el-button--large"
            @click="$router.push({ path: '/manage/pharmacies', query: { page: 1 }})"
          ) 取消
        el-col(:span="12")
          el-button(
            class="btn-manage el-button-primary el-button--large"
            :form="'add-pharmacy-form'"
            native-type="submit"
          ) 新增藥局
    el-dialog(
      class="delete-dialog"
      v-if="deleteDialogVisible"
      title="刪除"
      :visible.sync="deleteDialogVisible"
      width="450px"
      center
      @close="closeDeletepharmacyDialog"
    )
      el-row
        el-col(:span="24")
          span.dialog-message 是否確定要刪除此藥局?
      .dialog-footer(slot="footer")
        el-row(:gutter="20")
          el-col.custom-col-24
            el-col(:span="12")
              el-button(
                class="btn-manage el-button-danger-plain el-button--large"
                @click="closeDeletepharmacyDialog"
              ) 取消
            el-col(:span="12")
              el-button(
                class="btn-manage el-button-danger el-button--large"
                @click="deletePharmacyHandler"
              ) 確定
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";
import Cookies from "js-cookie";
// components

// assets

export default {
  props: {},
  data() {
    return {
      deleteIndex: "",
      addPharmaciesListIndex: "",
      importPharmaciesListIndex: "",
      deleteDialogVisible: false,
      highlightStatusList: [
        "elFormItem1",
        "elFormItem2",
        "elFormItem3",
        "elFormItem4",
        "elFormItem5"
      ],
      totalPharmaciesList: {
        list: [],
        citiesAndDistricts: []
      },
      fail: []
    };
  },
  methods: {
    ...mapActions({}),
    async getPharmaciesList() {
      await this.$store.dispatch("pharmacies/getPharmaciesList", {
        page: "",
        searchForm: {}
      });
    },
    async onSubmit() {
      if (!this.totalPharmaciesList.list.length) {
        this.$store.dispatch("errorHandler", "目前尚未新增藥局資料!!");
        return (
          this.errorMsg &&
          (window.alert(`${this.errorMsg}`),
          this.$store.dispatch("errorHandler", ""))
        );
      }
      const addPharmaciesList = this.filterAndMappingPharmaciesList();
      await this.$store.dispatch("pharmacies/addPharmacies", addPharmaciesList);
      this.highlightErrorsCheckHandler();

      this.successMsg &&
        (window.alert(`${this.successMsg}`),
        this.$store.dispatch("successHandler", ""),
        this.$router.push({ name: "Pharmacies", query: { page: 1 } }));

      this.errorMsg &&
        (window.alert(`${this.errorMsg}`),
        this.$store.dispatch("errorHandler", ""));
    },
    filterAndMappingPharmaciesList() {
      return this.totalPharmaciesList.list.map(pharmacy => {
        return {
          city: pharmacy.city,
          street: pharmacy.street,
          address: pharmacy.address,
          name: pharmacy.pharmacyName,
          district: pharmacy.administrativeDistrict
        };
      });
    },
    selectCityHandler($event, pharmacy, index) {
      this.totalPharmaciesList.list[index] = {
        ...pharmacy,
        city: $event,
        administrativeDistrict: ""
      };
    },
    selectDistrictHandler($event, pharmacy, index) {
      this.totalPharmaciesList.list[index] = {
        ...pharmacy,
        administrativeDistrict: $event
      };
    },
    filterAdministrativeDistricts(city) {
      const match = this.totalPharmaciesList.citiesAndDistricts.find(
        data => data.city === city
      );

      return match?.administrativeDistricts;
    },
    addPharmaciesHandler() {
      this.totalPharmaciesList.list.push({
        pharmacyName: "",
        city: "",
        administrativeDistrict: "",
        street: "",
        address: "",
        isEdit: true
      });
    },
    openDeleteDrugstoreDialog(index) {
      this.deleteDialogVisible = true;
      this.deleteIndex = index;
    },
    closeDeletepharmacyDialog() {
      this.deleteIndex = "";
      this.deleteDialogVisible = false;
    },
    async deletePharmacyHandler() {
      this.totalPharmaciesList.list.splice(this.deleteIndex, 1);
      this.highlightErrorsCheckHandler();
      this.totalPharmaciesList = { ...this.totalPharmaciesList };
      this.deleteDialogVisible = false;
    },
    highlightHandler(index, ref) {
      this.highlightStatusList.forEach(list => {
        this.$refs[list].forEach(item => {
          item.$el.classList.remove("is-active");
        });
      });
      this.$refs[ref][index].$el.classList.add("is-active");
    },
    highlightErrorsCheckHandler() {
      // 用於判斷第二次之後 highlight 錯誤資料
      const tempory = JSON.parse(JSON.stringify(this.failedAddPharmacies));
      this.fail = [...tempory];

      // highlight 錯誤資料
      if (this.failedAddPharmacies.length) {
        const errors = this.checkErrorIndexHandler();

        // 移除所有 active / error highligh 的狀態
        this.totalPharmaciesList.list.forEach((item, index) => {
          this.highlightStatusList.forEach(list => {
            this.$refs[list][index].$el.classList.remove("is-error");
            this.$refs[list][index].$el.classList.remove("is-active");
          });
        });

        //  需要取得錯誤的資料和它的 index
        errors.forEach(error => {
          this.highlightStatusList.forEach(list => {
            this.$refs[list][error].$el.classList.remove("is-active");
            this.$refs[list][error].$el.classList.add("is-error");
          });
        });
      }
    },
    checkErrorIndexHandler() {
      return this.totalPharmaciesList.list
        .slice()
        .reverse()
        .map((pharmacy, index) => {
          let matchIndex = this.failedAddPharmacies.findIndex(
            failedPharmacy => {
              return (
                (pharmacy.pharmacyName === failedPharmacy.name ||
                  (!pharmacy.pharmacyName && !failedPharmacy.name)) &&
                (pharmacy.city === failedPharmacy.city ||
                  (!pharmacy.city && !failedPharmacy.city)) &&
                (pharmacy.street === failedPharmacy.street ||
                  (!pharmacy.street && !failedPharmacy.street)) &&
                (pharmacy.address === failedPharmacy.address ||
                  (!pharmacy.address && !failedPharmacy.address))
              );
            }
          );
          if (matchIndex > -1) {
            this.failedAddPharmacies.splice(matchIndex, 1);
            const index1 = this.totalPharmaciesList.list.length - 1 - index;
            return index1;
          }
        })
        .filter(num => num || num === 0);
    },
    uploadErrorHandler() {
      window.alert("匯入 csv 檔案失敗，請重新匯入!!");
    },
    uploadSuccessHandler(response) {
      const importPharmaciesList = response.map(pharmacy => {
        return {
          pharmacyName: pharmacy.name,
          city: pharmacy.city,
          administrativeDistrict: pharmacy.district,
          street: pharmacy.street,
          address: pharmacy.address
        };
      });
      importPharmaciesList.forEach(item => {
        this.totalPharmaciesList.list.push(item);
      });

      window.alert("匯入 csv 檔案成功!!");
    }
  },
  computed: {
    ...mapGetters({
      pharmaciesList: "pharmacies/pharmaciesList"
    }),
    successMsg() {
      return this.$store.state.successMsg;
    },
    errorMsg() {
      return this.$store.state.errorMsg;
    },
    failedAddPharmacies() {
      return [
        ...this.fail,
        ...this.$store.state.pharmacies.failedAddPharmacies
      ];
    },
    token() {
      return Cookies.get("token");
    },
    fetchUploadURL() {
      return process.env.VUE_APP_BASE_URL + "/pharmacies/import";
    }
  },

  components: {},
  async mounted() {
    await this.getPharmaciesList();
    this.totalPharmaciesList = {
      citiesAndDistricts: this.pharmaciesList.citiesAndDistricts,
      list: []
    };
  }
};
</script>

<style lang="scss" scoped>
.import {
  letter-spacing: 0;
}

.empty {
  margin-top: 20px;
}

.delete {
  width: 36px;
  height: 36px;
}

.content {
  width: 100%;
  background: $fifth;
  padding: 25px;
  border-radius: 5px;
}

.last-col {
  text-align: right;
}

.add-pharmacy-btn {
  padding: 9px 0;
  margin-top: 19px;
  border: 3px dashed $primary;
  font-size: 13px;
  text-align: center;
  border-radius: 3px;
  color: $primary;
  background: $sixth;
  cursor: pointer;
}

.footer {
  margin-top: 35px;
}

#add-pharmacy-form {
  ::v-deep .el-select {
    .el-input__suffix {
      top: 26px;
    }
  }
}

.is-error {
  .star-key {
    color: $ninth;
  }
  ::v-deep .el-form-item__label {
    text-align: left;
    color: $ninth !important;
  }
  ::v-deep .el-input__inner {
    border: 1px solid $ninth !important;
  }
  ::v-deep .el-select .el-input .el-select__caret {
    color: $ninth !important;
  }
}
</style>
