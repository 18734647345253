import Vue from "vue";
import Vuex from "vuex";
import root from "@/store/root";
import permission from "@/store/permission";
import districts from "@/store/districts";
import contact from "@/store/contact";
import pharmacies from "@/store/pharmacies";
import orders from "@/store/orders";
import hospitals from "@/store/hospitals";
import branches from "@/store/branches";
import branches_orders from "@/store/branches_orders";
import frontend_register from "@/store/frontend_register";
import frontend_history_records from "@/store/frontend_history_records";
import frontend_history_pharmacies from "@/store/frontend_history_pharmacies";
import frontend_choose_pharmacies from "@/store/frontend_choose_pharmacies";
import frontend_choose_hospitals from "@/store/frontend_choose_hospitals";
import frontend_photo from "@/store/frontend_photo";

Vue.use(Vuex);

export default new Vuex.Store({
  ...root,
  modules: {
    permission,
    districts,
    contact,
    pharmacies,
    orders,
    hospitals,
    branches,
    branches_orders,
    frontend_register,
    frontend_history_records,
    frontend_history_pharmacies,
    frontend_choose_pharmacies,
    frontend_photo,
    frontend_choose_hospitals
  }
});
