<template lang="pug">
  .confirm-pharmacy
    .map
      GmapMap(
        :center="checkCenter(type)"
        :zoom="checkZoom(type)" 
        map-type-id="terrain"
        style="width: 100%; height: 100%"
        :options="mapOptions"
      )
        GmapMarker(
          :key="index"
          v-for="(m, index) in checkMarkers"
          :position="m"
          :clickable="true"
          @click="type === 'choose' && $emit('update:selected', m)"
        )
    p.confirm-message(v-if="(type === 'choose' || type === 'history') && selected.pharmacy") 是否選擇此藥局領藥
    .pharmacy(v-if="(type === 'choose' || type === 'history') && selected.pharmacy")
      .name {{ selected.pharmacy }}
      .address {{ selected.address }}
      el-button(
        class="el-button-primary el-button--small confirm"
        @click="confirmdHandler(selected)"
      ) 確定
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";

// components

// views

// assets

export default {
  props: {
    selected: {
      type: Object
    },
    markers: {
      type: Array
    },
    type: {
      type: String
    },
    searchType: {
      type: String
    },
    userCurrentPosition: {
      type: Object
    }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({}),
    confirmdHandler() {
      const sendInfo = {
        method: this.type === "choose" || this.type === "history" ? 2 : 1, // 1: 配送到府 ; 2: 藥局配送
        prescription: {},
        pick_up_location:
          this.type === "choose" || this.type === "history"
            ? {
                id: this.selected.id
              }
            : {
                address: this.selected.address
              }
      };
      this.$store.dispatch("sendInfoHandler", sendInfo);
      this.$emit("storeChoosePharmaciesQueryRecord");
      this.$router.push({ name: "PrescriptionPhoto" });
    },
    checkCenter(type) {
      if (
        type === "history" ||
        (type === "choose" && this.searchType !== "地圖搜尋")
      ) {
        return {
          lat: this.selected.lat,
          lng: this.selected.lng
        };
      } else if (type === "choose" && this.searchType === "地圖搜尋") {
        return this.userCurrentPosition;
      }
    },
    checkZoom(type) {
      if (
        type === "history" ||
        (type === "choose" && this.searchType !== "地圖搜尋")
      ) {
        return 17;
      } else if (type === "choose" && this.searchType === "地圖搜尋") {
        return 15;
      }
    }
  },
  computed: {
    ...mapGetters({}),
    mapOptions() {
      return {
        gestureHandling: this.searchType !== "地圖搜尋" ? "none" : null,
        disableDefaultUI: true
      };
    },
    checkMarkers() {
      if (
        (this.type === "choose" && this.searchType === "街道搜尋") ||
        this.type === "history"
      ) {
        return [this.selected];
      } else if (
        (this.type === "choose" && this.searchType === "名稱搜尋") ||
        this.type === "history"
      ) {
        return [this.selected];
      }
      if (this.type === "choose" && this.searchType === "地圖搜尋") {
        return this.markers;
      }
      return [];
    }
  },
  components: {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
.confirm-pharmacy {
  .map {
    height: 350px;
    margin-bottom: 16px;
    @media (max-height: 667px) {
      height: 300px;
    }
  }
  .pharmacy {
    position: relative;
    border-radius: 5px;
    color: $third;
    background: $fifth;
    padding: 16px;
    .name {
      font-weight: 600;
    }
    .address {
      font-size: 14px;
      width: calc(100% - 65px);
    }
    .confirm {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translate(0, -50%);
      font-weight: 600;
    }
  }
  .confirm-message {
    margin-bottom: 16px;
    color: $ninth;
    font-weight: 600;
  }
}
</style>
