<template lang="pug">
  div.manage-container 
    ValidationObserver(ref="searchForm" v-slot="{ handleSubmit }")
      el-form(
        id="search-form"
        :model="searchForm"
        @submit.native.prevent="handleSubmit(onSearchFormSubmit)"
      )
        el-row.search-bar(:gutter="25")
          el-col(:span="5") 
            el-form-item(
              label="關鍵字搜尋"
              :class="{ 'is-active': highlightSearchFormItemsStatusList[0] }"
              @click.native="$refs.keyword.focus(); highlightSearchFormItemsHandler(0)"
            )
              ValidationProvider(rules="invalidInput" v-slot="{ errors }")
                el-input(
                  class="search-input el-input-medium"
                  type="text"
                  ref="keyword"
                  resize="none"
                  @focus="highlightSearchFormItemsHandler(0)"
                  v-model="searchForm.keyword"
                )
                p.error-msg {{ errors[0] }}
          el-col(:span="5") 
            el-form-item
              div(slot="label")
                span.empty
              el-button(
                class="btn-manage el-button-primary el-button--medium search-btn"
                :form="'search-form'"
                native-type="submit"
              ) 搜尋
              a(
                href="#"
                class="clear-all"
                @click.prevent="resetSearchForm"
              ) 清除篩選
    .pharmacy-table-content
      .title-bar
        h3.page-title 藥局列表
        el-button.add(
          class="btn-manage el-button--large el-button-secondary"
          @click="$router.push({ name: 'AddPharmacies' })"
        ) 新增
      el-table.pharmacy-table(
        :data="pharmaciesList.list" 
        style="width: 100%;"
        empty-text="沒有符合搜尋的藥局資料"
        )
          el-table-column(width="256")
            template(slot="header")
              p.first-col-title 藥局名稱
            template(slot-scope="props")
              p.pharmacy-name {{ props.row.pharmacyName }}
          el-table-column(
            :width="index === 3 ? '400' : '139'" 
            v-for="({ name, key }, index) in tableParams" 
            :key="index"
          )
            template(slot="header")
              p {{ name }}
            template(slot-scope="props")
              p {{ props.row[key]}}
          el-table-column(label="")
            template(slot-scope="props")
              .edit-btns
                el-button.check(@click="openUpdatePharmacyFormHandler(props.row)")
                  img(:src="require('@/assets/images/check.png')")
                el-button.delete(@click="openDeletePharmacyDialog(props.row)")
                  img(:src="require('@/assets/images/delete.png')")
    el-pagination(
      background
      layout="prev, pager, next"
      :total="pharmaciesLength"
      :page-size="50"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
    )

    DeleteDialog(
      :deleteDialogVisible.sync="deleteDialogVisible"
      :deleteId.sync="deleteId"
      @deleteHandler="deletePharmacyHandler"
      :msg="'藥局'"
    )
    el-dialog(
      v-if="pharmacyDialogVisible"
      title="修改藥局"
      :visible.sync="pharmacyDialogVisible"
      width="450px"
      center
      @close="resetPharmacyForm"
    )
      el-row
        el-col
          ValidationObserver(ref="pharmacyForm" v-slot="{ handleSubmit }")
            el-form(
              id="pharmacy-form"
              :model="pharmacyForm"
              @submit.native.prevent="handleSubmit(onDiaglogPharmacyFormSubmit)"
            )
              el-form-item(
                :class="{ 'is-active': highlightDialogFormItemsStatusList[0] }"
                @click.native="$refs.pharmacyName.focus(); highlightDialogFormItemsHandler(0)"
              )
                div(slot="label" class="pharmacy")
                  span.star-key *
                  span 藥局名稱
                ValidationProvider(rules="invalidInput" v-slot="{ errors }")
                  el-input(
                    class="el-input-medium"
                    type="text"
                    ref="pharmacyName"
                    v-model="pharmacyForm.pharmacyName"
                    resize="none"
                    @focus="highlightDialogFormItemsHandler(0)"
                  )
                  p.error-msg {{ errors[0] }}
              el-form-item(
                :class="{ 'is-active': highlightDialogFormItemsStatusList[1] }"
                @click.native="$refs.city.toggleMenu(); highlightDialogFormItemsHandler(1)"
              )
                div(slot="label")
                  span.star-key *
                  span 縣市
                ValidationProvider(rules="invalidInput" v-slot="{ errors }")
                  el-select(
                    ref="city"
                    placeholder="請選擇"
                    size="medium"
                    :value="pharmacyForm.city"
                    @focus="highlightDialogFormItemsHandler(1)"
                    @change="selectCityHandler($event)"
                  )
                    el-option(
                      v-for="item in pharmaciesList.citiesAndDistricts"
                      :key="item.id"
                      :label="item.city"
                      :value="item.city"
                    )
                  p.error-msg {{ errors[0] }}
              el-form-item(
                :class="{ 'is-active': highlightDialogFormItemsStatusList[2] }"
                @click.native="$refs.administrativeDistrict.toggleMenu(); highlightDialogFormItemsHandler(2)"
              )
                div(slot="label")
                  span.star-key *
                  span 行政區
                ValidationProvider(rules="invalidInput" v-slot="{ errors }")
                  el-select(
                    ref="administrativeDistrict"
                    v-model="pharmacyForm.administrativeDistrict"
                    placeholder="請選擇"
                    size="medium"
                    @focus="highlightDialogFormItemsHandler(2)"
                  )
                    el-option(
                      v-for="item in filterAdministrativeDistricts(pharmacyForm.city)"
                      :key="item.districtName"
                      :label="item.districtName"
                      :value="item.districtName"
                    )
                  p.error-msg {{ errors[0] }}
              el-form-item(
                :class="{ 'is-active': highlightDialogFormItemsStatusList[3] }"
                @click.native="$refs.street.focus(); highlightDialogFormItemsHandler(3)"
              )
                div(slot="label")
                  span.star-key *
                  span 街道
                ValidationProvider(rules="invalidInput" v-slot="{ errors }")
                  el-input(
                    class="el-input-medium"
                    type="text"
                    ref="street"
                    v-model="pharmacyForm.street"
                    resize="none"
                    @focus="highlightDialogFormItemsHandler(3)"
                  )
                  p.error-msg {{ errors[0] }}
              el-form-item(
                :class="{ 'is-active': highlightDialogFormItemsStatusList[4] }"
                @click.native="$refs.address.focus(); highlightDialogFormItemsHandler(4)"
              )
                div(slot="label")
                  span.star-key *
                  span 地址
                ValidationProvider(rules="" v-slot="{ errors }")
                  el-input(
                    class="el-input-medium"
                    type="text"
                    ref="address"
                    v-model="pharmacyForm.address"
                    resize="none"
                    @focus="highlightDialogFormItemsHandler(4)"
                  )
                  p.error-msg {{ errors[0] }}
      .dialog-footer(slot="footer")
        el-row(:gutter="20")
          el-col.custom-col-24
            el-col(:span="12")
              el-button(
                class="btn-manage el-button-primary-plain"
                @click="resetPharmacyForm"
              ) 取消
            el-col(:span="12")
              el-button(
                class="btn-manage el-button-primary"
                :form="'pharmacy-form'"
                native-type="submit"
              ) 確定
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";

// components
import DeleteDialog from "@/components/frontend-manage/Base/DeleteDialog";
// assets

export default {
  props: {},
  data() {
    return {
      tableParams: [
        {
          name: "縣市",
          key: "city"
        },
        {
          name: "行政區",
          key: "administrativeDistrict"
        },
        {
          name: "街道",
          key: "street"
        },
        {
          name: "地址",
          key: "address"
        }
      ],
      // pharmaciesList,
      searchForm: {
        keyword: ""
      },
      pharmacyForm: {
        pharmacyName: "",
        city: "",
        administrativeDistrict: "",
        street: "",
        address: ""
      },
      deleteId: null,
      currentPage: 1,
      pharmacyDialogVisible: false,
      deleteDialogVisible: false,
      highlightSearchFormItemsStatusList: [false],
      highlightDialogFormItemsStatusList: [false, false, false, false, false]
    };
  },
  methods: {
    ...mapActions({}),
    async getPharmaciesList() {
      await this.$store.dispatch("pharmacies/getPharmaciesList", {
        page: this.currentPage,
        searchForm: {
          name: this.searchForm.keyword
        }
      });
    },
    onSearchFormSubmit() {
      this.currentPage = 1;
      this.$router
        .push({
          path: "/manage/pharmacies",
          query: { page: 1 }
        })
        .catch(err => err);
      this.getPharmaciesList();
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.$router.push({
        path: "/manage/pharmacies",
        query: { page }
      });
      this.getPharmaciesList();
    },
    async onDiaglogPharmacyFormSubmit() {
      await this.$store.dispatch("pharmacies/updatePharmacyInfo", {
        ...this.pharmacyForm,
        name: this.pharmacyForm.pharmacyName,
        district: this.pharmacyForm.administrativeDistrict
      });

      this.successMsg &&
        (alert(`${this.successMsg}`),
        this.$store.dispatch("successHandler", ""),
        this.resetPharmacyForm());
    },
    openUpdatePharmacyFormHandler(formObj) {
      // 模擬從表單上拿到該筆資料
      this.pharmacyForm = {
        ...formObj
      };
      this.pharmacyDialogVisible = true;
    },
    async deletePharmacyHandler() {
      await this.$store.dispatch("pharmacies/deletePharmacy", this.deleteId);

      this.checkLastDataInPage();
      this.getPharmaciesList();

      this.successMsg &&
        (alert(`${this.successMsg}`),
        this.$store.dispatch("successHandler", ""),
        (this.deleteId = null),
        (this.deleteDialogVisible = false));
    },
    checkLastDataInPage() {
      // 檢查是不是分頁的最後一筆資料
      const isLastDataInPage = (this.pharmaciesLength - 1) % 50;
      if (isLastDataInPage === 0) {
        this.currentPage = this.currentPage - 1;
        this.$router.push({
          path: "/manage/pharmacies",
          query: { page: this.currentPage }
        });
      }
    },
    selectCityHandler($event) {
      this.pharmacyForm = {
        ...this.pharmacyForm,
        city: $event,
        administrativeDistrict: ""
      };
    },
    filterAdministrativeDistricts(city) {
      const match = this.pharmaciesList.citiesAndDistricts.find(
        data => data.city === city
      );
      return match?.administrativeDistricts;
    },
    resetSearchForm() {
      this.searchForm = {
        keyword: ""
      };
      this.$refs.searchForm.reset();
      this.highlightSearchFormItemsStatusList.fill(false);
    },
    resetPharmacyForm() {
      this.pharmacyForm = {
        pharmacyName: "",
        city: "",
        administrativeDistrict: "",
        street: "",
        address: ""
      };
      this.pharmacyDialogVisible = false;
      this.$refs.pharmacyForm.reset();
      this.highlightDialogFormItemsStatusList.fill(false);
    },
    openDeletePharmacyDialog({ id } /*需要確認後端需要傳什麼給它*/) {
      this.deleteDialogVisible = true;
      this.deleteId = id;
    },
    closeDeletePharmacyDialog() {
      this.deleteId = "";
      this.deleteDialogVisible = false;
    },
    highlightSearchFormItemsHandler(index) {
      this.highlightSearchFormItemsStatusList.fill(false);
      this.highlightSearchFormItemsStatusList[index] = true;
      this.highlightSearchFormItemsStatusList = [
        ...this.highlightSearchFormItemsStatusList
      ];
    },
    highlightDialogFormItemsHandler(index) {
      this.highlightDialogFormItemsStatusList.fill(false);
      this.highlightDialogFormItemsStatusList[index] = true;
      this.highlightDialogFormItemsStatusList = [
        ...this.highlightDialogFormItemsStatusList
      ];
    }
  },
  computed: {
    ...mapGetters({
      pharmaciesList: "pharmacies/pharmaciesList",
      pharmaciesLength: "pharmacies/pharmaciesLength"
    }),
    successMsg() {
      return this.$store.state.successMsg;
    }
  },
  components: {
    DeleteDialog
  },
  async mounted() {
    await this.getPharmaciesList();
  }
};
</script>

<style lang="scss" scoped>
#search-form {
  margin-bottom: 40px;
  .search-bar {
    margin: 0 !important;
    padding: 25px;
    background: $fifth;
    border-radius: 5px;
    ::v-deep .el-form-item__label {
      width: 100%;
      text-align: left;
      font-size: 14px;
      color: #4a4a4a;
      line-height: 2;
      .empty {
        display: inline-block;
      }
    }
    .search-btn {
      width: 114px;
      padding: 0;
    }
    a {
      text-decoration: underline;
      margin-left: 49px;
    }
  }
}
#pharmacy-form {
  ::v-deep .el-select {
    .el-input__suffix {
      top: 28px;
    }
  }
}

.pharmacy-name {
  padding-left: 15px;
}

.branches-table-content {
  .check {
    margin-right: 10px;
  }
}
</style>
