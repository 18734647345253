import api from "@/api";

const mappingContactList = state => {
  const { data: contactList } = state.contactInfo;
  return contactList.map(contact => {
    return {
      id: contact.id,
      fullName: contact.fullName,
      gender: contact.gender,
      phone: contact.phone
    };
  });
};

const state = {
  contactInfo: {
    data: []
  },
  contactUser: {}
};

const getters = {
  contactList: state => mappingContactList(state),
  contactUser: state => {
    return {
      id: state.contactUser.id,
      lastName: state.contactUser.last_name,
      firstName: state.contactUser.first_name,
      phone: state.contactUser.phone,
      gender: state.contactUser.gender
    };
  },
  contactLength: state => state.contactInfo.total
};

const mutations = {
  CONTACT_INFO(state, contactInfo) {
    state.contactInfo = contactInfo;
  },
  CONTACT_USER(state, contactUser) {
    state.contactUser = contactUser;
  }
};

const actions = {
  async getContactList({ commit }, { page, searchForm }) {
    try {
      const { data: contactInfo } = await api.get("/members", {
        params: {
          page: page,
          ...searchForm
        }
      });
      commit("CONTACT_INFO", contactInfo);
    } catch (e) {
      console.log(e);
    }
  },
  async getSpecificUser({ commit }, id) {
    try {
      const { data: contactUser } = await api.get(`members/${id}`);
      commit("CONTACT_USER", contactUser);
    } catch (e) {
      console.log(e);
    }
  },
  async updateUserContactInfo({ commit, dispatch }, contactForm) {
    try {
      const { id } = contactForm;
      const { data: contactInfo } = await api.put(`members/${id}`, {
        ...contactForm
      });
      commit("CONTACT_INFO", contactInfo);
      dispatch("successHandler", "聯絡人資料修改成功!!", { root: true });
    } catch (e) {
      console.log(e);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
