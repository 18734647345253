<template lang="pug">
  el-dialog(
    class="delete-dialog"
    title="確認"
    :visible="confirmDialogVisible"
    width="450px"
    center
    append-to-body
    @close="$emit('update:confirmDialogVisible', false)"
  )
    el-row
      el-col(:span="24")
        span.dialog-message 是否確定儲存本次變更?
    .dialog-footer(slot="footer")
      el-row(:gutter="20")
        el-col.custom-col-24
          el-col(:span="12")
            el-button(
              class="btn-manage el-button-primary-plain el-button--large"
              @click="$emit('update:confirmDialogVisible', false)"
            ) 取消
          el-col(:span="12")
            el-button(
              class="btn-manage el-button-primary el-button--large"
              @click="$emit('updateOrderDetailsHandler')"
            ) 確定
</template>

<script>
export default {
  props: {
    confirmDialogVisible: {
      type: Boolean
    }
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  components: {},
  mounted() {}
};
</script>

<style lang="scss" scoped></style>
