<template lang="pug">
  header
    .logo
      img(:src="require('@/assets/images/main-logo.png')")
    h1 慢箋得來速
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";

// components

// views

// assets

export default {
  props: {},
  data() {
    return {};
  },
  methods: {
    ...mapActions({})
  },
  computed: {
    ...mapGetters({})
  },
  components: {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  text-align: center;
  background: $primary;
  height: 60px;
  .logo {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    img {
      width: 100%;
    }
  }
  h1 {
    display: inline-block;
    vertical-align: middle;
    line-height: 60px;
    color: $fifth;
    font-size: 25px;
  }
}
</style>
