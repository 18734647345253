import api from "@/api";
import {
  viewKeysToApiKeysConverter,
  apiKeysToViewKeysConverter
} from "@/assets/utils/dataKeyConverter.js";

const viewKeys = ["id", "hospitalName", "site", "address"];
const apiKeys = ["id", "name", "website", "address"];

const mappingHospitalsListList = hospitalsList => {
  return hospitalsList.map(hospital =>
    apiKeysToViewKeysConverter({ viewKeys, apiKeys }, hospital)
  );
};

const state = {
  hospitalsList: []
};

const getters = {
  hospitalsList: state => mappingHospitalsListList(state.hospitalsList),
  pharmaciesLength: state => state.pharmaciesInfo.total
};

const mutations = {
  HOSPITALS_LIST(state, hospitalsList) {
    state.hospitalsList = hospitalsList;
  }
};

const actions = {
  async getHospitalsList({ commit }) {
    try {
      // if need to search query, reference to pharmacies list
      // api has query feature, but not use now
      const { data: hospitalsList } = await api.get("/hospitals");
      commit("HOSPITALS_LIST", hospitalsList);
    } catch (e) {
      console.log(e);
    }
  },
  async updateHospitalsInfo({ dispatch }, hospitalsForm) {
    const convertedHospitalsForm = viewKeysToApiKeysConverter(
      { apiKeys, viewKeys },
      hospitalsForm
    );
    try {
      const { id } = convertedHospitalsForm;
      await api.put(`/hospitals/${id}`, {
        ...convertedHospitalsForm
      });
      dispatch("successHandler", "醫院資料修改成功!!", { root: true });
    } catch (e) {
      console.log(e);
    }
  },
  async createHospitalsInfo({ dispatch }, hospitalsForm) {
    const convertedHospitalsForm = viewKeysToApiKeysConverter(
      { apiKeys, viewKeys },
      hospitalsForm
    );
    try {
      const {
        data: { fail }
      } = await api.post(`/hospitals`, [convertedHospitalsForm]);
      if (!fail.length) {
        dispatch("successHandler", "醫院資料新增成功!!", { root: true });
      } else {
        dispatch("errorHandler", "醫院資料有誤，請確實填寫!!", { root: true });
      }
    } catch (e) {
      console.log(e);
    }
  },
  async deleteHospital({ dispatch }, id) {
    try {
      await api.delete(`/hospitals/${id}`);
      dispatch("successHandler", "醫院刪除成功!!", { root: true });
    } catch (e) {
      console.log(e);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
