<template lang="pug">
  ValidationObserver(ref="searchForm" v-slot="{ handleSubmit }")
    el-form(
      id="search-form"
      :model="searchForm"
      @submit.native.prevent="handleSubmit(onSearchFormSubmit)"
    )
      el-row.search-bar(:gutter="25")
        el-col(style="width: 20%" v-if="!isExcludeFields.find(field => field === 'startDate')")
          el-form-item(
            label="開始日期"
            :class="{ 'is-active': highlightStatusList[0] }"
            @click.native="$refs.startDate.$refs.datePicker.focus(); highlightHandler(0)"
          )
            DatePicker(
              ref="startDate"
              @focus="highlightHandler(0)"
              :value.sync="searchForm.startDate"
            )
        el-col(style="width: 20%" v-if="!isExcludeFields.find(field => field === 'endDate')")
          el-form-item(
            label="結束日期"
            :class="{ 'is-active': highlightStatusList[1] }"
            @click.native="$refs.endDate.$refs.datePicker.focus(); highlightHandler(1)"
          )
            DatePicker(
              ref="endDate"
              @focus="highlightHandler(1)"
              :value.sync="searchForm.endDate"
            )
        el-col(style="width: 20%" v-if="!isExcludeFields.find(field => field === 'contactPerson')")
          el-form-item(
            label="聯絡人"
            :class="{ 'is-active': highlightStatusList[2] }"
            @click.native="$refs.contactPerson.focus(); highlightHandler(2)"
          )
            ValidationProvider(rules="invalidInput" v-slot="{ errors }")
              el-input(
                class="el-input-medium"
                type="text"
                ref="contactPerson"
                resize="none"
                @focus="highlightHandler(2)"
                v-model="searchForm.contactPerson"
              )
              p.error-msg {{ errors[0] }}
        el-col(style="width: 20%" v-if="!isExcludeFields.find(field => field === 'contactPhone')")
          el-form-item(
            label="聯絡電話"
            :class="{ 'is-active': highlightStatusList[3] }"
            @click.native="$refs.contactPhone.focus(); highlightHandler(3)"
          )
            ValidationProvider(rules="invalidInput" v-slot="{ errors }")
              el-input(
                class="el-input-medium"
                type="text"
                ref="contactPhone"
                resize="none"
                @focus="highlightHandler(3)"
                v-model="searchForm.contactPhone"
              )
              p.error-msg {{ errors[0] }}
        el-col(style="width: 20%" v-if="!isExcludeFields.find(field => field === 'receiveMedicineName')")
          el-form-item(
            label="病人姓名"
            :class="{ 'is-active': highlightStatusList[4] }"
            @click.native="$refs.receiveMedicineName.focus(); highlightHandler(4)"
          )
            ValidationProvider(rules="invalidInput" v-slot="{ errors }")
              el-input(
                class="el-input-medium"
                type="text"
                ref="receiveMedicineName"
                resize="none"
                @focus="highlightHandler(4)"
                v-model="searchForm.receiveMedicineName"
              )
              p.error-msg {{ errors[0] }}
        el-col(style="width: 20%" v-if="!isExcludeFields.find(field => field === 'receiveMedicineType')" )
          el-form-item(
            label="領藥方式"
            :class="{ 'is-active': highlightStatusList[5] }"
            @click.native="$refs.receiveMedicineType.toggleMenu(); highlightHandler(5)"
          )
            el-select(
              ref="receiveMedicineType"
              @focus="highlightHandler(5)"
              v-model="searchForm.receiveMedicineType"
              size="medium"
              placeholder="請選擇"
            )
              el-option(
                v-for="type in typeOptions" 
                :key="type.id"
                :label="type.label" 
                :value="type.value"
              )
        el-col(style="width: 20%" v-if="!isExcludeFields.find(field => field === 'receiveMedicineLocation')")
          el-form-item(
            label="領藥機構"
            :class="{ 'is-active': highlightStatusList[6] }"
            @click.native="$refs.receiveMedicineLocation.focus(); highlightHandler(6)"
          )
            ValidationProvider(rules="invalidInput" v-slot="{ errors }")
              el-input(
                class="el-input-medium"
                type="text"
                ref="receiveMedicineLocation"
                resize="none"
                @focus="highlightHandler(6)"
                v-model="searchForm.receiveMedicineLocation"
              )
              p.error-msg {{ errors[0] }}
        el-col(style="width: 20%" v-if="!isExcludeFields.find(field => field === 'orderStatus')")
          el-form-item(
            label="慢箋狀態"
            :class="{ 'is-active': highlightStatusList[7] }"
            @click.native="$refs.orderStatus.toggleMenu(); highlightHandler(7)"
          )
            el-select(
              ref="orderStatus"
              @focus="highlightHandler(7)"
              v-model="searchForm.orderStatus"
              size="medium"
              placeholder="請選擇"
            )
              el-option(
                v-for="type in orderStatusOptions" 
                :key="type.id"
                :label="type.label" 
                :value="type.value"
              )
        el-col(style="width: 20%") 
          el-form-item
            div(slot="label")
              span.empty
            el-button(
              class="btn-manage el-button-primary el-button--medium search-btn"
              :form="'search-form'"
              native-type="submit"
            ) 搜尋
            a(
              href="#"
              class="clear-all"
              @click.prevent="resetSearchForm"
            ) 清除篩選
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";

// components
import DatePicker from "@/components/frontend-manage/Base/DatePicker";

// config
import { typeOptions } from "@/components/frontend-manage/OrdersManagement/options.js";

export default {
  props: {
    searchForm: {
      type: Object
    },
    isExcludeFields: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      typeOptions,
      highlightStatusList: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ]
    };
  },
  methods: {
    ...mapActions({}),
    onSearchFormSubmit() {
      this.$emit("onSearchFormSubmit");
      this.$refs.searchForm.reset();
      this.highlightStatusList.fill(false);
    },
    resetSearchForm() {
      this.$refs.searchForm.reset();
      this.highlightStatusList.fill(false);
      this.$emit("resetSearchForm");
    },
    highlightHandler(index) {
      this.highlightStatusList.fill(false);
      this.highlightStatusList[index] = true;
      this.highlightStatusList = [...this.highlightStatusList];
    }
  },
  computed: {
    ...mapGetters({}),
    orderStatusOptions() {
      return [
        {
          id: 0,
          label: "等待審核",
          value: 1
        },
        {
          id: 1,
          label: "備藥中",
          value: 2
        },
        {
          id: 2,
          label: "失敗-黑名單",
          value: 701
        },
        {
          id: 3,
          label: "失敗-照片",
          value: 702
        },
        {
          id: 4,
          label: "失敗-備藥時間",
          value: 703
        },
        {
          id: 5,
          label: "失敗-第二次已領",
          value: 704
        },
        {
          id: 6,
          label: "失敗-無獲利",
          value: 705
        },
        {
          id: 7,
          label: "失敗-其他",
          value: 706
        },
        {
          id: 8,
          label: "可領藥",
          value: 3
        },
        {
          id: 9,
          label: "取消",
          value: 4
        },
        {
          id: 10,
          label: "已領藥",
          value: 5
        },
        {
          id: 11,
          label: "未確認",
          value: 6
        },
        {
          id: 12,
          label: "退藥",
          value: 7
        },
        {
          id: 13,
          label: "登錄失敗",
          value: 9
        },
        {
          id: 14,
          label: "登錄成功",
          value: 10
        },
        {
          id: 15,
          label: "已通知",
          value: 11
        }
      ];
    }
  },
  components: {
    DatePicker
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
#search-form {
  margin-bottom: 40px;
  .search-bar {
    margin: 0 !important;
    padding: 25px;
    background: $fifth;
    border-radius: 5px;
    ::v-deep .el-form-item__label {
      width: 100%;
      text-align: left;
      font-size: 14px;
      color: #4a4a4a;
      line-height: 2;
      .empty {
        display: inline-block;
      }
    }
    .search-btn {
      width: 114px;
      padding: 0;
    }
    a {
      text-decoration: underline;
      margin-left: 49px;
    }
  }
  ::v-deep .el-select {
    width: 100%;
    .el-input__suffix {
      top: 33px;
    }
  }
  .date-picker {
    width: 100%;
    ::v-deep .el-input__inner {
      height: 36px;
      line-height: 36px;
    }
  }
}
</style>
