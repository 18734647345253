import axios from "axios";
import Cookie from "js-cookie";
import router from "@/router/index.js";

const baseURL = process.env.VUE_APP_BASE_URL;

const api = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json"
  }
});

api.interceptors.request.use(
  config => {
    const token = Cookie.get("token") || "";
    config.headers.Authorization = "Bearer " + token;
    return config;
  },
  error => Promise.reject(error)
);

// Add a response interceptor
api.interceptors.response.use(
  response => response,
  error => {
    if (
      error.response &&
      error.response.status === 401 &&
      window.location.pathname.includes("/manage")
    ) {
      Cookie.remove("token");
      router.push({ name: "Login" });
    }
    return Promise.reject(error);
  }
);

export default api;
