import api from "@/api";

const mappingHospitalsList = state => {
  return state.hospitalsList.map(hospital => {
    return {
      id: hospital.id,
      hospitalName: hospital.name,
      address: hospital.address
    };
  });
};

const state = {
  historyInfo: {
    data: [],
    currentPage: 1
  },
  hospitalsList: [],
  hospitalQueryRecords: {}
};
const getters = {
  hospitalsList: state => mappingHospitalsList(state),
  hospitalQueryRecords: state => state.hospitalQueryRecords
};

const mutations = {
  HOSPITALS_LIST(state, hospitalsList) {
    state.hospitalsList = hospitalsList;
  },
  HISTORY_PHARMACIES_QUERY_RECORDS(state, hospitalQueryRecords) {
    state.hospitalQueryRecords = hospitalQueryRecords;
  }
};

const actions = {
  async getHospitalsListList({ commit }) {
    try {
      const { data: hospitalsList } = await api.get("/member/hospitals");
      commit("HOSPITALS_LIST", hospitalsList);
    } catch (e) {
      console.log(e);
    }
  },
  storeHospitalQueryRecords({ commit }, hospitalQueryRecords) {
    commit("HISTORY_PHARMACIES_QUERY_RECORDS", hospitalQueryRecords);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
