import api from "@/api";
import Cookie from "js-cookie";

const state = {
  errorMsg: "",
  successMsg: "",
  loginInfo: {},
  sendInfo: {}
};

const getters = {
  admin: state => ({
    isAdmin: state.loginInfo.is_admin,
    account: state.loginInfo.account
  }),
  sendInfo: state => state.sendInfo
};

const mutations = {
  ERROR_MESSAGE(state, message) {
    state.errorMsg = message;
  },
  LOGIN_INFO(state, data) {
    state.loginInfo = data;
  },
  SUCCESS_MESSAGE(state, message) {
    state.successMsg = message;
  },
  SEND_INFO(state, sendInfo) {
    state.sendInfo = sendInfo;
  }
};

const actions = {
  errorHandler({ commit }, message) {
    commit("ERROR_MESSAGE", message);
  },
  successHandler({ commit }, message) {
    commit("SUCCESS_MESSAGE", message);
  },
  async loginHandler({ commit, dispatch }, loginForm) {
    try {
      const { data } = await api.post("/login", loginForm);
      commit("LOGIN_INFO", data);
      localStorage.setItem("loginInfo", JSON.stringify(data));
      dispatch("successHandler", "登入成功");
      Cookie.set("token", data.access_token);
    } catch (e) {
      dispatch("errorHandler", "帳號或密碼錯誤，請重新填寫");
      Cookie.set("token", "");
    }
  },
  async sendInfoHandler({ commit }, sendInfo) {
    commit("SEND_INFO", sendInfo);
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
