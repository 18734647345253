<template lang="pug">
  div.register 
    TitleBar(:title="'填寫聯絡人資料'")
    .container
      .form-container
        .register-form
          ValidationObserver(
            ref="registerForm"
            v-slot="{ handleSubmit }"
          )
            el-form(
              id="register-form"
              :model="registerForm"
              @submit.native.prevent="handleSubmit(onSubmit)"
            )
              p.tips *請務必填寫您的真實資料
              el-form-item(
                @click.native="$refs.lastName.focus(); highlightHandler(0)"
                :class="{ 'is-active': highlightStatusList[0] }"
              )
                div(slot="label")
                  span(class="star-key") *
                  span 姓氏
                ValidationProvider(rules="invalidInput|empty" v-slot="{ errors }")
                  el-input(
                    class="el-input-medium"
                    type="text"
                    ref="lastName"
                    v-model="registerForm.lastName"
                    @focus="highlightHandler(0)"
                    resize="none"
                  )
                  p.error-msg {{ errors[0] }}
              el-form-item(
                @click.native="$refs.firstName.focus(); highlightHandler(1)"
                :class="{ 'is-active': highlightStatusList[1] }"
              )
                div(slot="label")
                  span(class="star-key") *
                  span 名字
                ValidationProvider(rules="invalidInput|empty" v-slot="{ errors }")
                  el-input(
                    class="el-input-medium"
                    type="text"
                    ref="firstName"
                    v-model="registerForm.firstName"
                    @focus="highlightHandler(1)"
                    resize="none"
                  )
                  p.error-msg {{ errors[0] }}
              el-form-item.gender(
                @click.native="highlightHandler(2)"
                :class="{ 'is-active': highlightStatusList[2] }"
              )
                div(class="gender-label" slot="label")
                  span(class="star-key") *
                  span 性別
                ValidationProvider(rules="" v-slot="{ errors }")
                  el-radio(v-model="registerForm.gender" label="先生") 先生
                  el-radio(v-model="registerForm.gender" label="小姐") 小姐
              el-form-item(
                @click.native="$refs.phone.focus(); highlightHandler(3)"
                :class="{ 'is-active': highlightStatusList[3] }"
              )
                div(slot="label")
                  span(class="star-key") *
                  span 聯絡電話
                ValidationProvider(rules="phone|invalidInput|empty" v-slot="{ errors }")
                  el-input(
                    class="el-input-medium"
                    type="text"
                    ref="phone"
                    v-model="registerForm.phone"
                    placeholder="ex:02-12341234, 0911123123"
                    @focus="highlightHandler(3)"
                    resize="none"
                  )
                  p.error-msg {{ errors[0] }}
              .agree
                el-checkbox(v-model="registerForm.checked") 同意以下隱私權及使用條款
        .authorization 慢箋病患本人及其已授權之代理人(聯絡人)同意本服務所登錄使用之聯絡人資訊、病患資料、慢箋照片(圖片)及個資內容資訊，用於慢箋管理服務、處方調劑收受核對與交付、及相關健康管理使用；本服務將對上述所登錄之資訊進行保密及善盡適法合規之義務。
      el-button(
        class="btn el-button-primary el-button--large register-btn"
        :form="'register-form'"
        native-type="submit"
      ) 確定送出
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";
import liff from "@line/liff";

// components
import TitleBar from "@/components/frontend/Base/TitleBar.vue";

// views

// assets

export default {
  props: {},
  data() {
    return {
      registerForm: {
        lastName: "",
        firstName: "",
        gender: "先生",
        phone: "",
        checked: false
      },
      highlightStatusList: [false, false, false, false]
    };
  },
  methods: {
    ...mapActions({}),
    async onSubmit() {
      if (this.registerForm.checked) {
        await this.$store.dispatch("frontend_register/registerUserHandler", {
          first_name: this.registerForm.firstName,
          last_name: this.registerForm.lastName,
          gender: this.registerForm.gender,
          phone: this.registerForm.phone
        });

        this.successMsg &&
          (alert(`${this.successMsg}`),
          this.$store.dispatch("successHandler", ""),
          this.resetRegisterForm(),
          liff.closeWindow());

        this.errorMsg &&
          (alert(`${this.errorMsg}`),
          this.$store.dispatch("errorHandler", ""),
          this.resetRegisterForm(),
          liff.closeWindow());
      } else {
        window.alert("請勾選隱私權及使用條款！");
      }
    },
    resetRegisterForm() {
      this.registerForm = {
        lastName: "",
        firstName: "",
        gender: "先生",
        phone: "",
        checked: false
      };
      this.highlightStatusList.fill(false);
      this.$refs.registerForm.reset();
    },
    highlightHandler(index) {
      this.highlightStatusList.fill(false);
      this.highlightStatusList[index] = true;
      this.highlightStatusList = [...this.highlightStatusList];
    }
  },
  computed: {
    ...mapGetters({}),
    successMsg() {
      return this.$store.state.successMsg;
    },
    errorMsg() {
      return this.$store.state.errorMsg;
    }
  },
  components: {
    TitleBar
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.register {
  height: calc(100vh - 63px);
  .tips {
    color: $ninth;
    font-size: 15px;
    margin-bottom: 16px;
  }

  .container {
    padding: 0;

    .form-container {
      padding: 16px;
      border-radius: 5px;
      background: $fifth;
      ::v-deep {
        .el-form-item__content {
          line-height: 22px;
        }
      }

      .gender {
        .gender-label {
          display: inline-block;
          padding-right: 11px;
        }
      }

      .authorization {
        color: $third;
        font-size: 14px;
        padding: 10px 16px;
        background: $sixth;
        border-radius: 5px;
        margin-top: 5px;
      }
    }
    .register-btn {
      position: fixed;
      width: calc(100% - 50px);
      bottom: 18px;
      font-size: 16px;
    }
  }
  ::v-deep .el-radio {
    margin-right: 21px;
    .el-radio__input {
      margin-right: 5px;
    }
    .el-radio__label {
      padding-left: 0;
    }
    .el-radio__inner {
      width: 16px;
      height: 16px;
      &::after {
        background-color: $primary;
        width: 9px;
        height: 9px;
      }
    }
    .el-radio__input.is-checked {
      .el-radio__inner {
        border-color: $primary;
        background: $fifth;
      }
      + .el-radio__label {
        color: $primary;
      }
    }
  }

  ::v-deep .el-checkbox {
    margin-bottom: 5px;
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: $primary;
      border-color: $primary;
    }

    .el-checkbox__inner {
      width: 20px;
      height: 20px;
      &::after {
        box-sizing: content-box;
        content: "";
        border: 2px solid #fff;
        border-left: 0;
        border-top: 0;
        height: 10px;
        left: 6px;
        position: absolute;
        top: 2px;
        width: 4px;
        transition: transform 0.15s ease-in 0.05s;
        transform-origin: center;
      }
    }

    .el-checkbox__label {
      font-size: 16px;
      font-weight: 600;
      vertical-align: bottom;
    }

    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: $primary;
    }
  }
}
</style>
