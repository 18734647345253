import api from "@/api";

const mappingDeliveryList = state => {
  return state.deliveryList.map(delivery => {
    const administrativeDistricts = delivery.districts.map(district => {
      return {
        id: district.id,
        isOpen: district.is_open,
        districtName: district.name
      };
    });

    return {
      id: delivery.id,
      city: delivery.name,
      isOpen: delivery.is_open,
      administrativeDistricts
    };
  });
};

const state = {
  deliveryList: []
};

const getters = {
  deliveryList: state => mappingDeliveryList(state)
};
const mutations = {
  DISTRICTS_LIST(state, deliveryList) {
    state.deliveryList = deliveryList;
  }
};

const actions = {
  async getDistrictsList({ commit }) {
    try {
      const { data: deliveryList } = await api.get("/delivery-area");
      commit("DISTRICTS_LIST", deliveryList);
    } catch (e) {
      console.log(e);
    }
  },
  async updateDeliveryDistrictsHandler(
    { commit, dispatch },
    { id, administrativeDistricts }
  ) {
    try {
      const { data: deliveryList } = await api.put(`delivery-area/${id}`, {
        districts: administrativeDistricts
      });
      commit("DISTRICTS_LIST", deliveryList);
      dispatch("successHandler", "配送地區狀態修改成功!!", { root: true });
    } catch (e) {
      console.log(e);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
