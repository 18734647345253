<template lang="pug">
  div.frontend-title-bar {{ title }}
</template>

<script>
export default {
  props: {
    title: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.frontend-title-bar {
  position: fixed;
  width: 100%;
  top: 60px;
  z-index: 999;
  height: 44px;
  background: $fifth;
  color: $twentyTwo;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
  padding: 10px 0;
}
</style>
