<template lang="pug">
  .pharmacy-list
    .list-item(v-if="!list.length && !init" class="empty") 查無資料
    .list-item(
      v-else
      v-for="item in list" 
      :key="item.id"
      @click="$emit('update:selected', item);"
    )
      .name {{ item.pharmacy }}
      .address {{ item.address }}
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";

// components

// views

// assets

export default {
  props: {
    list: {
      type: Array
    },
    init: {
      type: Boolean
    }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({})
  },
  computed: {
    ...mapGetters({})
  },
  components: {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
.pharmacy-list {
  .list-item {
    border-radius: 5px;
    color: $third;
    background: $fifth;
    padding: 16px;
    margin-bottom: 10px;
    .name {
      font-weight: 600;
    }
    .address {
      font-size: 14px;
    }
  }
  .empty {
    text-align: center;
  }
}
</style>
