<template lang="pug">
  el-dialog(
    title="API 資訊"
    :visible="apiInfoDialogVisible"
    width="900px"
    center
    @close="$emit('update:apiInfoDialogVisible', false); $refs.apiInfoForm.reset()"
  )
    el-row
      el-col
        ValidationObserver(ref="apiInfoForm" v-slot="{ handleSubmit }")
          el-form(
            id="api-info-form"
            :model="apiInfoForm"
            @submit.native.prevent="updateAPIInfoHandler"
          )
            div(class="row-title title") LINE Login
            el-form-item(
              :class="{ 'is-active': highlightDialogFormItemsStatusList[0] }"
              @click.native="$refs.login_channel_id.focus(); highlightDialogFormItemsHandler(0)"
            )
              div(slot="label" class="pharmacy")
                span.star-key *
                span Channel ID
              ValidationProvider(rules="notAllowedEmpty|maximum20InputLength" v-slot="{ errors }")
                el-input(
                  class="el-input-medium"
                  type="text"
                  ref="login_channel_id"
                  v-model="apiInfoForm.login_channel_id"
                  resize="none"
                  @focus="highlightDialogFormItemsHandler(0)"
                )
                p.error-msg {{ errors[0] }}
            el-form-item(
              :class="{ 'is-active': highlightDialogFormItemsStatusList[1] }"
              @click.native="$refs.liff_url.focus(); highlightDialogFormItemsHandler(1)"
            )
              div(slot="label" class="pharmacy")
                span.star-key *
                span LIFF URL
              ValidationProvider(rules="notAllowedEmpty|maximum50InputLength" v-slot="{ errors }")
                el-input(
                  class="el-input-medium"
                  type="text"
                  ref="liff_url"
                  v-model="apiInfoForm.liff_url"
                  resize="none"
                  @focus="highlightDialogFormItemsHandler(1)"
                )
                p.error-msg {{ errors[0] }}
            div(class="row-title title") Messaging API
            el-form-item(
              :class="{ 'is-active': highlightDialogFormItemsStatusList[2] }"
              @click.native="$refs.channel_secret.focus(); highlightDialogFormItemsHandler(2)"
            )
              div(slot="label" class="pharmacy")
                span.star-key *
                span Channel secret
              ValidationProvider(rules="notAllowedEmpty|maximum50InputLength" v-slot="{ errors }")
                el-input(
                  class="el-input-medium"
                  type="text"
                  ref="channel_secret"
                  v-model="apiInfoForm.channel_secret"
                  resize="none"
                  @focus="highlightDialogFormItemsHandler(2)"
                )
                p.error-msg {{ errors[0] }}
            el-form-item(
              :class="{ 'is-active': highlightDialogFormItemsStatusList[3] }"
              @click.native="$refs.channel_access_token.focus(); highlightDialogFormItemsHandler(3)"
            )
              div(slot="label" class="pharmacy")
                span.star-key *
                span Channel access token
              ValidationProvider(rules="notAllowedEmpty|maximum200InputLength" v-slot="{ errors }")
                el-input(
                  class="el-input-medium"
                  type="textarea"
                  ref="channel_access_token"
                  v-model="apiInfoForm.channel_access_token"
                  resize="none"
                  @focus="highlightDialogFormItemsHandler(3)"
                )
                p.error-msg {{ errors[0] }}
            div(class="row-title title") LINE Bot 識別碼(系統自動生成)
            el-form-item()
              div(slot="label")
                span api_token
              ValidationProvider(rules="" v-slot="{ errors }")
                el-input(
                  class="el-input-medium"
                  type="text"
                  ref="api_token"
                  disabled
                  v-model="apiInfoForm.api_token"
                  resize="none"
                )
    .dialog-footer(slot="footer")
      el-row(:gutter="20")
        el-col.custom-col-24
          el-col(:span="12")
            el-button(
              class="btn-manage el-button-primary-plain"
              @click="cancel"
            ) 取消
          el-col(:span="12")
            el-button(
              class="btn-manage el-button-primary"
              :form="'api-info-form'"
              native-type="submit"
            ) 確定
</template>

<script>
export default {
  props: {
    apiInfoForm: {
      type: Object
    },
    apiInfoDialogVisible: {
      type: Boolean
    }
  },
  data() {
    return {
      highlightDialogFormItemsStatusList: [false, false, false, false, false]
    };
  },
  methods: {
    async updateAPIInfoHandler() {
      const isValid = await this.$refs.apiInfoForm.validate();

      if (isValid) {
        this.$emit("updateAPIInfoHandler");
      }
    },
    highlightDialogFormItemsHandler(index) {
      this.highlightDialogFormItemsStatusList.fill(false);
      this.highlightDialogFormItemsStatusList[index] = true;
      this.highlightDialogFormItemsStatusList = [
        ...this.highlightDialogFormItemsStatusList
      ];
    },
    cancel() {
      this.$emit("update:apiInfoDialogVisible", false);
      this.$refs.apiInfoForm.reset();
      this.highlightDialogFormItemsStatusList.fill(false);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #f7f7f7;
  color: #4a4a4a;
  border: none;
}

.row-title {
  width: 100%;
  margin-bottom: 5px;
  text-align: left;
  border-left: 5px solid #4a4a4a;
  padding-left: 10px;
  line-height: 1.5;
}

.title {
  font-weight: 600;
  margin-right: 16px;
  font-size: 16px;
}
</style>
