import api from "@/api";

const mappingPharmaciesList = (state, getters, rootState, rootGetters) => {
  const { data: pharmaciesList } = state.pharmaciesInfo;
  const list = pharmaciesList.map(pharmacy => {
    return {
      id: pharmacy.id,
      pharmacyName: pharmacy.name,
      city: pharmacy.city,
      administrativeDistrict: pharmacy.district,
      street: pharmacy.street,
      address: pharmacy.address
    };
  });

  return {
    citiesAndDistricts: rootGetters["districts/deliveryList"],
    list
  };
};

const state = {
  pharmaciesInfo: {
    data: []
  },
  failedAddPharmacies: []
};

const getters = {
  pharmaciesList: (state, getters, rootState, rootGetters) =>
    mappingPharmaciesList(state, getters, rootState, rootGetters),
  pharmaciesLength: state => state.pharmaciesInfo.total
};

const mutations = {
  PHARMACIES_INFO(state, pharmaciesInfo) {
    state.pharmaciesInfo = pharmaciesInfo;
  },
  FAILED_ADD_PHARMACIES(state, failedAddPharmacies) {
    state.failedAddPharmacies = failedAddPharmacies;
  }
};

const actions = {
  async getPharmaciesList({ commit, dispatch }, { page, searchForm }) {
    try {
      dispatch("districts/getDistrictsList", null, { root: true });
      const { data: deliveryList } = await api.get("/delivery-area");
      const { data: pharmaciesInfo } = await api.get("/pharmacies", {
        params: {
          page: searchForm.keyword ? "" : page,
          ...searchForm
        }
      });
      Promise.all([deliveryList, pharmaciesInfo]).then(
        ([deliveryList, pharmaciesInfo]) => {
          commit("districts/DISTRICTS_LIST", deliveryList, { root: true });
          commit("PHARMACIES_INFO", pharmaciesInfo);
        }
      );
    } catch (e) {
      console.log(e);
    }
  },
  async updatePharmacyInfo({ commit, dispatch }, pharmacyForm) {
    try {
      const { id } = pharmacyForm;
      const { data: pharmaciesInfo } = await api.put(`pharmacies/${id}`, {
        ...pharmacyForm
      });
      commit("PHARMACIES_INFO", pharmaciesInfo);
      dispatch("successHandler", "藥局資料修改成功!!", { root: true });
    } catch (e) {
      console.log(e);
    }
  },
  async deletePharmacy({ dispatch }, id) {
    try {
      await api.delete(`pharmacies/${id}`);
      dispatch("successHandler", "藥局刪除成功!!", { root: true });
    } catch (e) {
      console.log(e);
    }
  },
  async addPharmacies({ commit, dispatch }, pharmaciesList) {
    try {
      const {
        data: { fail }
      } = await api.post("pharmacies", pharmaciesList);

      if (!fail.length) {
        dispatch("getPharmaciesList", { page: "", searchForm: {} });
        dispatch("successHandler", "藥局新增成功!!", { root: true });
      } else {
        commit("FAILED_ADD_PHARMACIES", fail);
      }
    } catch (e) {
      if (e.response.status === 422) {
        dispatch("errorHandler", "請確實填寫所有欄位!!", { root: true });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
