<template lang="pug">
  el-dialog(
    :title="type"
    :visible="orderDialogVisible"
    width="900px"
    center
    :class="{ 'order-review': type === '訂單詳情' }"
    @close="$emit('update:orderDialogVisible', false)"
  )
    el-row
      el-col
        ValidationObserver(ref="orderForm" v-slot="{ handleSubmit }")
          el-form(
            id="order-form"
            :model="orderForm"
          )
            el-row(class="dialog-contact-person")
              el-form-item(
              )
                div(class="order-row-title title") 聯絡人資料
                div(class="order-row-item")
                  el-col(:span="12" class="right-side-border")  
                    div
                      span.title 聯絡人
                      span
                        | {{ orderForm.contactPerson }}
                        span.bad-records(v-if="orderForm.badRecords") ({{ orderForm.badRecords }})
                        img.male(v-if="orderForm.gender === '先生'" :src="require('@/assets/images/male.png')")
                        img.female(v-if="orderForm.gender === '小姐'" :src="require('@/assets/images/female.png')")
                  el-col(:span="11" :offset="1")
                    .contact-phone
                      span.title 聯絡電話
                      span {{ orderForm.contactPhone }}
            el-row(class="dialog-order-details")
              el-form-item()
                div(class="order-row-title title") 訂單資料
                div(class="order-row-item")
                  el-col(:span="12" class="right-side-border")  
                    div.order-info
                      span.title 訂單編號
                      span {{ orderForm.orderNumber }}
                    div.order-info
                      span.title 訂單時間
                      span {{ orderForm.orderDate }}
                    div.order-info(
                      @click="type === '訂單詳情' && $refs.orderDate.toggleMenu(); highlightHandler(0)"
                      :class="{ 'is-active': highlightStatusList[0] && type === '訂單詳情' }"
                    )
                      span.title 慢箋狀態
                      span(v-if="type === '審核訂單' && orderForm.orderType !== 'C' && orderForm.orderType !== 'D'") 等待審核
                      el-select(
                        v-else
                        disabled
                        ref="orderDate"
                        placeholder="請選擇"
                        size="medium"
                        class="el-input-small"
                        v-model="orderForm.orderStatus"
                      )
                        el-option(
                          v-for="item in checkOrderOptions" 
                          :key="item.id"
                          :label="item.label"
                          :value="item.value"
                        )
                  el-col(:span="11" :offset="1")
                    el-col(:span="3")
                      span
                      span.title(
                        :class="{ 'is-active': highlightStatusList[1] }"
                        @click="$refs.memo.focus();highlightHandler(1);"
                      ) 備註
                    el-col(:span="21")
                      ValidationProvider(rules="invalidInput" v-slot="{ errors }")
                        .reason
                          el-input(
                            disabled
                            type="textarea"
                            ref="memo"
                            v-model="orderForm.memo"
                            resize="none"
                            @focus="highlightHandler(1)"
                          )
                        p.error-msg {{ errors[0] }}
            el-row(class="dialog-receive-medicine")
              el-form-item()
                div(class="order-row-title title") 領藥資料
                div(class="order-row-item")
                  el-col(:span="12" class="right-side-border")  
                    div.order-info(
                      :class="{ 'is-active': highlightStatusList[2] }"
                      style="margin-bottom: 22px;"
                    )
                      ValidationProvider(:rules="`orderRules:審核訂單|invalidInput`", v-slot="{ errors }")
                        span.star-key(v-if="orderForm.orderStatus === 2 || orderForm.orderStatus === 1 || orderForm.orderStatus === 8") *
                        span.title(
                          @click="$refs.receiveMedicineName.focus();highlightHandler(2);"
                        ) 病人姓名
                        el-input(
                          disabled
                          class="el-input-small dialog-receive-medicine-name"
                          type="text"
                          ref="receiveMedicineName"
                          v-model="orderForm.receiveMedicineName"
                          resize="none"
                          @focus="highlightHandler(2)"
                        )
                        p.error-msg {{ errors[0] }}
                    div.order-info(style="margin-bottom: 22px;")
                      span.title 領藥方式
                      span {{ typeText(orderForm.receiveMedicineType) }}
                    div.order-info()
                      span.title 領藥機構
                      span {{ orderForm.receiveMedicineLocation }}
                  el-col(:span="11" :offset="1")
                    .order-info(
                      :class="{ 'is-active': highlightStatusList[3] }"
                      @click="$refs.startDate.$refs.datePicker.focus();highlightHandler(3);"
                    )
                      ValidationProvider(:rules="`orderRules:審核訂單`"  v-slot="{ errors }")
                        span.star-key(v-if="orderForm.orderStatus === 2 || orderForm.orderStatus === 1 || orderForm.orderStatus === 8 ") *
                        span.title 開始領藥日期
                        DatePicker(
                          :disabled="true"
                          ref="startDate"
                          :value.sync="orderForm.startReceiveMedicineDate"
                        )
                        p.error-msg {{ errors[0] }}
                    .order-info(
                      :class="{ 'is-active': highlightStatusList[4] }"
                      @click="$refs.endDate.$refs.datePicker.focus();highlightHandler(4);"
                    )
                      ValidationProvider(:rules="`orderRules:審核訂單`" v-slot="{ errors }")
                        span.star-key(v-if="orderForm.orderStatus === 2 || orderForm.orderStatus === 1 || orderForm.orderStatus === 8") *
                        span.title 最後領藥日期
                        DatePicker(
                          :disabled="true"
                          ref="endDate"
                          :value.sync="orderForm.endReceiveMedicineDate"
                        )
                        p.error-msg {{ errors[0] }}
                    .order-info(v-if="(orderForm.orderType === 'A' || orderForm.orderType === 'B' )")
                      span.title 確認領藥日期
                      span {{ orderForm.checkReceiveMedicineDate ? orderForm.checkReceiveMedicineDate : "尚無資訊" }}
            el-row(
              v-if="type === '訂單詳情'"
              class="receive-medicine-notification"
            )
              el-form-item
                div(class="order-row-title title") 領藥通知
                .order-row-item
                  el-col(:span="12" class="right-side-border" v-if="(orderForm.orderType === 'A' || orderForm.orderType === 'B' )")
                    .order-info
                      span.title 預約成功通知
                      span {{ orderForm.reserveSuccess ? "已傳送" : "未傳送" }}
                    .order-info
                      span.title 開始領藥通知
                      span {{ orderForm.startReceiveMedicine ? "已傳送" : "未傳送" }}
                    .order-info
                      span.title 領藥期間通知
                      span {{ orderForm.duringReceiveMedicine ? "已傳送" : "未傳送" }}
                  el-col(:span="12" class="right-side-border" v-else)
                    .order-info
                      span.title 上傳成功通知
                      span {{ orderForm.hospitalSuccess ? "已傳送" : "未傳送" }}
                  el-col(:span="11" :offset="1" v-if="(orderForm.orderType === 'A' || orderForm.orderType === 'B' )")
                    .order-info
                      span.title 最後領藥通知
                      span {{ orderForm.lastReceiveMedicine ? "已傳送" : "未傳送" }}
                    .order-info
                      span.title 逾期未領通知
                      span {{ orderForm.outDateReceiveMedicine ? "已傳送" : "未傳送" }}
                    .order-info
                      span.title 預約提醒通知
                      span {{ orderForm.duringReceiveMedicine ? "已傳送" : "未傳送" }}
                  el-col(:span="11" :offset="1" v-else)
                    .order-info
                      span.title 預約提醒通知
                      span {{ orderForm.hospitalNotified ? "已傳送" : "未傳送" }}
</template>

<script>
// plugin
import dayjs from "dayjs";
// components
import DatePicker from "@/components/frontend-manage/Base/DatePicker";

// config
import {
  typeOptions,
  orderStatusOptions,
  hospitalOrderStatusOptions
} from "@/components/frontend-manage/OrdersManagement/options.js";

export default {
  props: {
    orderForm: {
      type: Object
    },
    searchForm: {
      type: Object
    },
    orderDialogVisible: {
      type: Boolean
    },
    isCreateThirdOrder: {
      type: Boolean
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      typeOptions,
      orderStatusOptions,
      hospitalOrderStatusOptions,
      highlightStatusList: [false, false, false, false, false]
    };
  },
  methods: {
    encodeISO8061DateTime(date, type) {
      if (type === "start") {
        const time = new Date(date).getTime() + 3600 * 1000 * 8;
        const result = dayjs(time).toISOString();
        return result.split(".")[0] + "+08:00";
      } else {
        const time =
          new Date(date).getTime() + 3600 * 1000 * 8 + 86400 * 1000 * 1 - 1;
        const result = dayjs(time).toISOString();
        return result.split(".")[0] + "+08:00";
      }
    },
    highlightHandler(index) {
      this.highlightStatusList.fill(false);
      this.highlightStatusList[index] = true;
      this.highlightStatusList = [...this.highlightStatusList];
    },
    typeText(type) {
      const obj = {
        1: "配送到府",
        2: "藥局",
        3: "醫院"
      };
      return obj[type];
    }
  },
  computed: {
    successMsg() {
      return this.$store.state.successMsg;
    },
    errorMsg() {
      return this.$store.state.errorMsg;
    },
    checkOrderOptions() {
      const { orderType } = this.orderForm;
      if (orderType === "A" || orderType === "B") {
        return this.orderStatusOptions;
      } else {
        return this.hospitalOrderStatusOptions;
      }
    }
  },
  components: {
    DatePicker
    // FailDialog,
    // ConfirmDialog
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
#order-form {
  ::v-deep .el-form-item__content {
    line-height: 1.5;
  }

  .dialog-order-details .order-info,
  .dialog-receive-medicine .order-info,
  .receive-medicine-notification .order-info {
    margin-bottom: 16px;
    position: relative;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .title {
    font-weight: 600;
    margin-right: 16px;
  }

  .right-side-border {
    border-right: 1px solid #b4b4b5;
  }

  .order-row-title {
    width: 100%;
    margin-bottom: 5px;
    text-align: left;
    border-left: 5px solid #4a4a4a;
    padding-left: 10px;
    line-height: 1.5;
  }

  .order-row-item {
    border-radius: 8px;
    border: 1px solid #b4b4b5;
    padding: 16px 25px;
    &::after {
      content: "";
      display: block;
      clear: both;
    }
  }

  .dialog-contact-person {
    .male {
      width: 12px;
      height: 12px;
    }
    .female {
      width: 9px;
      height: 12px;
    }
    img {
      margin: -5px 0 0 3px;
    }
    .bad-records {
      color: #e67873;
    }
  }

  .dialog-order-details {
    ::v-deep .el-select {
      display: inline-block;
      width: 75%;
      margin-left: -5px;
      .el-input__icon {
        line-height: 30px;
      }
      .el-input__suffix {
        top: 0;
      }
    }
    .reason {
      height: 110px;
      ::v-deep .el-textarea {
        height: 100%;
        .el-textarea__inner {
          height: 100%;
        }
      }
    }
  }
  .dialog-receive-medicine {
    ::v-deep .el-input__icon {
      line-height: 30px;
    }
    .dialog-receive-medicine-name {
      display: inline-block;
      width: 74%;
      margin-left: -5px;
    }
    .date-picker {
      display: inline-block;
      width: 73%;
      ::v-deep .el-icon-caret-bottom {
        bottom: 9px;
      }
      ::v-deep .el-input__inner {
        height: 30px;
        line-height: 30px;
      }
    }
    .title {
      margin-right: 7px;
    }
  }
  .receive-medicine-notification {
    ::v-deep .el-form-item {
      margin-bottom: 0;
    }
  }
}

.dialog-footer {
  .third-dialog-receive-medicine {
    margin-bottom: 16px;
    width: 100%;
  }
}

.order-review {
  ::v-deep .el-dialog {
    @media (max-height: 768px) {
      top: 60% !important;
      margin-bottom: 150px !important;
    }
  }
}

.is-active {
  color: $primary;
}
::v-deep .el-input.is-disabled .el-input__inner,
::v-deep .el-textarea.is-disabled .el-textarea__inner {
  background-color: #f7f7f7;
  color: #4a4a4a;
  border: none;
}
</style>
