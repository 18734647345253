var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":"API 資訊","visible":_vm.apiInfoDialogVisible,"width":"900px","center":""},on:{"close":function($event){_vm.$emit('update:apiInfoDialogVisible', false); _vm.$refs.apiInfoForm.reset()}}},[_c('el-row',[_c('el-col',[_c('ValidationObserver',{ref:"apiInfoForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('el-form',{attrs:{"id":"api-info-form","model":_vm.apiInfoForm},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.updateAPIInfoHandler($event)}}},[_c('div',{staticClass:"row-title title"},[_vm._v("LINE Login")]),_c('el-form-item',{class:{ 'is-active': _vm.highlightDialogFormItemsStatusList[0] },nativeOn:{"click":function($event){_vm.$refs.login_channel_id.focus(); _vm.highlightDialogFormItemsHandler(0)}}},[_c('div',{staticClass:"pharmacy",attrs:{"slot":"label"},slot:"label"},[_c('span',{staticClass:"star-key"},[_vm._v("*")]),_c('span',[_vm._v("Channel ID")])]),_c('ValidationProvider',{attrs:{"rules":"notAllowedEmpty|maximum20InputLength"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{ref:"login_channel_id",staticClass:"el-input-medium",attrs:{"type":"text","resize":"none"},on:{"focus":function($event){return _vm.highlightDialogFormItemsHandler(0)}},model:{value:(_vm.apiInfoForm.login_channel_id),callback:function ($$v) {_vm.$set(_vm.apiInfoForm, "login_channel_id", $$v)},expression:"apiInfoForm.login_channel_id"}}),_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{class:{ 'is-active': _vm.highlightDialogFormItemsStatusList[1] },nativeOn:{"click":function($event){_vm.$refs.liff_url.focus(); _vm.highlightDialogFormItemsHandler(1)}}},[_c('div',{staticClass:"pharmacy",attrs:{"slot":"label"},slot:"label"},[_c('span',{staticClass:"star-key"},[_vm._v("*")]),_c('span',[_vm._v("LIFF URL")])]),_c('ValidationProvider',{attrs:{"rules":"notAllowedEmpty|maximum50InputLength"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{ref:"liff_url",staticClass:"el-input-medium",attrs:{"type":"text","resize":"none"},on:{"focus":function($event){return _vm.highlightDialogFormItemsHandler(1)}},model:{value:(_vm.apiInfoForm.liff_url),callback:function ($$v) {_vm.$set(_vm.apiInfoForm, "liff_url", $$v)},expression:"apiInfoForm.liff_url"}}),_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"row-title title"},[_vm._v("Messaging API")]),_c('el-form-item',{class:{ 'is-active': _vm.highlightDialogFormItemsStatusList[2] },nativeOn:{"click":function($event){_vm.$refs.channel_secret.focus(); _vm.highlightDialogFormItemsHandler(2)}}},[_c('div',{staticClass:"pharmacy",attrs:{"slot":"label"},slot:"label"},[_c('span',{staticClass:"star-key"},[_vm._v("*")]),_c('span',[_vm._v("Channel secret")])]),_c('ValidationProvider',{attrs:{"rules":"notAllowedEmpty|maximum50InputLength"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{ref:"channel_secret",staticClass:"el-input-medium",attrs:{"type":"text","resize":"none"},on:{"focus":function($event){return _vm.highlightDialogFormItemsHandler(2)}},model:{value:(_vm.apiInfoForm.channel_secret),callback:function ($$v) {_vm.$set(_vm.apiInfoForm, "channel_secret", $$v)},expression:"apiInfoForm.channel_secret"}}),_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{class:{ 'is-active': _vm.highlightDialogFormItemsStatusList[3] },nativeOn:{"click":function($event){_vm.$refs.channel_access_token.focus(); _vm.highlightDialogFormItemsHandler(3)}}},[_c('div',{staticClass:"pharmacy",attrs:{"slot":"label"},slot:"label"},[_c('span',{staticClass:"star-key"},[_vm._v("*")]),_c('span',[_vm._v("Channel access token")])]),_c('ValidationProvider',{attrs:{"rules":"notAllowedEmpty|maximum200InputLength"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{ref:"channel_access_token",staticClass:"el-input-medium",attrs:{"type":"textarea","resize":"none"},on:{"focus":function($event){return _vm.highlightDialogFormItemsHandler(3)}},model:{value:(_vm.apiInfoForm.channel_access_token),callback:function ($$v) {_vm.$set(_vm.apiInfoForm, "channel_access_token", $$v)},expression:"apiInfoForm.channel_access_token"}}),_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"row-title title"},[_vm._v("LINE Bot 識別碼(系統自動生成)")]),_c('el-form-item',[_c('div',{attrs:{"slot":"label"},slot:"label"},[_c('span',[_vm._v("api_token")])]),_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{ref:"api_token",staticClass:"el-input-medium",attrs:{"type":"text","disabled":"","resize":"none"},model:{value:(_vm.apiInfoForm.api_token),callback:function ($$v) {_vm.$set(_vm.apiInfoForm, "api_token", $$v)},expression:"apiInfoForm.api_token"}})]}}],null,true)})],1)],1)]}}])})],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{staticClass:"custom-col-24"},[_c('el-col',{attrs:{"span":12}},[_c('el-button',{staticClass:"btn-manage el-button-primary-plain",on:{"click":_vm.cancel}},[_vm._v("取消")])],1),_c('el-col',{attrs:{"span":12}},[_c('el-button',{staticClass:"btn-manage el-button-primary",attrs:{"form":'api-info-form',"native-type":"submit"}},[_vm._v("確定")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }