<template lang="pug">
  div.app-container
    NavBar
    router-view.page
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";

// components
import NavBar from "@/components/frontend-manage/NavBar.vue";

// views

// assets

export default {
  props: {},
  data() {
    return {};
  },
  methods: {
    ...mapActions({})
  },
  computed: {
    ...mapGetters({})
  },
  components: {
    NavBar
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.app-container {
  min-width: 1440px;
  height: 100vh;
  background: $eighth;
  .page {
    padding-top: 25px;
  }
}
</style>
