import api from "@/api";
import dayjs from "dayjs";
import qs from "qs";

const mappingOrderDetails = state => {
  return {
    orderNumber: state.orderDetails.order_number,
    childOrderNumber: state.orderDetails.child_order_number,
    parentOrderNumber: state.orderDetails.parent_order_number,
    contactPerson: state.orderDetails.member_name,
    gender: state.orderDetails.member_gender,
    badRecords: state.orderDetails.failed_count,
    contactPersonId: state.orderDetails.member_id,
    contactPhone: state.orderDetails.member_phone,
    receiveMedicineName: state.orderDetails.receiver,
    receiveMedicineType: state.orderDetails.method,
    receiveMedicineLocation: state.orderDetails.location,
    orderStatus: state.orderDetails.status,
    orderDate: state.orderDetails.created_at,
    startReceiveMedicineDate:
      state.orderDetails.started_at == null
        ? null
        : dayjs(state.orderDetails.started_at).format("YYYY/MM/DD"),
    endReceiveMedicineDate:
      state.orderDetails.ended_at == null
        ? null
        : dayjs(state.orderDetails.ended_at).format("YYYY/MM/DD"),
    checkReceiveMedicineDate: state.orderDetails.confirmed_at,
    picture: state.orderDetails.prescription_url,
    memo: state.orderDetails.note,
    orderType: state.orderDetails.order_type,
    reserveSuccess: state.orderDetails.notify_record.success,
    startReceiveMedicine: state.orderDetails.notify_record.started,
    duringReceiveMedicine: state.orderDetails.notify_record.period,
    lastReceiveMedicine: state.orderDetails.notify_record.ended,
    outDateReceiveMedicine: state.orderDetails.notify_record.overdue,
    hospitalSuccess: state.orderDetails.notify_record.hospital_success,
    hospitalNotified: state.orderDetails.notify_record.hospital_notified
  };
};
const mappingOrderInfo = state => {
  const { data, current_page } = state.orderInfo;
  const list = data.map(item => {
    return {
      orderNumber: item.order_number,
      childOrderNumber: item.child_order_number,
      parentOrderNumber: item.parent_order_number,
      contactPerson: item.member_name,
      gender: item.member_gender,
      badRecords: item.failed_count,
      contactPersonId: item.member_id,
      contactPhone: item.member_phone,
      receiveMedicineName: item.receiver,
      receiveMedicineType: item.method,
      receiveMedicineLocation: item.location,
      orderStatus: item.status,
      orderDate: item.created_at,
      startReceiveMedicineDate: item.started_at,
      endReceiveMedicineDate: item.ended_at,
      checkReceiveMedicineDate: item.confirmed_at,
      picture: item.prescription_url,
      memo: item.note,
      orderType: item.order_type
    };
  });
  return {
    list,
    currentPage: current_page
  };
};
const state = {
  orderInfo: {
    data: [],
    currentPage: 1
  },
  orderDetails: {
    notify_record: {}
  }
};
const getters = {
  orderInfo: state => mappingOrderInfo(state),
  ordersLength: state => state.orderInfo.total,
  orderDetails: state => mappingOrderDetails(state)
};
const mutations = {
  ORDER_LIST(state, orderInfo) {
    state.orderInfo = orderInfo;
  },
  ORDER_DETAILS(state, orderDetails) {
    state.orderDetails = orderDetails;
  }
};

const actions = {
  // eslint-disable-next-line no-unused-vars
  async exportReports({ commit }, searchFrom) {
    const { data: orderInfo } = await api.get("/orders", {
      params: { ...searchFrom },
      responseType: "blob"
    });

    const blob = new Blob([orderInfo], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });

    const a = document.createElement("a");
    let href = window.URL.createObjectURL(blob);
    a.href = href;
    a.download = "訂單.xlsx";
    a.click();
    window.URL.revokeObjectURL(href);
  },
  async getOrderList({ commit, dispatch }, searchFrom) {
    let { status } = searchFrom;
    if (status == 1) {
      searchFrom.status = [1, 8]; // 等待審核
    } else if (status == 4) {
      searchFrom.status = [4, 12]; // 取消
    } else if (!status) {
      delete searchFrom.status;
    } else {
      searchFrom.status = [status]; // 其他訂單狀態
    }

    try {
      const { data: orderInfo } = await api.get("/orders", {
        params: { ...searchFrom },
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      });
      commit("ORDER_LIST", orderInfo);
    } catch (e) {
      dispatch("errorHandler", e.response.data.message, {
        root: true
      });
    }
  },
  // async deleteOrder({ dispatch }, orderNumber) {
  //   try {
  //     await api.delete(`orders/${orderNumber}`);
  //     dispatch("successHandler", "訂單刪除成功!!", { root: true });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // },
  async getOrderDetails({ commit }, orderNumber) {
    try {
      const { data: orderDetails } = await api.get(`orders/${orderNumber}`);
      commit("ORDER_DETAILS", orderDetails);
    } catch (e) {
      console.log(e);
    }
  }
  // async updateOrder({ dispatch }, orderForm) {
  //   try {
  //     const { orderNumber, type, reserveStatus } = orderForm;
  //     await api.put(`/orders/${orderNumber}`, {
  //       ...orderForm
  //     });
  //
  //     type === "審核訂單" &&
  //       reserveStatus === "預約成功" &&
  //       dispatch("successHandler", "訂單預約成功!!", { root: true });
  //     type === "審核訂單" &&
  //       reserveStatus === "登錄成功" &&
  //       dispatch("successHandler", "訂單登錄成功!!", { root: true });
  //     type === "審核訂單" &&
  //       reserveStatus === "失敗" &&
  //       dispatch("successHandler", "訂單預約失敗", { root: true });
  //     type === "審核訂單" &&
  //       reserveStatus === "登錄失敗" &&
  //       dispatch("successHandler", "訂單登錄失敗!!", { root: true });
  //     type === "訂單詳情" &&
  //       reserveStatus === "儲存變更" &&
  //       dispatch("successHandler", "訂單儲存成功!!", { root: true });
  //     type === "訂單詳情" &&
  //       reserveStatus === "訂單取消" &&
  //       dispatch("successHandler", "訂單取消成功!!", { root: true });
  //   } catch (e) {
  //     if (e.response.status === 422 && orderForm.status === 706) {
  //       dispatch("errorHandler", "訂單狀態為失敗-其他時，備註欄位必填", {
  //         root: true
  //       });
  //     } else {
  //       dispatch("errorHandler", e.response.data.message, {
  //         root: true
  //       });
  //     }
  //   }
  // },
  // async createThirdOrdersHandler({ dispatch }, orderNumber) {
  //   await api.post("/orders", {
  //     order_number: orderNumber
  //   });
  //
  //   dispatch("successHandler", "建立第三次領藥訂單成功!!", { root: true });
  // }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
