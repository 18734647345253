<template lang="pug">
  .delivery-service
    div
      TitleBar(:title="'選擇送藥到府'")
      .banner
        img(:src="require('@/assets/images/group.png')")
      .container
        .list-item(v-if="!citiesList.length" class="empty") 
          p 疫情期間暫不提供送藥到府服務
          p 請選擇 [ 藥局領藥 ] 謝謝!
        StreetSearch(
          v-if="citiesList.length"
          @getPharmaciesList="getPharmaciesList"
          @sendInfoHandler="sendInfoHandler"
          :selectedCity.sync="selectedCity"
          :selectedAdministrativeDistricts.sync="selectedAdministrativeDistricts"
          :selectedStreet.sync="selectedStreet"
          :citiesList="citiesList"
          :districtsList="districtsList"
          :type="'delivery'"
        )
      
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";

// components
import TitleBar from "@/components/frontend/Base/TitleBar.vue";
import StreetSearch from "@/components/frontend/StreetSearch.vue";
// views

// assets

export default {
  props: {},
  data() {
    return {
      selectedCity: "",
      selectedAdministrativeDistricts: "",
      selectedStreet: "",
      address: ""
    };
  },
  methods: {
    ...mapActions({}),
    async getPharmaciesList({ searchType, status }) {
      let searchParams = this.deliveryQueryRecords.city
        ? (searchParams = this.deliveryQueryRecords)
        : {};
      if (searchType === "配送到府" && status === "init") {
        return await this.$store.dispatch(
          "frontend_choose_pharmacies/getDeliveryPharmaciesList",
          { searchParams, status }
        );
      } else if (searchType === "配送到府" && status === "city") {
        searchParams = {
          city: this.selectedCity
        };
        return await this.$store.dispatch(
          "frontend_choose_pharmacies/getDeliveryPharmaciesList",
          { searchParams, status }
        );
      }
    },
    sendInfoHandler() {
      this.address =
        this.selectedCity +
        this.selectedAdministrativeDistricts +
        this.selectedStreet;
      const sendInfo = {
        method: 1, // 1: 配送到府 ; 2: 藥局配送 ; 3: 醫院
        prescription: {},
        pick_up_location: {
          address: this.address
        }
      };

      this.$store.dispatch("sendInfoHandler", sendInfo);

      const deliveryQueryRecords = {
        city: this.selectedCity,
        district: this.selectedAdministrativeDistricts,
        street: this.selectedStreet
      };

      this.$store.dispatch(
        "frontend_choose_pharmacies/storeDeliveryQueryRecords",
        deliveryQueryRecords
      );

      this.$router.push({ name: "PrescriptionPhoto" });
    }
  },
  computed: {
    ...mapGetters({
      citiesList: "frontend_choose_pharmacies/citiesList",
      districtsList: "frontend_choose_pharmacies/districtsList",
      deliveryQueryRecords: "frontend_choose_pharmacies/deliveryQueryRecords"
    })
  },
  components: {
    TitleBar,
    StreetSearch
  },
  async mounted() {
    const { city, district, street } = this.deliveryQueryRecords;
    this.selectedCity = city;
    this.selectedAdministrativeDistricts = district;
    this.selectedStreet = street;
    await this.getPharmaciesList({ searchType: "配送到府", status: "init" });
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0;
  margin: 20px 25px 0 25px;
}
.banner {
  margin-top: 105px;
  img {
    width: 100%;
  }
}
.list-item {
  border-radius: 5px;
  color: $third;
  background: $fifth;
  padding: 16px;
  margin-bottom: 10px;
  .name {
    font-weight: 600;
  }
  .address {
    font-size: 14px;
  }
}
.empty {
  text-align: center;
  p {
    margin: 0;
  }
}
</style>
