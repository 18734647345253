<template lang="pug">
  .choose-pharmacy
    TitleBar(:title="'選擇領藥藥局'")
    .container
      .choose-types
        .type(
          v-for="(type, index) in typesActivities" 
          :key="index"
          :class="{ 'is-active': activities[index] }"
          @click="acitveHandler({ index, type }); showConfirmView = false; selected = {}"
        ) {{ type.type }}

      ConfirmPharmacies(
        v-if="typesActivities[0].active"
        @storeChoosePharmaciesQueryRecord="storeChoosePharmaciesQueryRecord"
        :userCurrentPosition="userCurrentPosition"
        :selected.sync="selected"
        :markers="mapMarkers"
        :type="'choose'"
        :searchType="searchType.type"
      )

      div(v-if="typesActivities[1].active")
        StreetSearch(
          @getPharmaciesList="getPharmaciesList"
          :selectedCity.sync="selectedCity"
          :selectedAdministrativeDistricts.sync="selectedAdministrativeDistricts"
          :selectedStreet.sync="selectedStreet"
          :citiesList="citiesList"
          :districtsList="districtsList"
          :streetList="streetList"
          :selected.sync="selected"
          :type="'pharmacy'"
        )
        .street-pharmacy-list(v-if="selectedCity && selectedAdministrativeDistricts && selectedStreet")
          p.select-pharmacy 請選擇藥局
          PharmaciesList(
            :list="pharmaciesList"
            :selected.sync="selected"
            @click.native="showConfirmView = true; typesActiveHandler({ index: 0, type: '街道搜尋' })"
          )
      .search-name(v-if="typesActivities[2].active") 
        .search-bar
          .title(
            :style="{ color: searchActive ? '#80aa32' : '#4a4a4a' }"
            @click="searchActive = true; $refs.search.focus()"
          ) 請輸入藥局名稱
          el-input(
            ref="search"
            class="el-input-medium"
            type="text"
            placeholder="請輸入藥局名稱"
            :clearable="true"
            v-model="searchForm.name"
            @focus="searchActive = true"
            @blur="searchActive = false"
          )
          el-button(
            class="el-button--medium search-btn"
            @click="tricky(); getPharmaciesList({ searchType: '名稱搜尋' }); "
          )
        PharmaciesList(
          :list="pharmaciesList"
          :selected.sync="selected"
          :init="init"
          @click.native="selectPhamacyHandler"
        )
      div(v-if="typesActivities[3].active") 
        div(v-if="!showConfirmView")
          PharmaciesList(
            :list="historyPharmacies"
            :selected.sync="selected"
            @click.native="selectHistoryPhamacyHandler"
          )
        div(v-if="showConfirmView")
          ConfirmPharmacies(
            @storeChoosePharmaciesQueryRecord="storeChoosePharmaciesQueryRecord"
            :selected.sync="selected"
            :markers="mapMarkers"
            :type="'history'"
          )
      el-button(
        v-if="searchType !== '地圖搜尋' && showConfirmView === true"
        @click="goBackHandler"
        class="btn el-button-primary-plain el-button--large go-back"
      ) 上一步
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";

// components
import TitleBar from "@/components/frontend/Base/TitleBar.vue";
import ConfirmPharmacies from "@/components/frontend/ConfirmPharmacies.vue";
import PharmaciesList from "@/components/frontend/PharmaciesList.vue";
import StreetSearch from "@/components/frontend/StreetSearch.vue";
// views

// assets

export default {
  props: {},
  data() {
    return {
      selected: {},
      searchForm: {
        name: ""
      },
      userCurrentPosition: {
        lat: 0,
        lng: 0
      },
      searchType: {
        type: ""
      },
      showConfirmView: false,
      selectedCity: "",
      selectedAdministrativeDistricts: "",
      selectedStreet: "",
      typesActivities: [
        {
          type: "地圖搜尋",
          active: true
        },
        {
          type: "街道搜尋",
          active: false
        },
        {
          type: "名稱搜尋",
          active: false
        },
        {
          type: "歷史藥局",
          active: false
        }
      ],
      activities: [true, false, false],
      searchActive: false,
      init: true,
      index: 0
    };
  },
  methods: {
    ...mapActions({}),
    selectHistoryPhamacyHandler() {
      if (!this.historyPharmacies.length) return;
      this.showConfirmView = true;
      const historyPharmaciesQueryRecords = {
        showConfirmView: true,
        selected: this.selected
      };
      this.$store.dispatch(
        "frontend_history_pharmacies/storeHistoryPharmaciesQueryRecords",
        historyPharmaciesQueryRecords
      );
    },
    selectPhamacyHandler() {
      if (!this.pharmaciesList.length) return;
      this.showConfirmView = true;
      this.typesActiveHandler({ index: 0, type: "名稱搜尋" });
    },
    tricky() {
      // 為了處理第一次不顯示，搜尋後才顯示的 tricky 寫法。
      setTimeout(() => {
        this.init = false;
      }, 2000);
    },
    async getPharmaciesList({ searchType, status }) {
      let searchParams = {};

      if (searchType === "地圖搜尋") {
        searchParams = {
          map: ""
        };
        return await this.$store.dispatch(
          "frontend_choose_pharmacies/getMapSearchPharmaciesList",
          searchParams
        );
      }

      if (searchType === "街道搜尋" && status === "init") {
        searchParams = {};
        return await this.$store.dispatch(
          "frontend_choose_pharmacies/getStreetSearchList",
          { searchParams, type: "city" }
        );
      } else if (searchType === "街道搜尋" && status === "city") {
        searchParams = {
          city: this.selectedCity
        };
        return await this.$store.dispatch(
          "frontend_choose_pharmacies/getStreetSearchList",
          { searchParams, type: "district" }
        );
      } else if (searchType === "街道搜尋" && status === "district") {
        searchParams = {
          city: this.selectedCity,
          district: this.selectedAdministrativeDistricts
        };
        return await this.$store.dispatch(
          "frontend_choose_pharmacies/getStreetSearchList",
          { searchParams, type: "street" }
        );
      } else if (searchType === "街道搜尋" && status === "street") {
        searchParams = {
          city: this.selectedCity,
          district: this.selectedAdministrativeDistricts,
          street: this.selectedStreet
        };
        return await this.$store.dispatch(
          "frontend_choose_pharmacies/getStreetSearchList",
          { searchParams, type: "pharmacies" }
        );
      }

      if (searchType === "名稱搜尋") {
        // 清除街道搜尋的資料
        this.selectedCity = "";
        this.selectedAdministrativeDistricts = "";
        this.selectedStreet = "";
        searchParams = {
          ...this.searchForm
        };
        return await this.$store.dispatch(
          "frontend_choose_pharmacies/getNameSearchList",
          searchParams
        );
      }
    },
    async acitveHandler({ index, type }) {
      if (type.type === "地圖搜尋") {
        await this.getPharmaciesList({ searchType: "地圖搜尋" });
      }

      if (type.type === "街道搜尋") {
        await this.getPharmaciesList({
          searchType: "街道搜尋",
          status: "init"
        });
      }

      if (type.type === "名稱搜尋") {
        await this.getPharmaciesList({
          searchType: "名稱搜尋"
        });
      }

      if (type.type === "歷史藥局") {
        await this.$store.dispatch(
          "frontend_history_pharmacies/getHistoryPharmaciesList"
        );
      }
      this.activities.fill(false);
      this.activities[index] = true;
      this.index = index;
      this.activities = [...this.activities];

      this.typesActiveHandler({ index, type: type.type });
    },
    typesActiveHandler({ index, type }) {
      this.typesActivities.forEach(type => {
        type.active = false;
      });
      this.typesActivities[index].active = true;
      this.typesActivities = [...this.typesActivities];
      this.searchType = {
        active: true,
        type
      };
    },
    goBackHandler() {
      switch (this.searchType.type) {
        case "地圖搜尋":
          this.typesActiveHandler({ index: 0, type: "地圖搜尋" });
          break;
        case "街道搜尋":
          this.typesActiveHandler({ index: 1, type: "街道搜尋" });
          break;
        case "名稱搜尋":
          this.typesActiveHandler({ index: 2, type: "名稱搜尋" });
          break;
        case "歷史藥局":
          this.typesActiveHandler({ index: 3, type: "歷史藥局" });
          break;
      }
      this.showConfirmView = false;
    },
    storeChoosePharmaciesQueryRecord() {
      const choosePharmaciesQueryRecord = {
        city: this.selectedCity,
        district: this.selectedAdministrativeDistricts,
        street: this.selectedStreet,
        selected: this.selected,
        searchType: this.searchType,
        index: this.index,
        showConfirmView: true
      };

      this.$store.dispatch(
        "frontend_choose_pharmacies/storeChoosePharmaciesQueryRecord",
        choosePharmaciesQueryRecord
      );
    }
  },
  computed: {
    ...mapGetters({
      pharmaciesList: "frontend_choose_pharmacies/pharmaciesList",
      citiesList: "frontend_choose_pharmacies/citiesList",
      districtsList: "frontend_choose_pharmacies/districtsList",
      streetList: "frontend_choose_pharmacies/streetList",
      choosePharmaciesQueryRecord:
        "frontend_choose_pharmacies/choosePharmaciesQueryRecord",
      historyPharmacies: "frontend_history_pharmacies/historyPharmacies"
    }),
    mapMarkers() {
      return this.pharmaciesList;
    }
  },
  components: {
    TitleBar,
    ConfirmPharmacies,
    PharmaciesList,
    StreetSearch
  },
  async mounted() {
    const {
      city,
      district,
      street,
      selected,
      searchType,
      index,
      showConfirmView
    } = this.choosePharmaciesQueryRecord;

    navigator.geolocation.getCurrentPosition(position => {
      this.userCurrentPosition.lat = position.coords.latitude;
      this.userCurrentPosition.lng = position.coords.longitude;
    });

    if (showConfirmView) {
      this.showConfirmView = showConfirmView;
      this.selected = selected;
      this.selectedCity = city;
      this.selectedAdministrativeDistricts = district;
      this.selectedStreet = street;
      this.searchType = searchType;

      this.activities.fill(false);
      this.activities[index] = true;
      this.index = index;
      this.activities = [...this.activities];
      this.typesActiveHandler({ index: 0, type: this.searchType.type });
    } else {
      this.searchType.type = "地圖搜尋";
      await this.getPharmaciesList({ searchType: "地圖搜尋", status });
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0;
}

.choose-types {
  background: $fifth;
  margin-bottom: 16px;
  .type {
    display: inline-block;
    width: 25%;
    font-size: 14px;
    padding: 0 24px;
    text-align: center;
    position: relative;
    padding: 12px 12px 10px;
    background: $fifth;
    color: $third;
    font-weight: 600;
    &::after {
      content: "";
      height: 12px;
      width: 1px;
      position: absolute;
      top: 17px;
      right: 0;
      background: $seventeen;
    }
    &:last-child {
      &::after {
        background: transparent;
      }
    }
  }
  .is-active {
    color: $primary;
    border-bottom: 5px solid $primary;
  }
}

.search-bar {
  position: relative;
  margin-bottom: 16px;
  .title {
    padding-bottom: 5px;
    font-weight: 600;
  }
  .search-btn {
    position: absolute;
    right: 0;
    background: $primary;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-image: url("~@/assets/images/search.png");
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid $primary;
  }

  ::v-deep .el-input {
    .el-input__clear {
      color: $third;
    }
    .el-input__suffix {
      right: 50px;
    }
  }
}

.street-pharmacy-list {
  .select-pharmacy {
    font-weight: 600;
    color: $primary;
    margin-bottom: 10px;
  }
}

.go-back {
  width: calc(100% - 50px);
  position: fixed;
  left: 25px;
  bottom: 32px;
  z-index: 2;
  @media (max-height: 667px) {
    bottom: 18px;
  }
}
</style>
