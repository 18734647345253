import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Cookies from "js-cookie";
import liff from "@line/liff";
import api from "@/api/index.js";

import { frontend_manage } from "@/router/frontend-manage/index.js";
import { frontend } from "@/router/frontend/index.js";
Vue.use(VueRouter);

const routes = [...frontend_manage, ...frontend];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
});

async function liffInitialize() {
  // 要判斷在 line 使用時才執行
  if (!liff.isInClient() && window.location.pathname === "/") {
    return window.alert("本網頁只能透過 Line 連結顯示!!");
  }
  if (!window.location.pathname.includes("/manage")) {
    try {
      const { liffId, api_token } = await getLIFFID();

      await liff.init({ liffId: liffId });
      store.dispatch(
        "frontend_register/getLIFFAccessToken",
        liff.getAccessToken()
      );

      const { data } = await api.post("/member/login", {
        access_token: liff.getAccessToken(),
        api_token: api_token
      });
      store.dispatch("frontend_register/getFrontendLoginInfo", data);
      Cookies.set("token", data.access_token);
    } catch (e) {
      if (e.response && e.response.status === 403) {
        // 使用者不存在
        router.push({ name: "Register" });
      }
      if (e.response && e.response.status === 400) {
        alert(e.response.data.message);
        liff.closeWindow();
        console.log("400:The access token signature invalid");
        console.log("400:聯絡人不存在");
      }
    }
  }
}

async function getLIFFToken() {
  if (!window.location.pathname.includes("/manage")) {
    try {
      const { liffId } = await getLIFFID();

      await liff.init({ liffId: liffId });
      await store.dispatch(
        "frontend_register/getLIFFAccessToken",
        liff.getAccessToken()
      );
    } catch (e) {
      console.log(e);
    }
  }
}

async function getLIFFID() {
  if (!window.location.pathname.includes("/manage")) {
    try {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop)
      });
      let api_token = params.api_token;
      await store.dispatch("frontend_register/getLIFFID", api_token);

      return {
        liffId: store.getters["frontend_register/liffID"],
        api_token
      };
    } catch (e) {
      if (e.response.status === 400) {
        window.alert("令牌錯誤，請洽管理員");
      }
      if (e.response.status === 401) {
        window.alert("該藥局已停用，請洽管理員");
      }
      liff.closeWindow();
    }
  }
}

router.beforeEach(async (to, from, next) => {
  if (to.name !== "Register") await liffInitialize();
  if (to.name === "Register") await getLIFFToken();
  if (to.meta.type === "manage") {
    const token = Cookies.get("token");
    const isRequireAuth = to.matched.some(record => record.meta.auth);
    if (isRequireAuth) {
      if (to.name !== "Login" && token) next();
      else next({ name: "Login" });
    } else next();
  } else if (to.meta.type === "user") {
    next();
  }
});

export default router;
