<template lang="pug">
  .prescription-photo
    TitleBar(:title="'上傳慢箋照片'")
    .container
      .choose-type(v-if="!file.name")  
        .tips *請選擇慢箋照片上傳方式
        .types
          .photo
            div
              img(:src="require('@/assets/images/photo.png')")
              p 選擇照片
            input(type="file" accept="image/*" @change="getPrescriptionPhoto($event)") 
          .camera
            div
              img(:src="require('@/assets/images/camera.png')")
              p 開啟相機
            input(type="file" accept="image/*" capture="camera" @change="getPrescriptionPhoto($event)") 
      .show-photo(v-else)
        .tips *確認下圖無誤請點擊確定送出
        .photo(
          :style="{ backgroundImage: 'url(' + currentSelectedPicture + ')' }"
        )
    .feature-btns
      el-button(
        v-if="!currentSelectedPicture && frontendLoginInfo.role === 1"
        @click="goBackHandler"
        class="btn el-button-primary-plain el-button--large reselect"
      ) 上一步
      el-button(
        v-if="currentSelectedPicture"
        @click="reselectHandler"
        class="btn el-button-primary-plain el-button--large reselect"
      ) 重新選擇
      el-button(
        v-if="currentSelectedPicture"
        @click="sendHandler"
        class="btn el-button-primary el-button--large go-back"
      ) 確定送出
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";
import liff from "@line/liff";
// components
import TitleBar from "@/components/frontend/Base/TitleBar.vue";
import imageCompression from "browser-image-compression";
// views

// assets

export default {
  props: {},
  data() {
    return {
      file: {},
      currentSelectedPicture: "",
      isSend: false
    };
  },
  methods: {
    ...mapActions({}),
    goBackHandler() {
      this.$router.go(-1);
    },
    async getPrescriptionPhoto($event) {
      const imageFile = $event.target.files[0];
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1000,
        useWebWorker: true,
        fileType: "image/jpeg"
      };

      try {
        // another solution for pass file
        // const blob = await imageCompression(imageFile, options);
        // this.file = new File([blob], blob.name);
        this.file = await imageCompression(imageFile, options);
        this.currentSelectedPicture = URL.createObjectURL(this.file);
      } catch (error) {
        console.log(error);
      }
    },
    reselectHandler() {
      this.file = {};
      this.currentSelectedPicture = "";
    },
    async sendHandler() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });

      const form = new FormData();
      // 只有中央藥局才需要運送方式、地點
      if (this.frontendLoginInfo.role === 1) {
        form.append("method", JSON.stringify(this.sendInfo.method));
        form.append(
          "pick_up_location",
          JSON.stringify(this.sendInfo.pick_up_location)
        );
      }
      form.append("prescription", this.file, `${this.file.name}.jpg`);

      await this.$store.dispatch("frontend_photo/sendOrdersHandler", form);

      this.successMsg &&
        (alert(`${this.successMsg}`),
        this.$store.dispatch("successHandler", ""),
        (this.file = {}),
        (this.currentSelectedPicture = ""),
        loading.close(),
        liff.closeWindow());
    }
  },
  computed: {
    ...mapGetters({
      sendInfo: "sendInfo",
      frontendLoginInfo: "frontend_register/frontendLoginInfo"
    }),
    successMsg() {
      return this.$store.state.successMsg;
    }
  },
  components: {
    TitleBar
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0;
}

.choose-type,
.show-photo {
  background: $fifth;
  padding: 16px;
  border-radius: 5px;
  .tips {
    color: $ninth;
    font-size: 15px;
    margin-bottom: 16px;
  }
}

.choose-type {
  .types {
    display: flex;
    justify-content: space-between;
    > div {
      width: 48%;
      height: 134px;
      border: 2px solid $primary;
      border-radius: 5px;
      position: relative;
    }
    .photo,
    .camera {
      > div {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translate(0, -50%);
        text-align: center;
        img {
          width: 55px;
          height: 55px;
        }
        p {
          font-size: 18px;
          margin-top: 10px;
          font-weight: 600;
        }
      }
      input[type="file"] {
        opacity: 0;
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }

    .photo {
      background: $fifth;
      > div {
        p {
          color: $primary;
        }
      }
    }

    .camera {
      background: $primary;
      > div {
        p {
          color: $fifth;
        }
      }
    }
  }
}

.show-photo {
  .photo {
    width: 100%;
    height: 400px;
    background: no-repeat center;
    background-size: contain;
    @media (max-height: 667px) {
      height: 350px;
    }
  }
}

.feature-btns {
  width: calc(100% - 50px);
  position: fixed;
  left: 25px;
  bottom: 32px;
  z-index: 2;
  @media (max-height: 667px) {
    bottom: 10px;
  }
  .reselect {
    margin-bottom: 10px;
  }
}
</style>
