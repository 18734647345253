<template lang="pug">
  .history-records
    TitleBar(:title="'領藥查詢'")
    .container
      .list
        .list-item(v-for="(item, index) in historyInfo.list" :key="index")
          .list-item-title(:class="statusStyle(item.status)") {{ statusText(item.status) }}
          .list-item-content 
            div
              span.title 訂單時間
              span.info {{ item.orderDate }}
            div
              span.title 病人姓名
              span.info {{ item.receiveMedicineName }}
              span.info(v-if="!item.receiveMedicineName") 尚無資訊
            div
              span.title 領藥時間
              span.info(
                v-if="item.startReceiveMedicineDate && item.endReceiveMedicineDate"
              ) {{ item.startReceiveMedicineDate }}~{{ item.endReceiveMedicineDate }}
              span.info(
                v-if="!item.startReceiveMedicineDate && !item.endReceiveMedicineDate"
              ) 尚無資訊
            div.fail-reason {{ item.failedReason }}
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";

// components
import TitleBar from "@/components/frontend/Base/TitleBar.vue";

// views

// assets

export default {
  props: {},
  data() {
    return {};
  },
  methods: {
    ...mapActions({}),
    statusStyle(status) {
      // 配合後端提供的資料修改，需要確認是否與後台的訂單狀態共用，先假設是
      const obj = {
        1: "waiting-to-review",
        2: "prepared",
        701: "failed",
        702: "failed",
        703: "failed",
        704: "failed",
        705: "failed",
        706: "failed",
        3: "allowable",
        4: "cancelable",
        5: "already-received",
        6: "not-check",
        7: "drug-withdrawal"
      };
      return obj[status];
    },
    statusText(status) {
      const obj = {
        1: "等待審核",
        2: "備藥中",
        701: "預約失敗",
        702: "預約失敗",
        703: "預約失敗",
        704: "預約失敗",
        705: "預約失敗",
        706: "預約失敗",
        3: "可領藥",
        4: "取消",
        5: "已領藥",
        6: "未確認",
        7: "退藥"
      };
      return obj[status];
    }
  },
  computed: {
    ...mapGetters({
      historyInfo: "frontend_history_records/historyInfo"
    })
  },
  components: {
    TitleBar
  },
  async mounted() {
    await this.$store.dispatch(
      "frontend_history_records/getHistoryRecordsList"
    );
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0;

  .list-item {
    border-radius: 5px;
    margin-bottom: 10px;
    &-title {
      font-weight: 600;
      background: $fifteen;
      padding: 4px 0;
      text-align: center;
      color: $fifth;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    &-content {
      background: $fifth;
      padding: 16px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      > div {
        padding-bottom: 10px;
        color: $third;
        .title {
          font-weight: 600;
          margin-right: 16px;
        }
      }
    }
  }
}
</style>
