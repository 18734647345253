export const viewKeysToApiKeysConverter = ({ apiKeys, viewKeys }, dataObj) => {
  return apiKeys.reduce((obj, key, index) => {
    if (!dataObj[viewKeys[index]]) return obj;
    obj[key] = dataObj[viewKeys[index]];
    return obj;
  }, {});
};

export const apiKeysToViewKeysConverter = ({ apiKeys, viewKeys }, dataObj) => {
  return viewKeys.reduce((obj, key, index) => {
    obj[key] = dataObj[apiKeys[index]];
    return obj;
  }, {});
};
