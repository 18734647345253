<template lang="pug">
  div.manage-container 
    ValidationObserver(ref="searchForm" v-slot="{ handleSubmit }")
      el-form(
        id="search-form"
        :model="searchForm"
        @submit.native.prevent="handleSubmit(onSearchFormSubmit)"
      )
        el-row.search-bar(:gutter="25")
          el-col(:span="8") 
            el-form-item(
              label="聯絡人"
              :class="{ 'is-active': highlightSearchFormItemsStatusList[0] }"
              @click.native="$refs.contactPerson.focus(); highlightSearchFormItemsHandler(0)"
            )
              ValidationProvider(rules="invalidInput" v-slot="{ errors }")
                el-input(
                  class="search-input el-input-medium"
                  type="text"
                  ref="contactPerson"
                  resize="none"
                  @focus="highlightSearchFormItemsHandler(0)"
                  v-model="searchForm.contactPerson"
                )
                p.error-msg {{ errors[0] }}
          el-col(:span="8") 
            el-form-item(
              label="聯絡電話"
              :class="{ 'is-active': highlightSearchFormItemsStatusList[1] }"
              @click.native="$refs.contactPhone.focus(); highlightSearchFormItemsHandler(1)"
            )
              ValidationProvider(rules="invalidInput" v-slot="{ errors }")
                el-input(
                  class="search-input el-input-medium"
                  type="text"
                  ref="contactPhone"
                  resize="none"
                  @focus="highlightSearchFormItemsHandler(1)"
                  v-model="searchForm.contactPhone"
                )
                p.error-msg {{ errors[0] }}
          el-col(:span="8") 
            el-form-item
              div(slot="label")
                span.empty
              el-button(
                class="btn-manage el-button-primary el-button--medium search-btn"
                :form="'search-form'"
                native-type="submit"
              ) 搜尋
              a(
                href="#"
                class="clear-all"
                @click.prevent="resetSearchForm"
              ) 清除篩選
    .contact-table-content
      .title-bar
        h3.page-title 聯絡人列表
      el-table.drugstore-table(
        :data="contactList" 
        style="width: 100%;"
        empty-text="沒有符合搜尋的聯絡人資料"
      )
          el-table-column(width="242")
            template(slot="header")
              p.first-col-title 聯絡人
            template(slot-scope="props")
              p.contact-name {{ props.row.fullName }}
          el-table-column(width="128")
            template(slot="header")
              p 性別
            template(slot-scope="props")
              p {{ props.row.gender }}
          el-table-column()
            template(slot="header")
              p 聯絡電話
            template(slot-scope="props")
              p {{ props.row.phone }}
          el-table-column(label="")
            template(slot-scope="props")
              .edit-btns
                el-button.check(@click="openUpdateContactFormHandler(props.row.id)")
                  img(:src="require('@/assets/images/check.png')")
    el-pagination(
      background
      layout="prev, pager, next"
      :total="contactLength"
      :page-size="50"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
    )
    el-dialog(
      v-if="contactDialogVisible"
      title="修改聯絡人"
      :visible.sync="contactDialogVisible"
      width="450px"
      center
      @close="resetContactForm"
    )
      el-row
        el-col
          ValidationObserver(ref="contactForm" v-slot="{ handleSubmit }")
            el-form(
              id="contact-form"
              :model="contactForm"
              @submit.native.prevent="handleSubmit(onDiaglogContactFormSubmit)"
            )
              el-form-item(
                :class="{ 'is-active': highlightDialogFormItemsStatusList[0] }"
                @click.native="$refs.lastName.focus(); highlightDialogFormItemsHandler(0)"
              )
                div(slot="label" class="drugstore")
                  span.star-key *
                  span 姓氏
                ValidationProvider(rules="invalidInput" v-slot="{ errors }")
                  el-input(
                    class="el-input-medium"
                    type="text"
                    ref="lastName"
                    v-model="contactForm.lastName"
                    resize="none"
                    @focus="highlightDialogFormItemsHandler(0)"
                  )
                  p.error-msg {{ errors[0] }}
              el-form-item(
                :class="{ 'is-active': highlightDialogFormItemsStatusList[1] }"
                @click.native="$refs.firstName.focus(); highlightDialogFormItemsHandler(1)"
              )
                div(slot="label" class="drugstore")
                  span.star-key *
                  span 名字
                ValidationProvider(rules="invalidInput" v-slot="{ errors }")
                  el-input(
                    class="el-input-medium"
                    type="text"
                    ref="firstName"
                    v-model="contactForm.firstName"
                    resize="none"
                    @focus="highlightDialogFormItemsHandler(1)"
                  )
                  p.error-msg {{ errors[0] }}
              el-form-item(
                :class="{ 'is-active': highlightDialogFormItemsStatusList[2] }"
                @click.native="$refs.gender.toggleMenu(); highlightDialogFormItemsHandler(2)"
              )
                div(slot="label")
                  span.star-key *
                  span 性別
                ValidationProvider(rules="invalidInput" v-slot="{ errors }")
                  el-select(
                    ref="gender"
                    v-model="contactForm.gender"
                    placeholder="請選擇"
                    size="medium"
                    @focus="highlightDialogFormItemsHandler(2)"
                  )
                    el-option(
                      v-for="gender in genderOptions"
                      :key="gender.value"
                      :label="gender.label"
                      :value="gender.value"
                    )
                  p.error-msg {{ errors[0] }}
              el-form-item(
                :class="{ 'is-active': highlightDialogFormItemsStatusList[3] }"
                @click.native="$refs.phone.focus(); highlightDialogFormItemsHandler(3)"
              )
                div(slot="label")
                  span.star-key *
                  span 聯絡電話
                ValidationProvider(rules="invalidInput" v-slot="{ errors }")
                  el-input(
                    class="el-input-medium"
                    type="text"
                    ref="phone"
                    v-model="contactForm.phone"
                    resize="none"
                    @focus="highlightDialogFormItemsHandler(3)"
                  )
                  p.error-msg {{ errors[0] }}
      .dialog-footer(slot="footer")
        el-row(:gutter="20")
          el-col.custom-col-24
            el-col(:span="12")
              el-button(
                class="btn-manage el-button-primary-plain"
                @click="resetContactForm"
              ) 取消
            el-col(:span="12")
              el-button(
                class="btn-manage el-button-primary"
                :form="'contact-form'"
                native-type="submit"
              ) 確定
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";

// components

// assets

// config

export default {
  props: {},
  data() {
    return {
      searchForm: {
        contactPerson: "",
        contactPhone: ""
      },
      contactForm: {
        id: null,
        lastName: "",
        firstName: "",
        gender: "",
        phone: ""
      },
      currentPage: 1,
      deleteId: "",
      genderOptions: [
        {
          label: "先生",
          value: "先生"
        },
        {
          label: "小姐",
          value: "小姐"
        }
      ],
      contactDialogVisible: false,
      deleteDialogVisible: false,
      highlightSearchFormItemsStatusList: [false, false],
      highlightDialogFormItemsStatusList: [false, false, false, false, false]
    };
  },
  methods: {
    ...mapActions({}),
    async getContactList() {
      await this.$store.dispatch("contact/getContactList", {
        page: this.currentPage,
        searchForm: {
          name: this.searchForm.contactPerson,
          phone: this.searchForm.contactPhone
        }
      });
    },
    onSearchFormSubmit() {
      this.currentPage = 1;
      this.$router
        .push({
          path: "/manage/contact",
          query: { page: 1 }
        })
        .catch(err => err);
      this.getContactList();
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.$router.push({
        path: "/manage/contact",
        query: { page }
      });
      this.getContactList();
    },
    async onDiaglogContactFormSubmit() {
      // maybe they don't want this action...
      await this.$store.dispatch("contact/updateUserContactInfo", {
        ...this.contactForm,
        first_name: this.contactForm.firstName,
        last_name: this.contactForm.lastName
      });

      this.successMsg &&
        (alert(`${this.successMsg}`),
        this.$store.dispatch("successHandler", ""),
        this.resetContactForm());
    },
    async openUpdateContactFormHandler(id) {
      await this.$store.dispatch("contact/getSpecificUser", id);
      // 模擬從表單上拿到該筆資料
      this.contactForm = {
        ...this.contactUser
      };
      this.contactDialogVisible = true;
    },
    resetSearchForm() {
      this.searchForm = {
        contactPerson: "",
        contactPhone: ""
      };
      this.$refs.searchForm.reset();
      this.highlightSearchFormItemsStatusList.fill(false);
    },
    resetContactForm() {
      this.contactForm = {
        id: null,
        lastName: "",
        firstName: "",
        gender: "",
        phone: ""
      };
      this.contactDialogVisible = false;
      this.$refs.contactForm.reset();
      this.highlightDialogFormItemsStatusList.fill(false);
    },
    highlightSearchFormItemsHandler(index) {
      this.highlightSearchFormItemsStatusList.fill(false);
      this.highlightSearchFormItemsStatusList[index] = true;
      this.highlightSearchFormItemsStatusList = [
        ...this.highlightSearchFormItemsStatusList
      ];
    },
    highlightDialogFormItemsHandler(index) {
      this.highlightDialogFormItemsStatusList.fill(false);
      this.highlightDialogFormItemsStatusList[index] = true;
      this.highlightDialogFormItemsStatusList = [
        ...this.highlightDialogFormItemsStatusList
      ];
    }
  },
  computed: {
    ...mapGetters({
      contactList: "contact/contactList",
      contactUser: "contact/contactUser",
      contactLength: "contact/contactLength"
    }),
    successMsg() {
      return this.$store.state.successMsg;
    }
  },
  components: {},
  async mounted() {
    await this.getContactList();
  }
};
</script>

<style lang="scss" scoped>
#search-form {
  margin: 0 auto 26px;
  width: 840px;
  .search-bar {
    margin: 0 !important;
    padding: 25px;
    margin-bottom: 40px;
    background: $fifth;
    border-radius: 5px;

    ::v-deep .el-form-item__label {
      width: 100%;
      text-align: left;
      font-size: 14px;
      color: #4a4a4a;
      line-height: 2;
      .empty {
        display: inline-block;
      }
    }
    .search-btn {
      width: 114px;
      padding: 0;
    }
    a {
      text-decoration: underline;
      margin-left: 49px;
    }
  }
}

#contact-form {
  ::v-deep .el-select {
    .el-input__suffix {
      top: 26px;
    }
  }
}

.contact-table-content {
  width: 840px;
  margin: auto;
  .contact-name {
    padding-left: 15px;
  }
}
</style>
