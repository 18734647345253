import api from "@/api";
import Cookies from "js-cookie";
import liff from "@line/liff";

const state = {
  liffAccessToken: "",
  backEndToken: "",
  liffID: "",
  apiToken: "",
  frontendLoginInfo: {}
};
const getters = {
  liffID: state => state.liffID,
  frontendLoginInfo: state => state.frontendLoginInfo
};
const mutations = {
  LIFF_ACCESS_TOKEN(state, liffAccessToken) {
    state.liffAccessToken = liffAccessToken;
  },
  LIFF_ID(state, liffID) {
    state.liffID = liffID;
  },
  API_TOKEN(state, api_token) {
    state.apiToken = api_token;
  },
  BACKEND_TOKEN(state, backEndToken) {
    state.backEndToken = backEndToken;
  },
  GET_FRONTEND_LOGIN_INFO(state, frontendLoginInfo) {
    state.frontendLoginInfo = frontendLoginInfo;
  }
};

const actions = {
  getFrontendLoginInfo({ commit }, frontendLoginInfo) {
    commit("GET_FRONTEND_LOGIN_INFO", frontendLoginInfo);
  },
  async getLIFFAccessToken({ commit }, liffAccessToken) {
    commit("LIFF_ACCESS_TOKEN", liffAccessToken);
  },
  async getLIFFID({ commit }, api_token) {
    try {
      const { data } = await api.get(`/line-bots/${api_token}`);
      commit("LIFF_ID", data.data);
      commit("API_TOKEN", api_token);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getBackEndToken({ commit }, liffAccessToken) {
    try {
      const { data } = await api.post("/member/login", {
        access_token: liffAccessToken
      });
      Cookies.set("token", data.access_token);
      commit("BACKEND_TOKEN", data.access_token);
    } catch (e) {
      alert(e.response.data.message);
      liff.closeWindow();
    }
  },
  async registerUserHandler({ state, dispatch }, registerForm) {
    try {
      const liffAccessToken = state.liffAccessToken;
      const apiToken = state.apiToken;
      const response = await api.post("member/register", {
        ...registerForm,
        access_token: liffAccessToken,
        api_token: apiToken
      });

      response &&
        (dispatch("successHandler", "註冊成功!!", { root: true }),
        dispatch("getBackEndToken", liffAccessToken));
    } catch (e) {
      if (e.response.status === 400) {
        dispatch("errorHandler", "使用者已註冊", { root: true });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
