<template lang="pug">
  div.manage-container 
    //- ValidationObserver(ref="searchForm" v-slot="{ handleSubmit }")
    //-   el-form(
    //-     id="search-form"
    //-     :model="searchForm"
    //-     @submit.native.prevent="handleSubmit(onSearchFormSubmit)"
    //-   )
    //-     el-row.search-bar(:gutter="25")
    //-       el-col(:span="5") 
    //-         el-form-item(
    //-           label="關鍵字搜尋"
    //-           :class="{ 'is-active': highlightSearchFormItemsStatusList[0] }"
    //-           @click.native="$refs.keyword.focus(); highlightSearchFormItemsHandler(0)"
    //-         )
    //-           ValidationProvider(rules="invalidInput" v-slot="{ errors }")
    //-             el-input(
    //-               class="search-input el-input-medium"
    //-               type="text"
    //-               ref="keyword"
    //-               resize="none"
    //-               @focus="highlightSearchFormItemsHandler(0)"
    //-               v-model="searchForm.keyword"
    //-             )
    //-             p.error-msg {{ errors[0] }}
    //-       el-col(:span="5") 
    //-         el-form-item
    //-           div(slot="label")
    //-             span.empty
    //-           el-button(
    //-             class="btn-manage el-button-primary el-button--medium search-btn"
    //-             :form="'search-form'"
    //-             native-type="submit"
    //-           ) 搜尋
    //-           a(
    //-             href="#"
    //-             class="clear-all"
    //-             @click.prevent="resetSearchForm"
    //-           ) 清除篩選
    .hospital-table-content 
      .title-bar
        h3.page-title 醫院列表
        el-button.add(
          class="btn-manage el-button--large el-button-secondary"
          @click="hospitalDialogVisible = true"
        ) 新增
      el-table.hospital-table(
        :data="hospitalsList" 
        style="width: 100%;"
        )
          el-table-column(width="300")
            template(slot="header")
              p.first-col-title 醫院名稱
            template(slot-scope="props")
              p.hospital-name {{ props.row.hospitalName }}
          el-table-column(
            :min-width="index === 0 ? '200' : '300'" 
            v-for="({ name, key }, index) in tableParams" 
            :key="index"
          )
            template(slot="header")
              p {{ name }}
            template(slot-scope="props")
              p(v-if="index === 0") {{ props.row[key] }}
              a(v-else :href="props.row['site']" target="_blank") {{ props.row['site'] }}
          el-table-column(label="")
            template(slot-scope="props")
              .edit-btns
                el-button.check(@click="openUpdatehospitalsFormHandler(props.row)")
                  img(:src="require('@/assets/images/check.png')")
                el-button.delete(@click="openDeletePharmacyDialog(props.row)")
                  img(:src="require('@/assets/images/delete.png')")
    //- el-pagination(
    //-   background
    //-   layout="prev, pager, next"
    //-   :total="pharmaciesLength"
    //-   :page-size="50"
    //-   @current-change="handleCurrentChange"
    //-   :current-page="currentPage"
    //- )

    DeleteDialog(
      :deleteDialogVisible.sync="deleteDialogVisible"
      :deleteId.sync="deleteId"
      @deleteHandler="deleteHospitalHandler"
      :msg="'醫院'"
    )
    el-dialog(
      v-if="hospitalDialogVisible"
      :title="!isEditing ? '新增醫院' : '修改醫院'"
      :visible.sync="hospitalDialogVisible"
      width="450px"
      center
      @close="resethospitalsForm"
    )
      el-row
        el-col
          ValidationObserver(ref="hospitalsForm" v-slot="{ handleSubmit }")
            el-form(
              id="hospital-form"
              :model="hospitalsForm"
              @submit.native.prevent="handleSubmit(onDiagloghospitalsFormSubmit)"
            )
              el-form-item(
                :class="{ 'is-active': highlightDialogFormItemsStatusList[0] }"
                @click.native="$refs.hospitalName.focus(); highlightDialogFormItemsHandler(0)"
              )
                div(slot="label" class="pharmacy")
                  span.star-key *
                  span 醫院名稱
                ValidationProvider(rules="notAllowedEmpty" v-slot="{ errors }")
                  el-input(
                    class="el-input-medium"
                    type="text"
                    ref="hospitalName"
                    v-model="hospitalsForm.hospitalName"
                    resize="none"
                    @focus="highlightDialogFormItemsHandler(0)"
                  )
                  p.error-msg {{ errors[0] }}
              el-form-item(
                :class="{ 'is-active': highlightDialogFormItemsStatusList[2] }"
                @click.native="$refs.address.focus(); highlightDialogFormItemsHandler(2)"
              )
                div(slot="label")
                  span.star-key *
                  span 地址
                ValidationProvider(rules="notAllowedEmpty" v-slot="{ errors }")
                  el-input(
                    class="el-input-medium"
                    type="text"
                    ref="address"
                    v-model="hospitalsForm.address"
                    resize="none"
                    @focus="highlightDialogFormItemsHandler(2)"
                  )
                  p.error-msg {{ errors[0] }}
              el-form-item(
                :class="{ 'is-active': highlightDialogFormItemsStatusList[1] }"
                @click.native="$refs.site.focus(); highlightDialogFormItemsHandler(1)"
              )
                div(slot="label")
                  span.star-key *
                  span 網址
                ValidationProvider(rules="notAllowedEmpty" v-slot="{ errors }")
                  el-input(
                    class="el-input-medium"
                    type="textarea"
                    ref="site"
                    v-model="hospitalsForm.site"
                    resize="none"
                    :rows="3"
                    @focus="highlightDialogFormItemsHandler(1)"
                  )
                  p.error-msg {{ errors[0] }}
      .dialog-footer(slot="footer")
        el-row(:gutter="20")
          el-col.custom-col-24
            el-col(:span="12")
              el-button(
                class="btn-manage el-button-primary-plain"
                @click="resethospitalsForm"
              ) 取消
            el-col(:span="12")
              el-button(
                class="btn-manage el-button-primary"
                :form="'hospital-form'"
                native-type="submit"
              ) 確定
</template>

<script>
// plugin
import { mapActions, mapGetters } from "vuex";

// components
import DeleteDialog from "@/components/frontend-manage/Base/DeleteDialog";
// assets

export default {
  props: {},
  data() {
    return {
      tableParams: [
        {
          name: "地址",
          key: "address"
        },
        {
          name: "網址",
          key: "site"
        }
      ],
      searchForm: {
        keyword: ""
      },
      hospitalsForm: {
        id: null,
        hospitalName: "",
        site: "",
        address: ""
      },
      deleteId: null,
      currentPage: 1,
      hospitalDialogVisible: false,
      deleteDialogVisible: false,
      isEditing: false,
      highlightSearchFormItemsStatusList: [false],
      highlightDialogFormItemsStatusList: [false, false, false, false, false]
    };
  },
  methods: {
    ...mapActions({}),
    async getHospitalsList() {
      await this.$store.dispatch("hospitals/getHospitalsList");
    },
    async onDiagloghospitalsFormSubmit() {
      if (!this.isEditing) {
        await this.$store.dispatch("hospitals/createHospitalsInfo", {
          ...this.hospitalsForm
        });
      } else {
        await this.$store.dispatch("hospitals/updateHospitalsInfo", {
          ...this.hospitalsForm
        });
      }

      this.successMsg &&
        (alert(`${this.successMsg}`),
        this.$store.dispatch("successHandler", ""),
        this.resethospitalsForm(),
        await this.getHospitalsList());
      this.errorMsg &&
        (alert(`${this.errorMsg}`), this.$store.dispatch("errorHandler", ""));
    },
    openUpdatehospitalsFormHandler(formObj) {
      // 模擬從表單上拿到該筆資料
      this.hospitalsForm = {
        ...formObj
      };
      this.isEditing = true;
      this.hospitalDialogVisible = true;
    },
    async deleteHospitalHandler() {
      await this.$store.dispatch("hospitals/deleteHospital", this.deleteId);

      // this.checkLastDataInPage();
      this.getHospitalsList();

      this.successMsg &&
        (alert(`${this.successMsg}`),
        this.$store.dispatch("successHandler", ""),
        (this.deleteId = null),
        (this.deleteDialogVisible = false));
    },
    resethospitalsForm() {
      this.hospitalsForm = {
        hospitalName: "",
        site: "",
        address: ""
      };
      this.isEditing = false;
      this.hospitalDialogVisible = false;
      this.$refs.hospitalsForm.reset();
      this.highlightDialogFormItemsStatusList.fill(false);
    },
    openDeletePharmacyDialog({ id }) {
      this.deleteDialogVisible = true;
      this.deleteId = id;
    },
    closeDeletePharmacyDialog() {
      this.deleteId = "";
      this.deleteDialogVisible = false;
    },
    highlightDialogFormItemsHandler(index) {
      this.highlightDialogFormItemsStatusList.fill(false);
      this.highlightDialogFormItemsStatusList[index] = true;
      this.highlightDialogFormItemsStatusList = [
        ...this.highlightDialogFormItemsStatusList
      ];
    }
    // checkLastDataInPage() {
    //   // 檢查是不是分頁的最後一筆資料
    //   const isLastDataInPage = (this.pharmaciesLength - 1) % 50;
    //   if (isLastDataInPage === 0) {
    //     this.currentPage = this.currentPage - 1;
    //     this.$router.push({
    //       path: "/manage/pharmacies",
    //       query: { page: this.currentPage }
    //     });
    //   }
    // },
    // onSearchFormSubmit() {
    //   this.currentPage = 1;
    //   this.$router
    //     .push({
    //       path: "/manage/pharmacies",
    //       query: { page: 1 }
    //     })
    //     .catch(err => err);
    //   this.getPharmaciesList();
    // },
    // handleCurrentChange(page) {
    //   this.currentPage = page;
    //   this.$router.push({
    //     path: "/manage/pharmacies",
    //     query: { page }
    //   });
    //   this.getPharmaciesList();
    // },
    // resetSearchForm() {
    //   this.searchForm = {
    //     keyword: ""
    //   };
    //   this.$refs.searchForm.reset();
    //   this.highlightSearchFormItemsStatusList.fill(false);
    // },
    // highlightSearchFormItemsHandler(index) {
    //   this.highlightSearchFormItemsStatusList.fill(false);
    //   this.highlightSearchFormItemsStatusList[index] = true;
    //   this.highlightSearchFormItemsStatusList = [
    //     ...this.highlightSearchFormItemsStatusList
    //   ];
    // },
  },
  computed: {
    ...mapGetters({
      hospitalsList: "hospitals/hospitalsList"
    }),
    successMsg() {
      return this.$store.state.successMsg;
    },
    errorMsg() {
      return this.$store.state.errorMsg;
    }
  },
  components: {
    DeleteDialog
  },
  async mounted() {
    await this.getHospitalsList();
  }
};
</script>

<style lang="scss" scoped>
// #search-form {
//   margin-bottom: 40px;
//   .search-bar {
//     margin: 0 !important;
//     padding: 25px;
//     background: $fifth;
//     border-radius: 5px;
//     ::v-deep .el-form-item__label {
//       width: 100%;
//       text-align: left;
//       font-size: 14px;
//       color: #4a4a4a;
//       line-height: 2;
//       .empty {
//         display: inline-block;
//       }
//     }
//     .search-btn {
//       width: 114px;
//       padding: 0;
//     }
//     a {
//       text-decoration: underline;
//       margin-left: 49px;
//     }
//   }
// }
#hospital-form {
  ::v-deep .el-select {
    .el-input__suffix {
      top: 28px;
    }
  }
  ::v-deep .el-form-item:last-child {
    margin-bottom: 0;
  }
}

.hospital-name {
  padding-left: 15px;
}

.hospital-table-content {
  .check {
    margin-right: 10px;
  }
}
</style>
