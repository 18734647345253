import api from "@/api";

const mappingHistoryPharmacies = state => {
  return state.historyPharmacies.map(pharmacy => {
    return {
      id: pharmacy.pharmacy_id,
      lng: +pharmacy.longitude, // 經度
      lat: +pharmacy.latitude, // 緯度
      pharmacy: pharmacy.pharmacy_name,
      address: pharmacy.pharmacy_address
    };
  });
};

const state = {
  historyInfo: {
    data: [],
    currentPage: 1
  },
  historyPharmacies: [],
  historyPharmaciesQueryRecords: {}
};
const getters = {
  historyPharmacies: state => mappingHistoryPharmacies(state),
  historyPharmaciesQueryRecords: state => state.historyPharmaciesQueryRecords
};

const mutations = {
  HISTORY_PHARMACIES(state, historyPharmacies) {
    state.historyPharmacies = historyPharmacies;
  },
  HISTORY_PHARMACIES_QUERY_RECORDS(state, historyPharmaciesQueryRecords) {
    state.historyPharmaciesQueryRecords = historyPharmaciesQueryRecords;
  }
};

const actions = {
  async getHistoryPharmaciesList({ commit }) {
    try {
      const { data: historyPharmacies } = await api.get(
        "/member/pharmacies/history"
      );
      commit("HISTORY_PHARMACIES", historyPharmacies);
    } catch (e) {
      console.log(e);
    }
  },
  storeHistoryPharmaciesQueryRecords(
    { commit },
    historyPharmaciesQueryRecords
  ) {
    commit("HISTORY_PHARMACIES_QUERY_RECORDS", historyPharmaciesQueryRecords);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
