export const frontend = [
  {
    path: "/",
    name: "Home_Frontend",
    component: () => import("@/views/frontend/Home.vue"),
    meta: { auth: true, type: "user" },
    children: [
      {
        path: "/register",
        name: "Register",
        meta: { auth: true, type: "user" },
        component: () => import("@/views/frontend/Register.vue")
      },
      {
        path: "/history-records",
        name: "HistoryRecords",
        meta: { auth: true, type: "user" },
        component: () => import("@/views/frontend/HistoryRecords.vue")
      },
      // {
      //   path: "/history-pharmacies",
      //   name: "HistoryPharmacies",
      //   meta: { auth: true, type: "user" },
      //   component: () => import("@/views/frontend/HistoryPharmacies.vue")
      // },
      {
        path: "/prescription-photo",
        name: "PrescriptionPhoto",
        meta: { auth: true, type: "user" },
        component: () => import("@/views/frontend/PrescriptionPhoto.vue")
      },
      {
        path: "/choose-pharmacies",
        name: "ChoosePharmacies",
        meta: { auth: true, type: "user" },
        component: () => import("@/views/frontend/ChoosePharmacies.vue")
      },
      {
        path: "/delivery-service",
        name: "DeliveryService",
        meta: { auth: true, type: "user" },
        component: () => import("@/views/frontend/DeliveryService.vue")
      },
      {
        path: "/choose-hospitals",
        name: "ChooseHospitals",
        meta: { auth: true, type: "user" },
        component: () => import("@/views/frontend/ChooseHospitals.vue")
      }
    ]
  }
];
