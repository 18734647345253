// 需要配合後端的狀態
export const typeOptions = [
  {
    id: 0,
    label: "配送到府",
    value: "1"
  },
  {
    id: 1,
    label: "藥局",
    value: "2"
  },
  {
    id: 2,
    label: "醫院",
    value: "3"
  }
];

/*
  *************************************************************
  後端的狀態
  <1> 等待審核
  <2> 備藥中
  <3> 可領藥
  <4> 取消
  <5> 已領藥
  <6> 未確認
  <7> 退藥
  <701> 失敗-黑名單
  <702> 失敗-照片
  <703> 失敗-備藥時間
  <704> 失敗-第二次已領
  <705> 失敗-無獲利
  <706> 失敗-其它
  *************************************************************
*/
export const orderStatusOptions = [
  {
    id: 0,
    label: "等待審核",
    value: 1
  },
  {
    id: 1,
    label: "備藥中",
    value: 2
  },
  {
    id: 2,
    label: "失敗-黑名單",
    value: 701
  },
  {
    id: 3,
    label: "失敗-照片",
    value: 702
  },
  {
    id: 4,
    label: "失敗-備藥時間",
    value: 703
  },
  {
    id: 5,
    label: "失敗-第二次已領",
    value: 704
  },
  {
    id: 6,
    label: "失敗-無獲利",
    value: 705
  },
  {
    id: 7,
    label: "失敗-其他",
    value: 706
  },
  {
    id: 8,
    label: "可領藥",
    value: 3
  },
  {
    id: 9,
    label: "取消",
    value: 4
  },
  {
    id: 10,
    label: "已領藥",
    value: 5
  },
  {
    id: 11,
    label: "未確認",
    value: 6
  },
  {
    id: 12,
    label: "退藥",
    value: 7
  }
];

export const hospitalOrderStatusOptions = [
  {
    id: 0,
    label: "等待審核",
    value: 8
  },
  {
    id: 1,
    label: "登錄失敗",
    value: 9
  },
  {
    id: 2,
    label: "登錄成功",
    value: 10
  },
  {
    id: 3,
    label: "已通知",
    value: 11
  },
  {
    id: 4,
    label: "取消",
    value: 12
  }
];
// 失敗
export const failedStatusOptions = [
  {
    id: 0,
    label: "黑名單",
    value: 701
  },
  {
    id: 1,
    label: "照片",
    value: 702
  },
  {
    id: 2,
    label: "備藥時間",
    value: 703
  },
  {
    id: 3,
    label: "第二次已領",
    value: 704
  },
  {
    id: 4,
    label: "無獲利",
    value: 705
  },
  {
    id: 5,
    label: "其他",
    value: 706
  }
];
