<template lang="pug">
  .date-picker(@click="isRotate = true")
    //- 客製化:
    //-   -> 新增: date-picker arrow down 的部分
    //-   -> arrow down 旋轉的部分 
    el-date-picker(
      @input="$emit('update:value', $event == null ? '' : $event)"
      :value="value"
      type="date"
      :editable="false"
      @blur="isRotate = false"
      prefix-icon="false"
      placeholder="請選擇"
      format="yyyy/MM/dd"
      value-format="yyyy/MM/dd"
      :disabled="disabled"
      ref="datePicker"
    )
    i(
      v-if="!value && !disabled"
      class="el-icon-caret-bottom el-icon--right"
      :class="{ 'is-rotate': isRotate === true }"
    )
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isRotate: false
    };
  },
  methods: {
    test($event) {
      console.log($event);
    }
  }
};
</script>

<style lang="scss" scoped>
.date-picker {
  position: relative;
  .el-date-editor.el-input {
    width: 100%;
  }
  .el-icon-caret-bottom {
    position: absolute;
    right: 10px;
    bottom: 14px;
    transition: transform 0.3s;
    transform: rotate(0deg);
    pointer-events: none;
    color: #c0c4cc;
    font-size: 14px;
  }
  ::v-deep .el-input--prefix .el-input__inner {
    background: #f7f7f7;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
  }
  ::v-deep .el-input__inner {
    height: 36px;
    line-height: 36px;
  }
  .is-rotate {
    transform: rotate(-180deg);
  }
}
::v-deep .el-input.is-disabled .el-input__inner {
  cursor: not-allowed;
}
</style>
